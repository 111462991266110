import mitt from "mitt";

import { Emitter } from "mitt";

type Events = {
  onLogout: void;
  onLogin: void;
};

const emitter: Emitter<Events> = mitt<Events>();

const useAuthMitt = () => {
  return emitter;
}

export default useAuthMitt
