import className from '@/utils/className';
import { PropsWithChildren, ReactNode } from 'react';
import { FFCol, FFRow } from '@/uikit';
import clsx from 'clsx';
import './style.scss';

const { blockClassName, getClass } = className('c-sectionBox');

const SectionBox = ({
  title,
  outerTitle,
  outerRowClassName,
  children,
  titlePostfix,
  titlePrefix,
  outerActions,
  className,
  withBox = true,
}: PropsWithChildren<{
  title?: string;
  outerTitle?: string;
  outerRowClassName?: string;
  outerActions?: ReactNode;
  titlePrefix?: ReactNode;
  titlePostfix?: ReactNode;
  className?: string;
  withBox?: boolean;
}>) => {
  return (
    <FFCol gap="12px" width="100%">
      {(outerTitle || outerActions) && (
        <FFRow justifyContent="space-between" alignItems="center" className={outerRowClassName}>
          {outerTitle && <h3 className={getClass('outerTitle')}>{outerTitle}</h3>}
          {outerActions && <div>{outerActions}</div>}
        </FFRow>
      )}
      {withBox ? (
        <div className={clsx(blockClassName, className)}>
          {title && (
            <FFRow className={getClass('titleArea')} alignItems="center" gap="8px">
              {titlePrefix}
              <h3 className={getClass('title')}>{title}</h3>
              {titlePostfix}
            </FFRow>
          )}
          {children}
        </div>
      ) : (
        children
      )}
    </FFCol>
  );
};

export default SectionBox;
