const Stats = () => (
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
    <g id="signal-full--phone-mobile-device-signal-wireless-smartphone-iphone-bar-bars-full-android">
        <path id="Vector 3960" fill="currentColor" d="M5 7.5H1V13h4V7.5Z" strokeWidth="1"></path>
        <path id="Vector 3961" fill="currentColor" d="M9 4.5H5V13h4V4.5Z" strokeWidth="1"></path>
        <path id="Vector 3962" fill="currentColor" d="M13 1H9v12h4V1Z" strokeWidth="1"></path>
        <path id="Vector 3963" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M5 7.5H1V13h4V7.5Z" strokeWidth="1"></path>
        <path id="Vector 3964" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M9 4.5H5V13h4V4.5Z" strokeWidth="1"></path>
        <path id="Vector 3965" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M13 1H9v12h4V1Z" strokeWidth="1"></path>
    </g>
</svg>
);

export default Stats;