import SectionBox from '@/components/SectionBox';
import { SimpleFlowPath } from '@/models/simpleFlowPath';
import { FFButton, FFCol, FFIconButton, FFInput, FFRow, FFSelect, VisibilityWrapper } from '@/uikit';
import className from '@/utils/className';
import { forwardRef, Ref, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { Control, Controller, useForm } from 'react-hook-form';
import { PageGroup } from '@/models/pageGroup';
import { generateEntityId } from '@/utils/id';
import { usePageGroupPagesCategoryInfoQuery, usePageGroupPagesQuery } from '@/api/queries/pageGroup';
import { FFSelectOption } from '@/uikit/types/select';
import { Page } from '@/models/page';
import EntrySlider from '../EntrySlider';
import { SimpleFlowPathEditorFormRefType } from '@/types/forms/simpleFlow';
import useMitt from '@/hooks/mitt';
import { message as AntMessage } from 'antd';
import useFormStore from '@/stores/forms';
import { findDuplicateNames } from '@/utils/array';
import { defined } from '@/utils/define';
import './style.scss';

const defaultGroup = ({ pageType = 'lander' as PageGroup.PageTypeEnum, pageGroupName = '' }): PageGroup => ({
  pageGroupName,
  idPageGroup: generateEntityId(),
  status: 'active',
  routing: 'rotator',
  pageType: pageType,
  pages: [],
});

const { getClass } = className('c-pathEditor');

const PathGroup = ({
  group,
  control,
  groupIndex,
  landerPageOptions,
  offerPageOptions,
  pagesData,
  totalWeight,
  isLoadingPagesData,
  onChange,
  refetchPages,
}: {
  group: PageGroup;
  control: Control<SimpleFlowPath, any>;
  groupIndex: number;
  landerPageOptions: FFSelectOption[];
  offerPageOptions: FFSelectOption[];
  pagesData: Record<string, Page>;
  totalWeight: number;
  isLoadingPagesData: boolean;
  onChange: (group: PageGroup) => void;
  refetchPages: () => void;
}) => {
  const emitter = useMitt();
  const openLanderForm = useFormStore((state) => state.openLanderForm);
  const openOfferForm = useFormStore((state) => state.openOfferForm);
  const [isEditingName, setIsEditingName] = useState(false);

  const pageOptions = useMemo(() => {
    if (group.pageType === 'lander') {
      return landerPageOptions.filter((page) => !group.pages.some((groupPage) => groupPage.idPage === page.value));
    } else {
      return offerPageOptions.filter((page) => !group.pages.some((groupPage) => groupPage.idPage === page.value));
    }
  }, [landerPageOptions, offerPageOptions, group]);

  useEffect(() => {
    emitter.on('onLanderCreate', ({ data }) => {
      onAddPageToGroup(data.idPage);
    });
    emitter.on('onLanderUpdate', () => {
      refetchPages();
    });
    emitter.on('onOfferCreate', ({ data }) => {
      onAddPageToGroup(data.idPage);
    });
    emitter.on('onOfferUpdate', () => {
      refetchPages();
    });
  }, []);

  const onAddPageToGroup = (idPage: string) => {
    onChange({ ...group, pages: [...group.pages, { idPage, weight: 1 }] });
  };

  const onDeletePageOfGroup = (idPage: string) => {
    onChange({ ...group, pages: group.pages.filter((page) => page.idPage !== idPage) });
  };

  const toggleEditMode = () => setIsEditingName((prev) => !prev);

  const onPageChange = (idPage: string, weight: number) => {
    onChange({ ...group, pages: group.pages.map((page) => (page.idPage === idPage ? { ...page, weight } : page)) });
  };

  const onCreateNewPage = () => {
    if (group.pageType === 'lander') {
      openLanderForm();
    } else {
      openOfferForm();
    }
  };

  return (
    <SectionBox>
      <FFRow alignItems="center" gap="8px" borderBottom="1px solid #d6dee5" paddingBottom="12px">
        <FFIconButton
          buttonType="transparent"
          iconName={isEditingName ? 'general/line/check' : 'general/line/pencil'}
          onClick={toggleEditMode}
        />
        <Controller
          name={`groups.${groupIndex}.pageGroupName`}
          control={control}
          render={(opt) =>
            isEditingName ? (
              <FFInput value={opt.field.value} onChange={opt.field.onChange} placeholder="Group Name" />
            ) : (
              <span className={getClass('groupName')}>{opt.field.value || 'Group Name Here'}</span>
            )
          }
        />
      </FFRow>
      <FFRow marginTop="12px">
        <FFCol flex="1" gap="12px">
          {group.pages.map((pageGroupEntry) => (
            <EntrySlider
              key={pageGroupEntry.idPage}
              sliderEntry={pageGroupEntry}
              totalWeight={totalWeight}
              pageType={group.pageType}
              status={pagesData?.[pageGroupEntry.idPage]?.status || 'active'}
              loading={isLoadingPagesData}
              onChange={onPageChange}
              onDelete={onDeletePageOfGroup}
              getId={(e) => e.idPage}
              getName={(e) => pagesData[e.idPage]?.pageName}
            />
          ))}
        </FFCol>
      </FFRow>
      <FFRow alignItems="center" marginTop="12px">
        <FFCol width="200px">
          <FFSelect
            placeholder={`Select ${group.pageType === 'lander' ? 'Lander' : 'Offer'}`}
            options={pageOptions}
            showSearch
            labelGetter={(opt) => opt.label}
            valueGetter={(opt) => opt.value}
            groupOptions
            onSelect={onAddPageToGroup}
          />
        </FFCol>
        <FFCol>
          <FFButton type="ghost" iconName="general/line/add-circle" onClick={onCreateNewPage}>
            Create New {group.pageType === 'lander' ? 'Lander' : 'Offer'}
          </FFButton>
        </FFCol>
      </FFRow>
    </SectionBox>
  );
};

const PathEditor = forwardRef(({ allPaths }: { allPaths: SimpleFlowPath[] }, ref: Ref<SimpleFlowPathEditorFormRefType>) => {
  const emitter = useMitt();

  const defaultValues = useFormStore((state) => state.simpleFlowPathEditor.data);
  const closeSimpleFlowPathEditorForm = useFormStore((state) => state.closeSimpleFlowPathEditorForm);

  const [isEditingName, setIsEditingName] = useState(false);
  const [updatedGroupIds, setUpdatedGroupIds] = useState<string[]>([]);

  const { control, watch, setValue, handleSubmit } = useForm<SimpleFlowPath>({
    defaultValues: defaultValues || {},
  });

  const groups = watch('groups');
  const isDefault = watch('isDefault');

  const selectedPageIds = useMemo(() => {
    return groups
      .map((group) => group.pages)
      .flat()
      .map((page) => page.idPage);
  }, [groups]);

  const { data: landerPageCategoriesInfo = [] } = usePageGroupPagesCategoryInfoQuery('active', 'lander');
  const { data: offerPageCategoriesInfo = [] } = usePageGroupPagesCategoryInfoQuery('active', 'offer');
  const { data: pagesData = {}, isFetching: isLoadingPagesData, refetch: refetchPages } = usePageGroupPagesQuery(selectedPageIds);

  const landerPageOptions = useMemo(() => {
    const options: FFSelectOption[] = [];
    landerPageCategoriesInfo.forEach((category) => {
      (category.pages || []).forEach((page) => {
        options.push({
          value: page.idPage,
          label: page.pageName,
          category: category.categoryName,
        });
      });
    });
    return options;
  }, [landerPageCategoriesInfo]);

  const offerPageOptions = useMemo(() => {
    const options: FFSelectOption[] = [];
    offerPageCategoriesInfo.forEach((category) => {
      (category.pages || []).forEach((page) => {
        options.push({
          value: page.idPage,
          label: page.pageName,
          category: category.categoryName,
        });
      });
    });
    return options;
  }, [offerPageCategoriesInfo]);

  const totalWeight = useMemo(
    () => groups.reduce((acc, group) => acc + group.pages.reduce((acc, page) => acc + page.weight, 0), 0),
    [groups],
  );

  const toggleEditMode = () => setIsEditingName((prev) => !prev);

  const onAddNewLanderGroup = () => {
    setValue('groups', [
      ...groups,
      defaultGroup({
        pageType: 'lander',
        pageGroupName: `Lander Group ${groups.filter((group) => group.pageType === 'lander').length + 1}`,
      }),
    ]);
  };

  const onAddNewOfferGroup = () => {
    setValue('groups', [
      ...groups,
      defaultGroup({
        pageType: 'offer',
        pageGroupName: `Offer Group ${groups.filter((group) => group.pageType === 'offer').length + 1}`,
      }),
    ]);
  };

  const onGroupChange = (group: PageGroup) => {
    setUpdatedGroupIds([...updatedGroupIds, group.idPageGroup]);
    setValue(
      'groups',
      groups.map((g) => (g.idPageGroup === group.idPageGroup ? group : g)),
    );
  };

  const onSave = handleSubmit((data) => {
    const groupWithoutAnyPages = groups.find((group) => group.pages.length === 0);
    const duplicateNames = findDuplicateNames([...allPaths.filter((path) => path.pathId !== data.pathId), data], 'pathName');

    if (!data.pathName || data.pathName === 'New Path') {
      AntMessage.warning('All paths must have a name added');
      return;
    }
    if (duplicateNames.includes(data.pathName)) {
      AntMessage.warning('All paths must have unique names');
      return;
    }
    if (!data.groups.length) {
      AntMessage.warning('A path must have at least one page group in it');
      return;
    }
    if (defined(groupWithoutAnyPages)) {
      AntMessage.warning('Any group in a path must have at least one page in it');
      return;
    }

    emitter.emit('onSimpleFlowPathSave', { path: data, updatedGroupIds });
    closeSimpleFlowPathEditorForm();
  });

  const onInputBlur = () => {
    setIsEditingName(false);
  };

  useImperativeHandle(ref, () => ({ onSave }));

  return (
    <FFCol flex="1" gap="12px">
      <SectionBox>
        <FFRow alignItems="center" gap="8px">
          <VisibilityWrapper visible={!isDefault}>
            <FFIconButton
              buttonType="transparent"
              iconName={isEditingName ? 'general/line/check' : 'general/line/pencil'}
              onClick={toggleEditMode}
            />
          </VisibilityWrapper>
          <Controller
            name="pathName"
            control={control}
            render={(opt) =>
              isEditingName ? (
                <FFInput value={opt.field.value} placeholder="Path Name" onChange={opt.field.onChange} onBlur={onInputBlur} />
              ) : (
                <span className={getClass('pathName')}>{opt.field.value || 'Path Name Here'}</span>
              )
            }
          />
        </FFRow>
      </SectionBox>
      {groups.map((group, idx) => (
        <PathGroup
          group={group}
          control={control}
          groupIndex={idx}
          landerPageOptions={landerPageOptions}
          offerPageOptions={offerPageOptions}
          pagesData={pagesData}
          totalWeight={totalWeight}
          isLoadingPagesData={isLoadingPagesData}
          onChange={onGroupChange}
          refetchPages={refetchPages}
        />
      ))}
      <FFRow marginTop="16px" justifyContent="center" alignItems="center">
        <FFButton type="ghost" iconName="general/line/add-circle" onClick={onAddNewLanderGroup}>
          Add Lander Group
        </FFButton>
        <FFButton type="ghost" iconName="general/line/add-circle" onClick={onAddNewOfferGroup}>
          Add Offer Group
        </FFButton>
      </FFRow>
    </FFCol>
  );
});

export default PathEditor;
