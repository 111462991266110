import { SystemSettingsStore, SystemSettingsStoreProps } from '@/types/stores/systemSettings';
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

const DEFAULT_PROPS: SystemSettingsStoreProps = {
  domains: [],
  showProductTourButtons: false,
  colorMode: 'light',
  systemColorMode: 'light',
  selectedColorMode: 'light',
  userSettings: {
    defaultHomepageURL: '',
    defaultOfferRedirect: '307',
    defaultLanderRedirect: '307',
    ipAnonymizer: 'All IPs',
    defaultCustomDomain: '',
    thirdPartyKeys: {
      clickbankIPN: ''
    }
  }
};

const useSystemSettingsStore = create<SystemSettingsStore>()(
  devtools(
    persist(
      (set, get) => ({
        ...DEFAULT_PROPS,
        colorMode: get()?.selectedColorMode === 'system' ? get()?.systemColorMode : get()?.selectedColorMode,
        setDomains: domains => set({ domains }),
        setShowProductTourButtons: showProductTourButtons => set({ showProductTourButtons }),
        setUserSettings: userSettings => set({ userSettings }),
        setSelectedColorMode: colorMode => set({ colorMode }),
        setSystemColorMode: systemColorMode => set({ systemColorMode }),
        addDomain: domain => set({ domains: [...get().domains, domain] }),
        deleteDomain: domain => set({ domains: get().domains.filter(item => item.domain !== domain.domain) }),
      }),
      { name: 'systemsettings' }
    )
  )
);

export default useSystemSettingsStore;
