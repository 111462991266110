import { User } from '@/models/user';
import { gistUtils, loadGistScript } from '@/widgets/gist';
import { identifySegment, loadSegmentScript } from '@/widgets/segment';
import { SubscriptionStatus } from '@/models/subscription';
import { definedObject } from '@/utils/define';

export const isDevEnvironment = window.location.hostname === 'localhost';

export const loadGistAndSegmentScript = (user: User) => {
  loadSegmentScript();
  loadGistScript();
  gistUtils.identify(user);
  identifySegment(user);
  requestAnimationFrame(gistUtils.trackPageView);
};

export const isSubscriptionExpired = (user: User) => (definedObject(user) ? user.metadata?.subscription_status === 'cancelled' : false);

export const isSubscriptionStandby = (user: User) =>
  definedObject(user) ? user.metadata?.plan_id === 'standby' && user.metadata?.subscription_status !== 'cancelled' : false;

export const isStandByAndExpired = (subscriptionStatus: SubscriptionStatus) => subscriptionStatus.isStandby && subscriptionStatus.isExpired;

export const isStandByAndNotExpired = (subscriptionStatus: SubscriptionStatus) =>
  subscriptionStatus.isStandby && !subscriptionStatus.isExpired;

export const isNotStandByAndExpired = (subscriptionStatus: SubscriptionStatus) =>
  !subscriptionStatus.isStandby && subscriptionStatus.isExpired;

export const isJustStandBy = (subscriptionStatus: SubscriptionStatus) => subscriptionStatus.isStandby;

export const isJustExpired = (subscriptionStatus: SubscriptionStatus) => subscriptionStatus.isExpired;

export const isStandByOrExpired = (subscriptionStatus: SubscriptionStatus) => subscriptionStatus.isStandby || subscriptionStatus.isExpired;

export const isNotStandByAndNotExpired = (subscriptionStatus: SubscriptionStatus) =>
  !subscriptionStatus.isStandby && !subscriptionStatus.isExpired;
