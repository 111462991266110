import { equalObjects } from './object';
import { defined } from './define';
import { Category } from '@/models/models';
import { ReportCategory } from '@/models/reportCategory';

export const symmetricDifference = (arr1: any[], arr2: any[]) =>
  arr1.filter((x) => !arr2.includes(x)).concat(arr2.filter((x) => !arr1.includes(x)));

// Checks 2 arrs
export const arrEquals = (arr1: any[], arr2: any[]) => {
  if (!arr1 || !arr2) return false;

  if (arr1.length !== arr2.length) return false;

  for (let i = 0, l = arr1.length; i < l; i++) {
    if (arr1[i] instanceof Array && arr2[i] instanceof Array) {
      if (!arr1[i].equals(arr2[i])) return false;
      // something like this
    } else if (arr1[i] instanceof Object && arr2[i] instanceof Object) {
      if (!Object.is(arr1[i], arr2[i])) {
        return false;
      }
    } else if (arr1[i] !== arr2[i]) {
      return false;
    }
  }
  return true;
};

export const isEmpty = (arr: any[]) => arr.length === 0;

export const flatternArr = (arr: any[]) => {
  return arr.reduce(
    (acc, item) =>
      //@ts-ignore
      Array.isArray(item) ? [...acc, ...this.flattenArr(item)] : [...acc, item],
    [],
  );
};

export const arrayDifference = (first: any[], second: any[]) =>
  first.filter((value, idx) => {
    if (value instanceof Object) {
      return !(second[idx] instanceof Object && equalObjects(value, second[idx]));
    } else {
      return !second.includes(value);
    }
  });

export const arrayDifferenceWithTransposition = (first: any[], second: any[]) => {
  const difference = arrayDifference(first, second);
  return difference.length > 0 ? difference : arrayDifference(second, first);
};

export const arrayIntersection = (first: any[], second: any[]) => first.filter((f) => second.includes(f));

export const swapPositionsIn = (arr: any[], idx1 = 0, idx2 = 1) => {
  let buffer;
  let array = [...arr];

  if (idx1 < 0 && idx2 < 0) {
    buffer = array[array.length + idx2];
    array[array.length + idx2] = array[array.length + idx1];
    array[array.length + idx1] = buffer;
  }

  if (idx1 >= 0 && idx2 >= 0) {
    buffer = array[idx2];
    array[idx2] = array[idx1];
    array[idx1] = buffer;
  }

  return array;
};

export const arrayToObject = (array: any[], keyName: string) =>
  array.reduce((obj, item) => {
    obj[item[keyName]] = item;
    return obj;
  }, {});

export const checkAllValuesBeNumber = (array: any[]) => array.every((i) => typeof i === 'number');

export const createArrayWithEmptyObjects = (length = 0) => {
  let arr = [];
  for (let i = 0; i <= length; i++) {
    arr.push({});
  }

  return arr;
};

export const sortObjectArrayByKeyValue = (data: any[], key: string, nameArray: string[]) => {
  if (data.length && nameArray.length) {
    nameArray.forEach((name) => {
      data.forEach((item, i) => {
        if (defined(item[key]) && item[key] === name) {
          data.splice(i, 1);
          data.unshift(item);
        }
      });
    });
  }
};

export const uniqueArray = <T>(value: T, index: number, array: T[]) => {
  return array.indexOf(value) === index;
};

export const uniqueArrValues = <T>(arr: T[], isRepeatedDataCondition?: (v: T) => boolean, newData?: T): T[] => {
  if (!arr.length && defined(newData)) {
    return [newData];
  }
  return arr.reduce(
    (acc: T[], crr) =>
      (isRepeatedDataCondition ? isRepeatedDataCondition(crr) : crr === newData || acc.includes(crr)) ? acc : [...acc, crr],
    newData ? [newData] : [],
  );
};

export const getCorrectCategoryID = (
  prevCategories: Category[] = [],
  newCategories: Category[] = [],
  handler: (categoryID: string) => void,
) => {
  if (prevCategories.length > newCategories.length) {
    if (defined(newCategories[0])) {
      handler(newCategories[0].idCategory || '');
    } else {
      handler('');
    }
  } else if (prevCategories.length < newCategories.length) {
    if (defined(newCategories[newCategories.length - 1])) {
      handler(newCategories[newCategories.length - 1].idCategory || '');
    } else {
      handler('');
    }
  }
};

export const reportCategoriesToCategories = (data: ReportCategory[]): Category[] => {
  return data.map(
    (opt) =>
      ({
        idCategory: opt.id,
        categoryName: opt.name,
        categoryType: opt.type,
        status: opt.status,
      } as Category),
  );
};

export const nonEmptyArrayByPriority = <T>(arr: T[][]) => {
  for (const x of arr) {
    if (x !== undefined && x.length > 0) {
      return x;
    }
  }
  return [];
};

export const repeatObject = <T extends object>(obj: T, n: number): Array<T> => {
  const arr: Array<T> = [];
  for (let i = 0; i < n; i++) {
    arr.push({ ...obj });
  }
  return arr;
};

export const findDuplicateNames = <T extends { [key in K]: string }, K extends keyof T>(arr: T[], key: K) => {
  const nameCount = arr.reduce((acc: { [key: string]: number }, obj) => {
    acc[obj[key]] = (acc[obj[key]] || 0) + 1;
    return acc;
  }, {});
  const duplicates = Object.keys(nameCount).filter((name) => nameCount[name] > 1);
  return duplicates;
};

export const hasNonExistingItem = (arrayA: string[], arrayB: string[]): boolean => {
  return arrayA.some((item) => !arrayB.includes(item));
};

export const arrayMove = <T>(arr: T[], fromIndex: number, toIndex: number) => {
  const newArr = arr.slice();
  const element = newArr[fromIndex];
  newArr.splice(fromIndex, 1);
  newArr.splice(toIndex, 0, element);
  return newArr;
}

