import { Suspense } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { ConfigProvider, theme } from 'antd';
import { FFNewIcon, FFRow } from '@/uikit';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import useSystemSettingsStore from '@/stores/systemSettings';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

type AppProviderProps = {
  children: React.ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  const colorMode = useSystemSettingsStore((state) => state.colorMode);
  const isDarkMode = colorMode === 'dark';

  return (
    <Suspense
      fallback={
        <FFRow height="100vh" width="100vw" position="fixed" alignItems="center" justifyContent="center">
          <FFNewIcon name="general/app-loading" width={100} height={100} />
        </FFRow>
      }
    >
      <HelmetProvider>
        <QueryClientProvider client={queryClient}>
          <ConfigProvider
            theme={{
              algorithm: isDarkMode ? theme.darkAlgorithm : theme.defaultAlgorithm,
              token: {
                fontFamily: 'Noto Sans, Arial, Helvetica, sans-serif',
                fontSize: 14,
                fontSizeLG: 14,
                fontSizeSM: 14,
                controlHeight: 35,
              },
            }}
          >
            {children}
            <ReactQueryDevtools initialIsOpen={false} />
          </ConfigProvider>
        </QueryClientProvider>
      </HelmetProvider>
    </Suspense>
  );
};
