import { useTrafficSourceCategoriesInfoQuery, useTrafficSourceQuery } from '@/api/queries/general';
import CodeSnippet from '@/components/CodeSnippet';
import SectionBox from '@/components/SectionBox';
import { Page } from '@/models/page';
import { PageGroup } from '@/models/pageGroup';
import useSystemSettingsStore from '@/stores/systemSettings';
import { FFCol, FFField, FFInput, FFSelect, FFText } from '@/uikit';
import { FFSelectOption } from '@/uikit/types/select';
import { ucFirst } from '@/utils/string';
import { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';

interface RedirectLinkSettings {
  idTrafficSource: string;
  cost: string;
  domain: string;
  idPage: string;
}

const RedirectLinks = ({
  nodeId,
  idFunnel,
  pages,
  pageType,
}: {
  nodeId: string;
  idFunnel: string;
  pages: Page[];
  pageType: PageGroup.PageTypeEnum;
}) => {
  const { domains, userSettings } = useSystemSettingsStore();
  const {
    control,
    formState: { errors },
    watch,
  } = useForm<RedirectLinkSettings>({
    defaultValues: {
      domain: userSettings.defaultCustomDomain,
      idPage: '-1',
    },
  });
  const idTrafficSource = watch('idTrafficSource');
  const domain = watch('domain');
  const cost = watch('cost');

  const { data: trafficSourceGroups = [], isLoading: trafficSourceGroupsIsPending } = useTrafficSourceCategoriesInfoQuery();
  const { data: trafficSource, isLoading: trafficSourceIsLoading } = useTrafficSourceQuery(idTrafficSource);

  const trafficSourceOptions = useMemo(() => {
    const trafficSourceOptions: FFSelectOption[] = [];
    for (const trafficSourceGroup of trafficSourceGroups) {
      (trafficSourceGroup.trafficSources || []).forEach((trafficSource) => {
        trafficSourceOptions.push({
          value: trafficSource.idTrafficSource,
          label: trafficSource.trafficSourceName,
          category: trafficSourceGroup.categoryName,
        });
      });
    }
    return trafficSourceOptions;
  }, [trafficSourceGroups]);

  const pageOptions = useMemo(() => {
    const pageOptions: FFSelectOption[] = [
      {
        value: '-1',
        label: `${ucFirst(pageType)} Group (Rotator)`,
      },
    ];
    pages.forEach((page) => {
      pageOptions.push({
        value: page.idPage!,
        label: page.pageName!,
      });
    });
    return pageOptions;
  }, [pages, pageType]);

  const getCode = useMemo(() => {
    if (trafficSourceIsLoading) return 'Fetching TrafficSource Data...';
    if (!trafficSource) return 'Please select a traffic source first';

    let url = new URL(`https://${domain}/fts/${idFunnel}-${idTrafficSource}/${nodeId}`);
    Object.values(trafficSource?.trackingFieldSlots || {}).forEach((value) => {
      url.searchParams.append(value.name, value.value);
    });
    url.searchParams.append('c', cost || trafficSource?.defaultCost || '');
    return decodeURI(url.toString());
  }, [idFunnel, nodeId, idTrafficSource, domain, cost, trafficSource, trafficSourceIsLoading]);

  return (
    <SectionBox title="Redirect Links">
      <FFCol gap="12px">
        <FFText.P type="body-2">
          Generate redirect links that point to FunnelFlux and route visitors according to your funnel design. These links allow you to use
          rotators/conditions to control page destination and split-test, as well as change the funnel at any time without needing to update
          your ads.
        </FFText.P>
        <FFField label="Node ID" block direction="row">
          <CodeSnippet code={nodeId} />
        </FFField>
        <Controller
          name="idTrafficSource"
          control={control}
          rules={{ required: 'Traffic Source is required' }}
          render={(opt) => (
            <FFField label="Traffic Source" block direction="row">
              <FFSelect
                disabled={trafficSourceGroupsIsPending}
                loading={trafficSourceGroupsIsPending}
                error={errors.idTrafficSource?.message}
                value={opt.field.value}
                onChange={opt.field.onChange}
                options={trafficSourceOptions}
                groupOptions={true}
                sortGroup
                selectAll
                showSearch
                autoFocus
                valueGetter={(opt) => opt.value}
                labelGetter={(opt) => opt.label}
                placeholder="Select a Traffic Source"
              />
            </FFField>
          )}
        />
        <Controller
          name="cost"
          control={control}
          render={(opt) => (
            <FFField label="Entrance cost (optional)" block direction="row">
              <FFInput value={opt.field.value} onChange={opt.field.onChange} placeholder="Entrance cost (optional)" />
            </FFField>
          )}
        />
        <Controller
          name="domain"
          control={control}
          render={(opt) => (
            <FFField label="Tracking Domain" block direction="row">
              <FFSelect
                value={opt.field.value}
                onChange={opt.field.onChange}
                options={domains}
                valueGetter={(opt) => opt.domain}
                labelGetter={(opt) => opt.domain}
              />
            </FFField>
          )}
        />
        <Controller
          name="idPage"
          control={control}
          render={(opt) => (
            <FFField label={ucFirst(pageType)} block direction="row">
              <FFSelect
                value={opt.field.value}
                onChange={opt.field.onChange}
                options={pageOptions}
                valueGetter={(opt) => opt.value}
                labelGetter={(opt) => opt.label}
              />
            </FFField>
          )}
        />
        <CodeSnippet code={getCode} multiline />
      </FFCol>
    </SectionBox>
  );
};

export default RedirectLinks;
