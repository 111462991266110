import useMitt from '@/hooks/mitt';
import useFormStore from '@/stores/forms';
import { FFButton, FFCol, FFRow, FFSidePanel } from '@/uikit';
import { getSidebarZIndex } from '@/utils/sidebar';

const Versioning = () => {
  const emitter = useMitt();
  const { versioning, openedForms, closeVersioningForm } = useFormStore();

  const onConfirm = () => {
    emitter.emit('onVersioningConfirm', versioning.type);
  };

  const onCancel = () => {
    closeVersioningForm();
    emitter.emit('onVersioningCancel', versioning.type);
  };

  return (
    <FFSidePanel
      isOpen={versioning.isOpen}
      maxWidth={600}
      onClose={closeVersioningForm}
      title=""
      sidebarName="ConfirmVersion"
      zIndex={getSidebarZIndex(openedForms, 'versioning')}
      actions={
        <FFRow gap="8px">
          <FFButton onClick={onConfirm}>Yes</FFButton>
          <FFButton type="tertiary" onClick={onCancel}>
            No
          </FFButton>
        </FFRow>
      }
    >
      <FFCol gap="12px">
        <p>The resource you are trying to save has been updated since you opened it.</p>
        <p>Choose [YES] to forcefully save your changes, overwriting any existing data.</p>
        <p>Choose [NO] to cancel your save attempt. You can then discard your changes and manually reload this resource.</p>
      </FFCol>
    </FFSidePanel>
  );
};

export default Versioning;
