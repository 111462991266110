import className from '@/utils/className';
import logo from '@/imgs/logo.svg';
import { FFButton, FFCol, FFRow } from '@/uikit';
import './style.scss';

const { getClass } = className('c-errorFallback');

export default function ErrorFallback() {
  const onRefresh = () => {
    window.location.reload();
  };

  return (
    <FFRow height="100%" gap="20px" alignItems="center" justifyContent="center">
      <img src={logo} alt="ff logo" className={getClass('logo')} />
      <FFCol alignItems="flex-start" gap="12px">
        <div className={getClass('errorText')}>
          A Javascript error has occurred.
          <br /> We've already logged the error and will try to fix it as soon as possible. <br />
        </div>
        <FFButton onClick={onRefresh}>Refresh</FFButton>
      </FFCol>
    </FFRow>
  );
}
