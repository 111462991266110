/**
 * FunnelFlux Edge Domain Manager API
 * API endpoints to manage Edge service domains
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type DomainPlan = 'FREE' | 'PREMIUM' | 'V2' | '2';

export const DomainPlan = {
  FREE: 'FREE' as DomainPlan,
  PREMIUM: 'PREMIUM' as DomainPlan,
  V2: 'V2' as DomainPlan,
  '2': '2' as DomainPlan
};
