import {
  ConditionRuleSelectParams,
  connectionTypeParams,
  continentSelectParams,
  countrySelectParams,
  dayOfMonthParams,
  dayOfWeekParams,
  deviceBrowserLanguageParams,
  deviceOsSelectParams,
  deviceTypeSelectParams,
  monthOfYearParams,
  timezoneSelectParams,
} from '@/constants/conditions';
import * as dayjs from 'dayjs';
import { FunnelConditionRule } from '@/models/funnelConditionRule';
import { DomainEntry } from '@/models/models';
import { FFInput, FFSelect } from '@/uikit';
import { defined } from '@/utils/define';
import { sortBasedOnOrder, sortByName } from '@/utils/sort';
import { DatePicker, TimePicker } from 'antd';
import { PropsWithChildren } from 'react';
import { Control, Controller, useWatch } from 'react-hook-form';
import { FFSelectOption } from '@/uikit/types/select';
import { SimpleFlowRule } from '@/models/simpleFlowRules';

interface Props {
  rule: FunnelConditionRule;
  idGroup: number;
  idRule: number;
  trafficSources: FFSelectOption[];
  domains: DomainEntry[];
  control: Control<SimpleFlowRule, any>;
}

const getSelectParams = (
  attribute: FunnelConditionRule.AttributeEnum,
  trafficSources: FFSelectOption[] = [],
  domains: DomainEntry[] = [],
): ConditionRuleSelectParams => {
  switch (attribute) {
    case FunnelConditionRule.AttributeEnum.LocationContinent:
      return continentSelectParams;
    case FunnelConditionRule.AttributeEnum.LocationCountry:
      return countrySelectParams;
    case FunnelConditionRule.AttributeEnum.LocationTimezone:
      return timezoneSelectParams;
    case FunnelConditionRule.AttributeEnum.DeviceType:
      return deviceTypeSelectParams;
    case FunnelConditionRule.AttributeEnum.DeviceOS:
      return deviceOsSelectParams;
    case FunnelConditionRule.AttributeEnum.DeviceBrowserLanguage:
      return deviceBrowserLanguageParams;
    case FunnelConditionRule.AttributeEnum.ConnectionType:
      return connectionTypeParams;
    case FunnelConditionRule.AttributeEnum.TimeDayOfWeek:
      return dayOfWeekParams;
    case FunnelConditionRule.AttributeEnum.TimeDayOfMonth:
      return dayOfMonthParams;
    case FunnelConditionRule.AttributeEnum.TimeMonthOfYear:
      return monthOfYearParams;
    case FunnelConditionRule.AttributeEnum.TrafficSource:
      return {
        defaultValueFallback: {
          label: 'Organic traffic',
          value: 'organic',
        },
        options: sortByName(trafficSources, 'label'),
        valueGetter: (opt: FFSelectOption) => opt.value,
        labelGetter: (opt: FFSelectOption) => opt.label,
      };
    case FunnelConditionRule.AttributeEnum.TrackingDomain:
      return {
        options: sortBasedOnOrder(domains, 'domain'),
        valueGetter: (opt: DomainEntry) => opt.domain,
        labelGetter: (opt: DomainEntry) => opt.domain,
      };
    default:
      return {
        options: [],
        labelGetter: (opt) => opt,
        valueGetter: (opt) => opt,
      };
  }
};

const RouteGroupRule = ({ rule, idGroup, idRule, trafficSources, domains, control, children }: PropsWithChildren<Props>) => {
  const isPlural = [
    FunnelConditionRule.TestEnum.AnyIn,
    FunnelConditionRule.TestEnum.NotIn,
    FunnelConditionRule.TestEnum.Contains,
    FunnelConditionRule.TestEnum.DoesNotContain,
  ].includes(rule.test);
  const route = useWatch({
    control,
    name: 'rule'
  });
  const attribute = route?.groups?.[idGroup]?.rules?.[idRule].attribute;

  switch (attribute) {
    case FunnelConditionRule.AttributeEnum.LocationContinent:
    case FunnelConditionRule.AttributeEnum.LocationCountry:
    case FunnelConditionRule.AttributeEnum.LocationTimezone:
    case FunnelConditionRule.AttributeEnum.DeviceType:
    case FunnelConditionRule.AttributeEnum.DeviceOS:
    case FunnelConditionRule.AttributeEnum.DeviceBrowserLanguage:
    case FunnelConditionRule.AttributeEnum.ConnectionType:
    case FunnelConditionRule.AttributeEnum.TrafficSource:
    case FunnelConditionRule.AttributeEnum.TrackingDomain:
      return (
        <>
          {children}
          <Controller
            name={`rule.groups.${idGroup}.rules.${idRule}.genericParams.values`}
            control={control}
            rules={{ required: 'Required' }}
            render={(opt) => (
              <FFSelect
                {...getSelectParams(rule.attribute, trafficSources, domains)}
                onChange={(values) => {
                  opt.field.onChange(Array.isArray(values) ? values : [values]);
                }}
                value={opt.field.value}
                showSearch
                autoFocus
                style={{ width: 240 }}
                placeholder="Select value(s)"
                mode={isPlural ? 'multiple' : undefined}
                error={opt.fieldState.error?.message}
              />
            )}
          />
        </>
      );
    case FunnelConditionRule.AttributeEnum.LocationCity:
    case FunnelConditionRule.AttributeEnum.LocationRegion:
    case FunnelConditionRule.AttributeEnum.DeviceBrand:
    case FunnelConditionRule.AttributeEnum.DeviceOSVersion:
    case FunnelConditionRule.AttributeEnum.DeviceModel:
    case FunnelConditionRule.AttributeEnum.DeviceBrowser:
    case FunnelConditionRule.AttributeEnum.DeviceBrowserVersion:
    case FunnelConditionRule.AttributeEnum.ConnectionIP:
    case FunnelConditionRule.AttributeEnum.ConnectionISP:
    case FunnelConditionRule.AttributeEnum.ConnectionUserAgent:
    case FunnelConditionRule.AttributeEnum.ConnectionReferrer:
    case FunnelConditionRule.AttributeEnum.ConnectionCurrentURL:
      return (
        <>
          {children}
          <Controller
            name={`rule.groups.${idGroup}.rules.${idRule}.genericParams.values`}
            control={control}
            rules={{ required: 'Required' }}
            render={(opt) => (
              <FFInput
                error={opt.fieldState.error?.message}
                onChange={(e) => {
                  opt.field.onChange(e.target.value.split(','));
                }}
                value={(opt.field.value || []).join(',')}
                placeholder="Select value(s)"
              />
            )}
          />
        </>
      );
    case FunnelConditionRule.AttributeEnum.TimeDate:
      return (
        <>
          {children}
          <Controller
            name={`rule.groups.${idGroup}.rules.${idRule}.timeDateParams`}
            control={control}
            rules={{ required: 'Required' }}
            render={(opt) => (
              <DatePicker
                status={opt.fieldState.error?.message ? 'error' : undefined}
                style={{ width: 240 }}
                onChange={(date) => {
                  if (defined(date)) {
                    opt.field.onChange({
                      day: date.date(),
                      month: date.month() + 1,
                      year: date.year(),
                    });
                  }
                }}
                value={
                  defined(opt.field.value)
                    ? dayjs.default(`${opt.field.value.year}.${opt.field.value.month}.${opt.field.value.day}`, 'YYYY-MM-DD')
                    : undefined
                }
                showTime={false}
                format="DD.MM.YYYY"
              />
            )}
          />
        </>
      );
    case FunnelConditionRule.AttributeEnum.TimeDayOfWeek:
      return (
        <>
          {children}
          <Controller
            name={`rule.groups.${idGroup}.rules.${idRule}.timeDayOfWeekParams.day`}
            control={control}
            rules={{ required: 'Required' }}
            render={(opt) => (
              <FFSelect
                {...getSelectParams(rule.attribute)}
                onChange={(value) => opt.field.onChange(Number(value))}
                value={opt.field.value}
                showSearch
                autoFocus
                placeholder="Select value(s)"
                mode={isPlural ? 'multiple' : undefined}
                error={opt.fieldState.error?.message}
              />
            )}
          />
        </>
      );
    case FunnelConditionRule.AttributeEnum.TimeDayOfMonth:
      return (
        <>
          {children}
          <Controller
            name={`rule.groups.${idGroup}.rules.${idRule}.timeDayOfMonthParams.day`}
            control={control}
            rules={{ required: 'Required' }}
            render={(opt) => (
              <FFSelect
                {...getSelectParams(rule.attribute)}
                onChange={(value) => opt.field.onChange(Number(value))}
                value={opt.field.value}
                showSearch
                autoFocus
                placeholder="Select value(s)"
                mode={isPlural ? 'multiple' : undefined}
                error={opt.fieldState.error?.message}
              />
            )}
          />
        </>
      );
    case FunnelConditionRule.AttributeEnum.TimeMonthOfYear:
      return (
        <>
          {children}
          <Controller
            name={`rule.groups.${idGroup}.rules.${idRule}.timeMonthOfYearParams.month`}
            control={control}
            rules={{ required: 'Required' }}
            render={(opt) => (
              <FFSelect
                {...getSelectParams(rule.attribute)}
                onChange={(value) => opt.field.onChange(value)}
                value={opt.field.value}
                showSearch
                autoFocus
                placeholder="Select value(s)"
                mode={isPlural ? 'multiple' : undefined}
                error={opt.fieldState.error?.message}
              />
            )}
          />
        </>
      );
    case FunnelConditionRule.AttributeEnum.TimeTimeOfDay:
      return (
        <>
          {children}
          <Controller
            name={`rule.groups.${idGroup}.rules.${idRule}.timeOfDayParams`}
            control={control}
            rules={{ required: 'Required' }}
            render={(opt) => (
              <TimePicker
                value={
                  defined(opt.field.value) && defined(opt.field.value.hour) && defined(opt.field.value.minutes)
                    ? dayjs.default(`${opt.field.value.hour}:${opt.field.value.minutes}`, 'HH:mm')
                    : undefined
                }
                format="HH:mm"
                onChange={(time) => {
                  const value = defined(time) ? { hour: time.hour(), minutes: time.minute() } : { hour: null, minutes: null };
                  opt.field.onChange(value);
                }}
                placeholder="Select Time"
              />
            )}
          />
        </>
      );
    case FunnelConditionRule.AttributeEnum.TrackingField:
      return (
        <>
          <Controller
            name={`rule.groups.${idGroup}.rules.${idRule}.trackingFieldParams.fieldName`}
            control={control}
            rules={{ required: 'Required' }}
            render={(opt) => (
              <FFInput
                value={opt.field.value}
                error={opt.fieldState.error?.message}
                onChange={(e) => opt.field.onChange(e.target.value)}
                placeholder="Enter Field Name"
              />
            )}
          />
          {children}
          <Controller
            name={`rule.groups.${idGroup}.rules.${idRule}.trackingFieldParams.fieldValues`}
            control={control}
            rules={{ required: 'Required' }}
            render={(opt) => (
              <FFInput
                value={opt.field.value}
                error={opt.fieldState.error?.message}
                onChange={(e) => opt.field.onChange(e.target.value.split(','))}
                placeholder="Enter Value(s)"
              />
            )}
          />
        </>
      );
    default:
      return <></>;
  }
};

export default RouteGroupRule;
