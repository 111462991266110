import { TrafficSource } from '@/models/models';
import { FFCol, FFField, FFInput } from '@/uikit';
import { Control, Controller } from 'react-hook-form';

const TwitterCustomScenario = ({
  triggeringEvent,
  control,
}: {
  triggeringEvent: string;
  control: Control<TrafficSource>;
}) => {
  return (
    <FFCol gap="12px">
      <Controller
        name={`conversionTrackingSettings.${triggeringEvent}.customEventData.userId`}
        control={control}
        rules={{ required: 'Required' }}
        render={opt => (
          <FFField label="User ID:" block direction="row">
            <FFInput
              value={opt.field.value}
              onChange={opt.field.onChange}
              error={opt.fieldState.error?.message}
              placeholder="Your User ID"
              style={{ width: 280 }}
            />
          </FFField>
        )}
      />
      <Controller
        name={`conversionTrackingSettings.${triggeringEvent}.customEventData.pixelId`}
        control={control}
        rules={{ required: 'Required' }}
        render={opt => (
          <FFField label="Pixel ID:" block direction="row">
            <FFInput
              value={opt.field.value}
              onChange={opt.field.onChange}
              error={opt.fieldState.error?.message}
              placeholder="Your Pixel ID"
              style={{ width: 280 }}
            />
          </FFField>
        )}
      />
      <Controller
        name={`conversionTrackingSettings.${triggeringEvent}.customEventData.pixelEventId`}
        control={control}
        rules={{ required: 'Required' }}
        render={opt => (
          <FFField label="Pixel Event ID:" block direction="row">
            <FFInput
              value={opt.field.value}
              onChange={opt.field.onChange}
              error={opt.fieldState.error?.message}
              placeholder="Pixel Event ID"
              style={{ width: 280 }}
            />
          </FFField>
        )}
      />
    </FFCol>
  );
};

export default TwitterCustomScenario;
