import useHttp from '@/hooks/http';
import { Page } from '@/models/page';
import { useMutation } from '@tanstack/react-query';

export function useLanderCreateMutation() {
  const { post } = useHttp();
  return useMutation<Page, Error, Page>({
    mutationKey: ['landerForm', 'lander', 'create'],
    mutationFn: async (page: Page) => {
      const { data } = await post<Page, Page>('v1/page/save', page);
      return data;
    },
  });
}

export function useLanderUpdateMutation() {
  const { put } = useHttp();
  return useMutation<Page, Error, Page>({
    mutationKey: ['lander', 'update'],
    mutationFn: async (page: Page) => {
      const { data } = await put<Page, Page>('v1/page/save', page);
      return data;
    },
  });
}

export function useLanderDuplicateMutation() {
  const { post } = useHttp();
  return useMutation<Page, Error, Page>({
    mutationKey: ['lander', 'duplicate'],
    mutationFn: async (page: Page) => {
      const { data } = await post<Page, Page>('v1/page/duplicate/', page);
      return data;
    },
  });
}

export function useLanderDeleteMutation() {
  const { del } = useHttp();

  return useMutation<null, Error, string>({
    mutationKey: ['lander', 'delete'],
    mutationFn: async (id: string) => {
      await del('v1/page/delete/', {
        data: { entries: [id] },
      });
      return null;
    },
  });
}

export function useLanderArchiveMutation() {
  const { put } = useHttp();

  return useMutation<null, Error, Page>({
    mutationKey: ['lander', 'archive'],
    mutationFn: async (page: Page) => {
      await put<Page, null>('v1/page/save/', page);
      return null;
    },
  });
}

export function useLanderUnArchiveMutation() {
  const { put } = useHttp();

  return useMutation<null, Error, Page>({
    mutationKey: ['lander', 'unarchive'],
    mutationFn: async (page: Page) => {
      await put<Page, null>('v1/page/save/', page);
      return null;
    },
  });
}
