import SectionBox from '@/components/SectionBox';
import useHttp from '@/hooks/http';
import { FunnelGroup } from '@/models/funnelGroup';
import useFormStore from '@/stores/forms';
import { FFButton, FFCol, FFField, FFInput, FFNewIcon, FFRow, FFSidePanel, VisibilityWrapper } from '@/uikit';
import { SidebarTab } from '@/uikit/types/sidebar';
import { generateEntityId } from '@/utils/id';
import { getSidebarOffsetLevel, getSidebarZIndex } from '@/utils/sidebar';
import { removeNonDigits, trimStringPropertiesInObject, withoutWhiteSpace } from '@/utils/string';
import { message as AntMessage } from 'antd';
import { Ref, forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Skeleton from 'react-loading-skeleton';
import { useFunnelGroupQuery } from '@/api/queries/funnel';
import useMitt from '@/hooks/mitt';
import { useFunnelGroupCreateMutation, useFunnelGroupDuplicateMutation, useFunnelGroupUpdateMutation } from '@/api/mutations/funnel';
import { withIncrementedVersion } from '@/utils/model';

type TabId = 'general' | 'optionalSettings';

const DefaultCostTooltip = () => (
  <>
    <p>
      If you add a value here, all funnels in this group will by default inherit this when generating links, unless a cost is added at a
      more specific level. A cost is assigned to every visitor who enters a funnel (basically a cost per click).
    </p>
    ,<p>When generating links for your funnel, we check for default costs in the following order:</p>,
    <ul>
      <li>Funnel Group (can only add a cost value)</li>
      <li>Funnel (can only add a cost value)</li>
      <li>Traffic Source (can use values or tokens/text)</li>
      <li>Cost field in the link generation form</li>
    </ul>
    ,<p>These parameters are appended to URLs with …&c=X</p>
  </>
);

interface RefType {
  onSave: () => void;
}

const DEFAULT_FUNNELGROUP: FunnelGroup = {
  idCampaign: '',
  campaignName: '',
};

const tabs: SidebarTab<TabId>[] = [
  {
    title: 'General Settings',
    tabId: 'general',
    icon: <FFNewIcon name="sidebar-tabs/general-settings" size="md" display="inline-block" type="sidebartab" />,
  },
  {
    title: 'Optional Settings',
    tabId: 'optionalSettings',
    icon: <FFNewIcon name="sidebar-tabs/optional-settings" size="md" display="inline-block" type="sidebartab" />,
  },
];

interface FormProps {
  closeForm: () => void;
  currentTabId: TabId;
  defaultFormValues: FunnelGroup | null;
  submitLoading: boolean;
  setSubmitLoading: (loading: boolean) => void;
}

const Form = forwardRef(({ currentTabId, defaultFormValues, closeForm, setSubmitLoading }: FormProps, ref: Ref<RefType>) => {
  const { get: httpGet } = useHttp();
  const emitter = useMitt();

  const { mutateAsync: createFunnelGroup } = useFunnelGroupCreateMutation();
  const { mutateAsync: updateFunnelGroup } = useFunnelGroupUpdateMutation();
  const { mutateAsync: duplicateFunnelGroup } = useFunnelGroupDuplicateMutation();

  const isDuplication = useFormStore((state) => state.funnelGroup.isDuplication);
  const openVersioningForm = useFormStore((state) => state.openVersioningForm);
  const setVersioningType = useFormStore((state) => state.setVersioningType);
  const [latestVersionOfFunnelGroup, setLatestVersionOfFunnelGroup] = useState<FunnelGroup>();

  const { handleSubmit, control, setValue, getValues } = useForm<FunnelGroup>({
    defaultValues: defaultFormValues || DEFAULT_FUNNELGROUP,
  });

  useEffect(() => {
    emitter.on('onVersioningConfirm', onVersionConfirm);
  }, []);

  const onSaveAndCreate = () => {
    onSave(true);
  };

  const onClose = () => {
    emitter.all.clear();
    closeForm();
  };

  const onSave = (isSaveAndCreate = false) =>
    handleSubmit(async (data) => {
      setSubmitLoading(true);

      try {
        const newID = generateEntityId();
        const model = trimStringPropertiesInObject<FunnelGroup>(data, ['campaignName']);
        const updateModel = withIncrementedVersion(model);
        const duplicateModel: FunnelGroup = { ...model, meta: { version: 1 } };
        const createModel: FunnelGroup = { ...model, idCampaign: newID, meta: { version: 1 } };

        if (isDuplication) {
          await duplicateFunnelGroup(duplicateModel);
          emitter.emit('onFunnelGroupCreate', duplicateModel);
        } else if (model.idCampaign) {
          const funnelGroup = await httpGet<FunnelGroup>('v1/campaign/find/byId', {
            params: { idCampaign: model.idCampaign },
          });
          setLatestVersionOfFunnelGroup(funnelGroup.data);
          if (model.meta?.version !== funnelGroup.data.meta?.version) {
            setVersioningType('funnel');
            openVersioningForm();
          } else {
            await updateFunnelGroup(updateModel);
            emitter.emit('onFunnelGroupUpdate', updateModel);
          }
        } else {
          await createFunnelGroup(createModel);
          setValue('idCampaign', '');
          emitter.emit('onFunnelGroupCreate', createModel);
        }

        AntMessage.success(`${model.campaignName} ${data.idCampaign ? 'has been updated' : 'has been added'} successfully`);
        if (!isSaveAndCreate) {
          onClose();
        }
      } catch (e: any) {
        AntMessage.error(`${data.campaignName} ${data.idCampaign ? `cannot be updated. ${e.response?.data?.message}` : `cannot be added. ${e.response?.data?.message}`}`);
      } finally {
        setSubmitLoading(false);
      }
    })();

  const onVersionConfirm = async () => {
    setSubmitLoading(true);
    const model = withIncrementedVersion(getValues(), latestVersionOfFunnelGroup?.meta?.version);
    try {
      await updateFunnelGroup(model);
      onClose();
    } finally {
      setSubmitLoading(false);
    }
  };

  useImperativeHandle(ref, () => ({ onSave }));

  return (
    <>
      <VisibilityWrapper visible={currentTabId === 'general'}>
        <SectionBox title="General Settings">
          <FFCol gap="12px">
            <Controller
              control={control}
              name="campaignName"
              rules={{ required: 'Required' }}
              render={(opt) => (
                <FFField label="Funnel Group Name" block>
                  <FFInput
                    error={opt.fieldState.error?.message}
                    value={opt.field.value}
                    onChange={opt.field.onChange}
                    placeholder="Enter funnel group name here"
                  />
                </FFField>
              )}
            />
            <FFButton type="tertiary" onClick={onSaveAndCreate}>
              Save & Create Funnel Group
            </FFButton>
          </FFCol>
        </SectionBox>
      </VisibilityWrapper>
      <VisibilityWrapper visible={currentTabId === 'optionalSettings'}>
        <SectionBox title="Optional Settings">
          <FFCol gap="12px">
            <Controller
              control={control}
              name="defaultCost"
              render={(opt) => (
                <FFField label="Default Cost per Entrance" block tooltipContent={<DefaultCostTooltip />}>
                  <FFInput
                    value={opt.field.value}
                    onChange={(e) => opt.field.onChange(withoutWhiteSpace(removeNonDigits(e.target.value)))}
                    placeholder="E.g. 0.01"
                  />
                </FFField>
              )}
            />
          </FFCol>
        </SectionBox>
      </VisibilityWrapper>
    </>
  );
});

const FunnelGroupForm = () => {
  const isOpen = useFormStore((state) => state.funnelGroup.isOpen);
  const openedForms = useFormStore((state) => state.openedForms);
  const [currentTabId, setCurrentTabId] = useState<TabId>('general');
  const isDuplication = useFormStore((state) => state.funnelGroup.isDuplication);
  const funnelGroupId = useFormStore((state) => state.funnelGroup.data?.id);
  const [submitLoading, setSubmitLoading] = useState(false);
  const closeForm = useFormStore((state) => state.closeFunnelGroupForm);
  const { data: funnelGroup, isLoading } = useFunnelGroupQuery(funnelGroupId!);

  const formRef = useRef<RefType>({
    onSave: () => {},
  });

  const sidebarTitle = useMemo(() => {
    if (isDuplication) {
      return 'Duplicate Funnel Group';
    } else if (funnelGroupId) {
      return 'Edit Funnel Group';
    } else {
      return 'Create Funnel Group';
    }
  }, [isDuplication, funnelGroupId]);

  const onClose = () => {
    setCurrentTabId('general');
    closeForm();
  };

  return (
    <FFSidePanel
      isOpen={isOpen}
      minWidth={600}
      maxWidth={1100}
      tabs={tabs}
      onClose={onClose}
      sidebarName="FunnelGroupForm"
      currentTabId={currentTabId}
      offsetLevel={getSidebarOffsetLevel(openedForms, 'funnelGroup')}
      zIndex={getSidebarZIndex(openedForms, 'funnelGroup')}
      title={sidebarTitle}
      setCurrentTabId={(tabId) => setCurrentTabId(tabId as TabId)}
      actions={
        <FFRow gap="8px">
          <FFButton onClick={() => formRef.current.onSave()} loading={submitLoading} disabled={submitLoading}>
            Save
          </FFButton>
          <FFButton type="tertiary" disabled={submitLoading} onClick={onClose}>
            Cancel
          </FFButton>
        </FFRow>
      }
    >
      {isLoading ? (
        <Skeleton width="100%" height="400px" />
      ) : (
        <Form
          defaultFormValues={funnelGroup!}
          currentTabId={currentTabId}
          closeForm={closeForm}
          setSubmitLoading={setSubmitLoading}
          submitLoading={submitLoading}
          ref={formRef}
        />
      )}
    </FFSidePanel>
  );
};

export default FunnelGroupForm;
