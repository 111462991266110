import useHttp from '@/hooks/http';
import { TrafficSource } from '@/models/trafficSource';
import { useMutation } from '@tanstack/react-query';

export function useTrafficSourceCreateMutation() {
  const { post } = useHttp();
  return useMutation<TrafficSource, Error, TrafficSource>({
    mutationKey: ['trafficSource', 'create'],
    mutationFn: async (trafficSource: TrafficSource) => {
      const { data } = await post<TrafficSource, TrafficSource>('v1/trafficsource/save/', trafficSource);
      return data;
    },
  });
}

export function useTrafficSourceUpdateMutation() {
  const { put } = useHttp();
  return useMutation<TrafficSource, Error, TrafficSource>({
    mutationKey: ['trafficSource', 'update'],
    mutationFn: async (trafficSource: TrafficSource) => {
      const { data } = await put<TrafficSource, TrafficSource>('v1/trafficsource/save/', trafficSource);
      return data;
    },
  });
}

export function useTrafficSourceDuplicateMutation() {
  const { post } = useHttp();
  return useMutation<TrafficSource, Error, TrafficSource>({
    mutationKey: ['trafficSource', 'duplicate'],
    mutationFn: async (trafficSource: TrafficSource) => {
      const { data } = await post<TrafficSource, TrafficSource>(
        `v1/trafficsource/duplicate?idTrafficSource${trafficSource.idTrafficSource}`,
        trafficSource,
      );
      return data;
    },
  });
}

export function useTrafficSourceDeleteMutation() {
  const { del } = useHttp();

  return useMutation<null, Error, string>({
    mutationKey: ['trafficSource', 'delete'],
    mutationFn: async (id: string) => {
      await del('v1/trafficsource/delete/', {
        data: { entries: [id] },
      });
      return null;
    },
  });
}

export function useTrafficSourceArchiveMutation() {
  const { put } = useHttp();

  return useMutation<null, Error, TrafficSource>({
    mutationKey: ['trafficSource', 'archive'],
    mutationFn: async (trafficSource: TrafficSource) => {
      await put<TrafficSource, null>('v1/trafficsource/save/', trafficSource);
      return null;
    },
  });
}

export function useTrafficSourceUnArchiveMutation() {
  const { put } = useHttp();

  return useMutation<null, Error, TrafficSource>({
    mutationKey: ['trafficSource', 'unarchive'],
    mutationFn: async (trafficSource: TrafficSource) => {
      await put<TrafficSource, null>('v1/trafficsource/save/', trafficSource);
      return null;
    },
  });
}
