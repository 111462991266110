import { useFunnelCreateMutation, useFunnelDuplicateMutation, useFunnelUpdateMutation } from '@/api/mutations/funnel';
import { useFunnelGroupListQuery, useFunnelQuery } from '@/api/queries/funnel';
import { useTrafficSourceCategoriesInfoQuery } from '@/api/queries/general';
import { useTrafficSourceQuery } from '@/api/queries/trafficSource';
import CodeSnippet from '@/components/CodeSnippet';
import SectionBox from '@/components/SectionBox';
import StepCheck from '@/components/StepCheck';
import { IPs } from '@/constants';
import { getTrafficNode } from '@/constants/builder';
import useHttp from '@/hooks/http';
import useMitt from '@/hooks/mitt';
import Skeleton from 'react-loading-skeleton';
import { Funnel } from '@/models/funnel';
import useFormStore from '@/stores/forms';
import useSystemSettingsStore from '@/stores/systemSettings';
import { CommonFormProps } from '@/types/forms/form';
import { FunnelFormRefType, FunnelFormTabId } from '@/types/forms/funnel';
import {
  FFAddGroup,
  FFButton,
  FFIconButton,
  FFCol,
  FFField,
  FFInput,
  FFNewIcon,
  FFRow,
  FFSelect,
  FFSidePanel,
  FFText,
  VisibilityWrapper,
} from '@/uikit';
import { FFSelectOption } from '@/uikit/types/select';
import { SidebarTab } from '@/uikit/types/sidebar';
import { generateEntityId } from '@/utils/id';
import { serverVersionIsAheadOfLocal, withIncrementedVersion } from '@/utils/model';
import { getSidebarOffsetLevel, getSidebarZIndex } from '@/utils/sidebar';
import { removeNonDigits, trimStringPropertiesInObject, withoutWhiteSpace } from '@/utils/string';
import { Divider, message as AntMessage } from 'antd';
import { Ref, forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

const DefaultCostTooltip = () => (
  <>
    <p>
      If you add a value here, all links generated for this funnel will by default inherit this cost value in the link generation form, but
      any value set at the traffic source-level will take priority.
    </p>
    <p>The cost parameter is appended to URLs as …&c=X and this can be overridden in the link generation form.</p>
    <p>If present, this cost is applied to a visitor loading your link, so can be used to give a default cost per click/visit.</p>
    <p>When generating links for your funnel, we check for default costs in the following order:</p>
    <ul>
      <li>Funnel Group (can only add a cost value)</li>
      <li>Funnel (can only add a cost value)</li>
      <li>Traffic Source (can use values or tokens/text)</li>
      <li>Cost field in the link generation form</li>
    </ul>
  </>
);

const StepTwoTooltip = () => (
  <p>
    When sending traffic to a page you can pass traffic source IDs in the URL. If you do not, the visitor will appear as “Organic traffic”
    in your reporting. We suggest you pass this data in the URL, though it is not required.
  </p>
);

const StepFourTooltip = () => (
  <p>
    We recommend using the same domain for all parts of a funnel where possible, i.e. for redirect links, action links, javascript and
    conversion tracking. Mixing domains can cause tracking losses if cookies are relied upon.
  </p>
);

const DEFAULT_FUNNEL: Funnel = {
  idCampaign: '',
  idFunnel: '',
  funnelName: '',
  ipAnonymizer: 'disabled',
};

const tabs: SidebarTab<FunnelFormTabId>[] = [
  {
    title: 'General Settings',
    tabId: 'general',
    icon: <FFNewIcon name="sidebar-tabs/general-settings" size="md" type="sidebartab" display="inline-block" />,
  },
  {
    title: 'Advanced Funnel Settings',
    tabId: 'advanced-settings',
    icon: <FFNewIcon name="sidebar-tabs/advanced-funnel-settings" size="md" type="sidebartab" display="inline-block" />,
  },
  {
    title: 'Redirect Links',
    tabId: 'redirect-links',
    icon: <FFNewIcon name="sidebar-tabs/redirect-links" size="md" type="sidebartab" display="inline-block" />,
  },
  {
    title: 'Javascript Tracking',
    tabId: 'js-tracking',
    icon: <FFNewIcon name="sidebar-tabs/javascript-tracking" size="md" type="sidebartab" display="inline-block" />,
  },
  {
    title: 'Help',
    tabId: 'help',
    icon: <FFNewIcon name="sidebar-tabs/help" size="md" type="sidebartab" display="inline-block" />,
  },
];

interface IncomingCostOverride {
  key: string;
  value: string;
}

interface FormProps extends CommonFormProps<Funnel, FunnelFormTabId> {
  isInBuilder?: boolean;
}

export const FunnelFormSettings = forwardRef(
  (
    {
      currentTabId,
      isDuplication,
      defaultValues,
      saveMode = 'online',
      isInBuilder = false,
      closeForm,
      setSubmitLoading,
      onUpdate = () => {},
      onCreate = () => {},
      onDuplicate = () => {},
    }: FormProps,
    ref: Ref<FunnelFormRefType>,
  ) => {
    const http = useHttp();
    const emitter = useMitt();
    const navigate = useNavigate();
    const systemDefaultIpAnonymizer = useSystemSettingsStore((state) => state.userSettings.ipAnonymizer);
    const defaultCustomDomain = useSystemSettingsStore((state) => state.userSettings.defaultCustomDomain);
    const domains = useSystemSettingsStore((state) => state.domains);
    const openFunnelGroupForm = useFormStore((state) => state.openFunnelGroupForm);
    const openVersioningForm = useFormStore((state) => state.openVersioningForm);
    const setVersioningType = useFormStore((state) => state.setVersioningType);

    const [idTrafficSource, setIdTrafficSource] = useState('');
    const [latestVersionOfFunnel, setLatestVersionOfFunnel] = useState<Funnel>();
    const [incomingCostOverrides, setIncomingCostOverrides] = useState<IncomingCostOverride[]>([{ key: '', value: '' }]);
    const [cost, setCost] = useState('');
    const [domain, setDomain] = useState('');
    const [idNode, setIdNode] = useState<string | undefined>();

    const { data: funnelGroupList = [], refetch: refetchFunnelGroupsList } = useFunnelGroupListQuery('active', true);
    const { data: trafficSourceCategoriesInfoList = [] } = useTrafficSourceCategoriesInfoQuery();
    const { data: trafficSource, isFetching: trafficSourceLoading } = useTrafficSourceQuery(idTrafficSource);
    const { mutateAsync: createFunnel } = useFunnelCreateMutation();
    const { mutateAsync: updateFunnel } = useFunnelUpdateMutation();
    const { mutateAsync: duplicateFunnel } = useFunnelDuplicateMutation();

    const {
      handleSubmit,
      control,
      formState: { errors },
      getValues,
      watch,
    } = useForm<Funnel>({
      defaultValues: {
        ...defaultValues,
        ipAnonymizer: systemDefaultIpAnonymizer,
      },
    });

    const funnel = useMemo(() => watch(), [watch]);
    const funnelNodes = useMemo(() => funnel.nodes || [], [funnel]);

    useEffect(() => {
      emitter.on('onFunnelGroupSave', () => {
        refetchFunnelGroupsList();
      });
      emitter.on('onVersioningConfirm', () => {
        onSave(false, true);
      });
    }, []);

    useEffect(() => {
      if (Object.keys(getValues('incomingCostOverrides') || {}).length > 0) {
        setIncomingCostOverrides(Object.entries(getValues('incomingCostOverrides') || {}).map(([key, value]) => ({ key, value })));
      }
    }, [getValues]);

    const trafficSourceOptions = useMemo(() => {
      const trafficSourceOptions: FFSelectOption[] = [];
      for (const trafficSourceGroupInfo of trafficSourceCategoriesInfoList) {
        (trafficSourceGroupInfo.trafficSources || []).forEach((trafficSource) => {
          trafficSourceOptions.push({
            value: trafficSource.idTrafficSource,
            label: trafficSource.trafficSourceName,
            category: trafficSourceGroupInfo.categoryName,
          });
        });
      }
      return trafficSourceOptions;
    }, [trafficSourceCategoriesInfoList]);

    const nodeOptions = useMemo(
      () =>
        (funnelNodes || [])
          .filter((node) => !node.status || node.status === 'active')
          .map((node) => {
            let category = '';
            switch (node.nodeType) {
              case 'rotator':
                category = 'Starting';
                break;
              case 'condition':
                category = 'Condition';
                break;
              case 'landerGroup':
                category = 'Lander';
                break;
              case 'offerGroup':
                category = 'Offer';
                break;
              default:
                category = 'Other';
            }
            return { value: node.idNode, label: node.nodeName, category: `${category} Nodes` };
          }),
      [funnelNodes],
    );

    const getCode = useMemo(() => {
      if (trafficSourceLoading) return 'Fetching TrafficSource Data...';
      if (!trafficSource) return 'Please select a traffic source first';

      let url = new URL(`https://${domain}/fts/${funnel?.idFunnel}-${idTrafficSource}/${idNode}`);
      Object.values(trafficSource?.trackingFieldSlots || {}).forEach((value) => {
        url.searchParams.append(value.name, value.value);
      });
      url.searchParams.append('cost', cost || trafficSource?.defaultCost || '');
      return decodeURI(url.toString());
    }, [funnel, idNode, idTrafficSource, domain, cost, trafficSource, trafficSourceLoading]);

    const onAddIncomingCostOverride = () => {
      setIncomingCostOverrides([...incomingCostOverrides, { key: '', value: '' }]);
    };

    const onIncomingCostOverrideChange = (key: 'key' | 'value', value: string, idx: number) => {
      setIncomingCostOverrides(
        incomingCostOverrides.map((incomingCostOverride, _idx) =>
          _idx === idx ? { ...incomingCostOverride, [key]: value } : incomingCostOverride,
        ),
      );
    };

    const onClose = () => {
      emitter.all.clear();
      closeForm();
    };

    const onSave = (isSaveAndOpenEditor = false, isConfirmingVersioning = false) =>
      handleSubmit(async (data) => {
        setSubmitLoading(true);
        const duplicateFn = saveMode === 'offline' ? onDuplicate : duplicateFunnel;
        const createFn = saveMode === 'offline' ? onCreate : createFunnel;
        const updateFn = saveMode === 'offline' ? onUpdate : updateFunnel;
        const onSaveSuccessFn = (funnel: Funnel) => {
          emitter.emit('onFunnelSave', funnel);
          onClose();
          if (isSaveAndOpenEditor) {
            navigate(`funnels/editor/${funnel.idFunnel}`);
          }
        };

        const newID = generateEntityId();
        const model = trimStringPropertiesInObject<Funnel>(data, ['funnelName']);
        const updateModel = withIncrementedVersion(model);
        const duplicateModel: Funnel = { ...model, meta: { version: 1 } };
        const createModel: Funnel = {
          ...model,
          idFunnel: newID,
          incomingCostOverrides: incomingCostOverrides.reduce((acc: { [key: string]: string }, crr) => {
            if (crr.key && crr.value) {
              acc[crr.key] = crr.value;
            }
            return acc;
          }, {}),
          nodes: [getTrafficNode()],
          connections: [],
          meta: { ...model.meta, version: 1 },
        };
        const funnelGroup = funnelGroupList.find((funnelGroup) => funnelGroup.idCampaign === data.idCampaign)!;

        if (isConfirmingVersioning) {
          try {
            let versionedUpdate = withIncrementedVersion(updateModel, latestVersionOfFunnel?.meta?.version);
            await updateFn(versionedUpdate);
            onSaveSuccessFn(versionedUpdate);
          } catch (e) {
            AntMessage.error('Failed to save funnel');
          } finally {
            setSubmitLoading(false);
          }
          return;
        }
        try {
          if (isDuplication) {
            await duplicateFn(duplicateModel);
            emitter.emit('onFunnelCreate', { data: duplicateModel, funnelGroup });
          } else if (model.idFunnel) {
            const funnel = await http.get<Funnel>('v1/campaign/funnel/find/byId', {
              params: { idFunnel: model.idFunnel },
            });
            setLatestVersionOfFunnel(funnel.data);
            if (model.meta && serverVersionIsAheadOfLocal(model.meta.version, funnel.data.meta?.version)) {
              setVersioningType('funnel');
              openVersioningForm();
              return;
            } else {
              await updateFn(updateModel);
              emitter.emit('onFunnelUpdate', updateModel);
            }
          } else {
            await createFn(createModel);
            emitter.emit('onFunnelCreate', { data: createModel, funnelGroup });
          }

          onSaveSuccessFn(createModel);
          AntMessage.success('Funnel has been saved');
        } catch (e: any) {
          AntMessage.error(`Failed to save funnel. ${e.response?.data?.message}`);
        } finally {
          setSubmitLoading(false);
        }
      })();

    useImperativeHandle(ref, () => ({ onSave }));

    return (
      <>
        <VisibilityWrapper visible={currentTabId === 'general'}>
          <SectionBox title="General Settings">
            <FFCol gap="18px" height="max-content" flex="1">
              <FFRow gap="8px">
                <Controller
                  name="idCampaign"
                  control={control}
                  rules={{ required: 'Funnel Group is required' }}
                  render={(opt) => (
                    <FFField label="Parent Funnel Group" block>
                      <FFSelect
                        options={funnelGroupList}
                        valueGetter={(opt) => opt.idCampaign}
                        labelGetter={(opt) => opt.campaignName}
                        value={opt.field.value}
                        showSearch
                        onChange={opt.field.onChange}
                        placeholder="Parent Funnel Group"
                      />
                      <FFIconButton buttonType="transparent" iconName="general/line/add-circle" onClick={openFunnelGroupForm} />
                      <FFNewIcon name="general/line/arrow-right-3" size="sm" />
                    </FFField>
                  )}
                />
                <Controller
                  name="funnelName"
                  control={control}
                  rules={{ required: 'Funnel Name is required' }}
                  render={(opt) => (
                    <FFField label="Funnel Name" block>
                      <FFInput
                        value={opt.field.value}
                        onChange={opt.field.onChange}
                        error={errors.funnelName?.message}
                        placeholder="Funnel Name"
                      />
                    </FFField>
                  )}
                />
              </FFRow>
              <FFRow maxWidth="230px">
                <Controller
                  name="defaultCost"
                  control={control}
                  render={(opt) => (
                    <FFField label="Default Cost per Entrance" block tooltipContent={<DefaultCostTooltip />}>
                      <FFInput
                        value={opt.field.value}
                        onChange={(e) => opt.field.onChange(withoutWhiteSpace(removeNonDigits(e.target.value)))}
                        error={errors.defaultCost?.message}
                        placeholder="E.g. 0.01"
                      />
                    </FFField>
                  )}
                />
              </FFRow>
              <p>Note: all settings are also available from within the funnel editor</p>
              <VisibilityWrapper visible={!isInBuilder}>
                <FFButton type="primary" onClick={() => onSave(true)}>
                  Save and Open Editor
                </FFButton>
              </VisibilityWrapper>
            </FFCol>
          </SectionBox>
        </VisibilityWrapper>
        <VisibilityWrapper visible={currentTabId === 'advanced-settings'}>
          <FFCol gap="18px">
            <SectionBox title="IP Anonymization Settings">
              <FFCol gap="18px">
                <p>
                  These settings will determine how FunnelFlux truncates IP data before it gets logged to your analytics database, for user
                  privacy and GDPR compliance. This will not impact geo-location data or tokens used in page URLs.
                </p>
                <Controller
                  name="ipAnonymizer"
                  control={control}
                  render={(opt) => (
                    <FFSelect
                      options={IPs}
                      valueGetter={(opt) => opt.value}
                      labelGetter={(opt) => (opt.value === systemDefaultIpAnonymizer ? `System Default (${opt.label})` : opt.label)}
                      value={opt.field.value}
                      onChange={opt.field.onChange}
                    />
                  )}
                />
              </FFCol>
            </SectionBox>
            <SectionBox title="Incoming Traffic Cost Override">
              <FFCol gap="18px">
                <FFText.P type="body-2">
                  Incoming cost overrides will change the cost of all matching visitors who enter a funnel. This will not affect
                  existing/past visitors. These overrides are higher priority than the "c" parameter in URLs/JS and can be used to update
                  average incoming cost in real-time.
                </FFText.P>
                <FFAddGroup
                  rows={incomingCostOverrides}
                  showAddRow={true}
                  onAddRow={onAddIncomingCostOverride}
                  renderRow={(row, rowIdx) => {
                    return (
                      <>
                        <FFAddGroup.Col>
                          <FFSelect
                            options={trafficSourceOptions}
                            valueGetter={(opt) => opt.value}
                            labelGetter={(opt) => opt.label}
                            groupOptions={true}
                            sortGroup
                            selectAll
                            showSearch
                            autoFocus
                            value={row.key}
                            placeholder="Select a traffic source"
                            onSelect={(value) => onIncomingCostOverrideChange('key', value, rowIdx)}
                          />
                        </FFAddGroup.Col>
                        <FFAddGroup.Col>
                          <FFInput
                            name="value"
                            value={row.value}
                            placeholder="E.g. 0.01"
                            onChange={(e) =>
                              onIncomingCostOverrideChange('value', withoutWhiteSpace(removeNonDigits(e.target.value)), rowIdx)
                            }
                          />
                        </FFAddGroup.Col>
                      </>
                    );
                  }}
                />
              </FFCol>
            </SectionBox>
          </FFCol>
        </VisibilityWrapper>
        <VisibilityWrapper visible={currentTabId === 'redirect-links'}>
          <SectionBox title="URL Generator">
            <FFCol gap="18px">
              <FFRow gap="12px">
                <FFField label="Select a node" block>
                  <StepCheck step={1} />
                  <FFSelect
                    value={idNode}
                    options={nodeOptions}
                    valueGetter={(opt) => opt.value}
                    labelGetter={(opt) => opt.label}
                    showSearch
                    filterOption
                    sortGroup
                    groupOptions
                    placeholder="Select a node"
                    onSelect={setIdNode}
                  />
                </FFField>
                <FFField label="Select a Traffic Source" tooltipContent={<StepTwoTooltip />} block>
                  <StepCheck step={2} />
                  <FFSelect
                    value={idTrafficSource}
                    options={trafficSourceOptions}
                    valueGetter={(opt) => opt.value}
                    labelGetter={(opt) => opt.label}
                    showSearch
                    filterOption
                    sortGroup
                    groupOptions
                    placeholder="Select Traffic Source"
                    onSelect={setIdTrafficSource}
                  />
                </FFField>
              </FFRow>
              <FFRow gap="12px">
                <FFField label="Entrance cost (optional)" block>
                  <StepCheck step={3} />
                  <FFInput
                    value={cost}
                    placeholder="Entrance cost (optional)"
                    onChange={(e) => setCost(withoutWhiteSpace(removeNonDigits(e.target.value)))}
                  />
                </FFField>
                <FFField label="Tracking Domain" tooltipContent={<StepFourTooltip />} block>
                  <StepCheck step={4} />
                  <FFSelect
                    value={domain}
                    options={domains}
                    valueGetter={(opt) => opt.domain}
                    labelGetter={(opt) => opt.domain}
                    placeholder="Tracking Domain"
                    onSelect={setDomain}
                  />
                </FFField>
              </FFRow>
            </FFCol>
            <Divider />
            <FFCol gap="12px">
              <FFText.Span type="title-3">Your Tracking URL</FFText.Span>
              <FFText.P type="body-2">
                Use this URL at your traffic source. It will redirect users to your funnel and appends data as defined in your traffic
                source settings. You do not need to save these generated links or config.
              </FFText.P>
              <CodeSnippet code={getCode} />
            </FFCol>
          </SectionBox>
        </VisibilityWrapper>
        <VisibilityWrapper visible={currentTabId === 'js-tracking'}>
          <SectionBox title="Universal JavaScript Tag">
            <FFCol gap="12px">
              <p>For general tracking, make sure to include our universal JS tag. This already includes a view event.</p>
              <CodeSnippet codeType="genericViewFull" className="width-full margin-bottom-15" domain={defaultCustomDomain} />
            </FFCol>
          </SectionBox>
        </VisibilityWrapper>
        <VisibilityWrapper visible={currentTabId === 'help'}>
          <SectionBox title="Help">
            <FFCol gap="12px">
              <p>Funnels are the key components of FunnelFlux that describe the journey you want users to experience.</p>

              <p>Funnels must belong to a group, which is essentially a category or container.</p>

              <p>Many of the settings here are basic or allow overriding of default system behaviour inside this funnel.</p>
              <p>
                Need more help? Read our documentation{' '}
                <a href="https://help.funnelflux.pro/article/100" target="_blank" rel="noopener noreferrer">
                  here
                </a>
              </p>
            </FFCol>
          </SectionBox>
        </VisibilityWrapper>
      </>
    );
  },
);

const FunnelForm = () => {
  const isOpen = useFormStore((state) => state.funnel.isOpen);
  const openedForms = useFormStore((state) => state.openedForms);
  const isDuplication = useFormStore((state) => state.funnel.isDuplication);
  const [currentTabId, setCurrentTabId] = useState<FunnelFormTabId>('general');
  const idFunnel = useFormStore((state) => state.funnel.data?.id);
  const closeForm = useFormStore((state) => state.closeFunnelForm);
  const formRef = useRef<FunnelFormRefType>({
    onSave: () => {},
  });
  const { data: funnel = DEFAULT_FUNNEL, isLoading } = useFunnelQuery(idFunnel!);
  const [submitLoading, setSubmitLoading] = useState(false);

  const sidebarTitle = useMemo(() => {
    if (isDuplication) {
      return 'Duplicate Funnel';
    } else if (idFunnel) {
      return 'Edit Funnel';
    } else {
      return 'Create Funnel';
    }
  }, [isDuplication, idFunnel]);

  const onClose = () => {
    setCurrentTabId('general');
    setSubmitLoading(false);
    closeForm();
  };

  return (
    <>
      <FFSidePanel
        isOpen={isOpen}
        minWidth={600}
        maxWidth={1100}
        tabs={tabs}
        onClose={onClose}
        sidebarName="FunnelForm"
        currentTabId={currentTabId}
        offsetLevel={getSidebarOffsetLevel(openedForms, 'funnel')}
        zIndex={getSidebarZIndex(openedForms, 'funnel')}
        title={sidebarTitle}
        setCurrentTabId={(tabId) => setCurrentTabId(tabId)}
        actions={
          <FFRow gap="8px">
            <FFButton onClick={() => formRef.current.onSave()} loading={submitLoading} disabled={submitLoading}>
              Save
            </FFButton>
            <FFButton type="tertiary" disabled={submitLoading} onClick={onClose}>
              Cancel
            </FFButton>
          </FFRow>
        }
      >
        {isLoading ? (
          <Skeleton width="100%" height="400px" />
        ) : (
          <FunnelFormSettings
            defaultValues={funnel}
            currentTabId={currentTabId}
            isDuplication={isDuplication}
            ref={formRef}
            closeForm={onClose}
            setSubmitLoading={setSubmitLoading}
            submitLoading={submitLoading}
          />
        )}
      </FFSidePanel>
    </>
  );
};

export default FunnelForm;
