import { FFCol, FFField, FFInput } from '@/uikit';
import { Control, Controller } from 'react-hook-form';
import { TrafficSource } from '@/models/models';

const GoogleAdsCustomScenario = ({ triggeringEvent, control }: { triggeringEvent: string, control: Control<TrafficSource> }) => {
  return (
    <FFCol gap="12px">
      <Controller
        name={`conversionTrackingSettings.${triggeringEvent}.customEventData.accountId`}
        control={control}
        rules={{ required: 'Required' }}
        render={opt => (
          <FFField label="Account ID:" block direction="row">
            <FFInput
              value={opt.field.value}
              onChange={opt.field.onChange}
              error={opt.fieldState.error?.message}
              placeholder="Ad Account ID (no hyphens)"
              style={{ width: 280 }}
            />
          </FFField>
        )}
      />
      <Controller
        name={`conversionTrackingSettings.${triggeringEvent}.customEventData.refreshToken`}
        control={control}
        rules={{ required: 'Required' }}
        render={opt => (
          <FFField label="Refresh token:" block direction="row">
            <FFInput
              value={opt.field.value}
              onChange={opt.field.onChange}
              error={opt.fieldState.error?.message}
              placeholder="Your generated refresh token"
              style={{ width: 280 }}
            />
          </FFField>
        )}
      />
      <Controller
        name={`conversionTrackingSettings.${triggeringEvent}.customEventData.conversionActionId`}
        control={control}
        rules={{ required: 'Required' }}
        render={opt => (
          <FFField label="Conversion Action ID:" block direction="row">
            <FFInput
              value={opt.field.value}
              onChange={opt.field.onChange}
              error={opt.fieldState.error?.message}
              placeholder="Your conversion action ID"
              style={{ width: 280 }}
            />
          </FFField>
        )}
      />
      <Controller
        name={`conversionTrackingSettings.${triggeringEvent}.customEventData.managerAccountId`}
        control={control}
        render={opt => (
          <FFField label="Manager Account ID:" block direction="row">
            <FFInput
              value={opt.field.value}
              onChange={opt.field.onChange}
              error={opt.fieldState.error?.message}
              placeholder="Optional manager account ID"
              style={{ width: 280 }}
            />
          </FFField>
        )}
      />
    </FFCol>
  );
};

export default GoogleAdsCustomScenario;
