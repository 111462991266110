import { FFCol } from '@/uikit';
import { CSSProperties, PropsWithChildren } from 'react';

const PageContainer = ({ children, padding = '12px 12px 8px 12px', maxWidth, className }: PropsWithChildren<Pick<CSSProperties, 'maxWidth' | 'padding'> & { className?: string }>) => {
  return (
    <FFCol padding={padding} maxWidth={maxWidth} gap="12px" height="100%" className={className}>
      {children}
    </FFCol>
  );
};

export default PageContainer;
