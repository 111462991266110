import { create } from 'zustand';
import { devtools, persist, subscribeWithSelector } from 'zustand/middleware';
import { OffersStore, OffersStoreProps } from '../types/stores/offers';
import { DrilldownReport } from '@/models/drilldownReport';
import { DEFAULT_TIME_RANGE, timeRangeItems } from '@/constants/time';
import { DrilldownReportParams } from '@/models/drilldownReportParams';
import { TimeRangeKeys } from '@/types/time';
import { getDatePickerValues } from '@/utils/time-helper';
import { Attribute } from '@/models/attribute';

const dateTime = (timeRange: TimeRangeKeys) => getDatePickerValues(timeRangeItems[timeRange]);
const drilldownReportParamsDefault = (customRange = DEFAULT_TIME_RANGE): DrilldownReportParams => ({
  timeStart: dateTime(customRange).timeStart,
  timeEnd: dateTime(customRange).timeEnd,
  attributes: [],
  includeAssetsWithoutTraffic: true,
  returnUniqueVisitors: false,
});

const reportingDefault: DrilldownReport = {
  columns: {
    attributes: [],
  },
  rows: [],
  rootRows: [],
  entities: {
    categories: [],
    offerSources: [],
  },
};

const DEFAULT_PROPS: OffersStoreProps = {
  offers: [],
  offerSources: [],
  reporting: reportingDefault,
  categories: [],
  reportingParams: {
    ...drilldownReportParamsDefault(),
    attributes: [{ attribute: Attribute.ElementOffer }, { attribute: Attribute.ThirdPartiesOfferSource }],
  },
  tableCols: [],
  showStatus: 'all',
  showOfferSources: false,
  tableSettings: {
    colorizeTableRows: false,
    isFilterMode: false,
    currentPage: 0,
    pageSize: 10,
    dateRangeName: 'Today',
    expandedRows: [],
    tableColSizes: {},
  },
};

const useOffersStore = create<OffersStore>()(
  subscribeWithSelector(
    devtools(
      persist(
        (set) => ({
          ...DEFAULT_PROPS,
          setOffers: (offers) => set({ offers }),
          setOfferSources: (offerSources) => set({ offerSources }),
          setReporting: (reporting) => set({ reporting }),
          setCategories: (categories) => set({ categories }),
          setReportingParams: (reportingParams) => set({ reportingParams }),
          setTableCols: (cols) => set({ tableCols: cols }),
          setShowStatus: (status) => set({ showStatus: status }),
          setShowOfferSources: (showOfferSources) => set({ showOfferSources }),
          setTableSettings: (key, value) =>
            set((state) => ({
              tableSettings: { ...state.tableSettings, [key]: value },
            })),
        }),
        {
          name: 'offers',
          partialize: (state) => {
            const { reporting, offers, categories, ...rest } = state;
            return rest;
          },
        },
      ),
    ),
  ),
);

export default useOffersStore;
