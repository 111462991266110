import { FFButton, FFCol, FFField, FFIconButton, FFInput, FFNewIcon, FFRow, FFSelect, VisibilityWrapper } from '@/uikit';
import className from '@/utils/className';
import { DownCircleOutlined, UpCircleOutlined } from '@ant-design/icons';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Radio, Tag } from 'antd';
import { attributeFields } from '@/constants/conditions';
import { Control, Controller, UseFormWatch } from 'react-hook-form';
import { FunnelCondition } from '@/models/funnelCondition';
import RouteGroupRule from '../RouteGroupRule';
import { DomainEntry, FunnelConditionRoute, FunnelConditionRule } from '@/models/models';
import clsx from 'clsx';
import { FFSelectOption } from '@/uikit/types/select';
import { getRouteName, getRouteNameByParam, isRouteNameAutoGenerated } from '../../utils';
import './style.scss';

interface Props {
  idRoute: number;
  onSwapUp: (id: number) => void;
  onSwapDown: (id: number) => void;
  onDelete: () => void;
  onAddGroup: () => void;
  onDeleteGroup: (idGroup: number) => void;
  onAddRule: (idGroup: number) => void;
  onDeleteRule: (idGroup: number, idRule: number) => void;
  watch: UseFormWatch<FunnelCondition>;
  trafficSources: FFSelectOption[];
  domains: DomainEntry[];
  route: FunnelConditionRoute;
  control: Control<FunnelCondition, any>;
}

const getDisplayedRouteName = (
  currentRoute: FunnelConditionRoute,
  idRoute: number,
  trafficSources: FFSelectOption[],
  isAutoGeneratedRouteName: boolean,
) => {
  if (!currentRoute) {
    return `Route ${idRoute}`;
  }
  if (!isAutoGeneratedRouteName) {
    return currentRoute.routeName;
  }
  const firstGroup = currentRoute.groups?.[0];
  const rules = firstGroup?.rules || [];

  if (!rules.length || !rules[0].attribute) {
    return currentRoute.routeName || `Route ${idRoute}`;
  }
  if (currentRoute.groups.length > 1) {
    return <span>Multiple rule group</span>;
  }
  if (rules.length > 2) {
    return <span>Complex rule set</span>;
  }

  return rules.slice(0, 2).map((rule, index) => {
    const isTrackingField = rule.attribute === 'Tracking Field';
    const ruleContent = isTrackingField ? (
      <span>
        Tracking Field {rule.trackingFieldParams?.fieldName} {getRuleTestTag(rule.test)} {rule.trackingFieldParams?.fieldValues}
      </span>
    ) : (
      <span>
        {rule.attribute.split(':')?.[1] || rule.attribute} {getRuleTestTag(rule.test)} {getRouteNameByParam(rule, trafficSources)}
      </span>
    );

    const isNotLast = index < rules.length - 1 && index < 1;
    const operator = firstGroup.operator === 'or' ? ' | ' : ' & ';

    return (
      <span key={index}>
        {ruleContent}
        {isNotLast && operator}
      </span>
    );
  });
};

const { blockClassName, getClass } = className('c-conditionRoute');

const getRuleTestTag = (test: FunnelConditionRule.TestEnum) => {
  if (!test) return <></>;
  switch (test) {
    case 'is':
      return <Tag color="blue">=</Tag>;
    case 'is not':
      return <Tag color="volcano">!=</Tag>;
    case 'any in':
      return <Tag color="green">IN</Tag>;
    case 'not in':
      return <Tag color="gold">!IN</Tag>;
    case 'contains':
      return <Tag color="purple">CONTAINS</Tag>;
    case 'does not contain':
      return <Tag color="magenta">!CONTAIN</Tag>;
    default:
      return <Tag color="blue">{test}</Tag>;
  }
};

const ConditionRoute = ({
  idRoute,
  onSwapUp = () => {},
  onSwapDown = () => {},
  onDelete = () => {},
  onAddGroup = () => {},
  onAddRule = () => {},
  onDeleteGroup = () => {},
  onDeleteRule = () => {},
  watch,
  trafficSources = [],
  domains = [],
  control,
  route,
}: Props) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);
  const [computedRouteName, setComputedRouteName] = useState(route.routeName);
  const isAutoGeneratedRouteName = useMemo(() => {
    return isRouteNameAutoGenerated(route.routeName);
  }, [route.routeName]);

  const switchEditMode = () => setIsEditMode(!isEditMode);

  useEffect(() => {
    watch((value) => {
      setComputedRouteName(getRouteName(value.routes?.[idRoute] as FunnelConditionRoute, idRoute, trafficSources));
    });
  }, [watch, idRoute, trafficSources, route, isAutoGeneratedRouteName]);

  const switchExpanded = () => setIsExpanded(!isExpanded);

  const getTestOptions = useCallback((attribute: FunnelConditionRule.AttributeEnum) => {
    switch (attribute) {
      case FunnelConditionRule.AttributeEnum.LocationContinent:
      case FunnelConditionRule.AttributeEnum.LocationCountry:
      case FunnelConditionRule.AttributeEnum.LocationTimezone:
      case FunnelConditionRule.AttributeEnum.DeviceType:
      case FunnelConditionRule.AttributeEnum.DeviceOS:
      case FunnelConditionRule.AttributeEnum.DeviceBrowserLanguage:
      case FunnelConditionRule.AttributeEnum.ConnectionType:
      case FunnelConditionRule.AttributeEnum.TrafficSource:
        return ['is', 'is not', 'any in', 'not in'];
      case FunnelConditionRule.AttributeEnum.LocationCity:
      case FunnelConditionRule.AttributeEnum.LocationRegion:
      case FunnelConditionRule.AttributeEnum.DeviceBrowserVersion:
      case FunnelConditionRule.AttributeEnum.TimeDayOfWeek:
      case FunnelConditionRule.AttributeEnum.TimeDayOfMonth:
      case FunnelConditionRule.AttributeEnum.TimeMonthOfYear:
        return ['is', 'is not'];
      case FunnelConditionRule.AttributeEnum.DeviceBrand:
      case FunnelConditionRule.AttributeEnum.DeviceBrowser:
      case FunnelConditionRule.AttributeEnum.ConnectionISP:
      case FunnelConditionRule.AttributeEnum.ConnectionUserAgent:
      case FunnelConditionRule.AttributeEnum.ConnectionReferrer:
      case FunnelConditionRule.AttributeEnum.ConnectionCurrentURL:
      case FunnelConditionRule.AttributeEnum.TrackingField:
      case FunnelConditionRule.AttributeEnum.TrackingDomain:
        return ['is', 'is not', 'contains', 'does not contain'];
      case FunnelConditionRule.AttributeEnum.DeviceOSVersion:
        return ['is', 'is not', '<', '>'];
      case FunnelConditionRule.AttributeEnum.TimeDate:
      case FunnelConditionRule.AttributeEnum.TimeTimeOfDay:
        return ['<', '<=', 'is', '>', '>='];
      default:
        return ['is', 'is not'];
    }
  }, []);

  return (
    <div className={clsx(blockClassName, route.groups.length > 1 && `${blockClassName}--moreThanOneGroup`)}>
      <FFRow justifyContent="space-between" flex="1" minHeight="30px">
        <FFRow gap="12px" alignItems="center">
          <FFRow gap="4px">
            <UpCircleOutlined className={getClass('shiftIcon')} onClick={() => onSwapUp(idRoute)} />
            <DownCircleOutlined className={getClass('shiftIcon')} onClick={() => onSwapDown(idRoute)} />
          </FFRow>
          <span className={getClass('routeIndex')}>{idRoute}</span>
          {isEditMode ? (
            <Controller
              name={`routes.${idRoute}.routeName`}
              control={control}
              render={(opt) => (
                <FFInput
                  defaultValue={isAutoGeneratedRouteName ? computedRouteName : opt.field.value}
                  size="small"
                  onChange={opt.field.onChange}
                />
              )}
            />
          ) : (
            <span className={getClass('routeName')}>{getDisplayedRouteName(route, idRoute, trafficSources, isAutoGeneratedRouteName)}</span>
          )}
          {!isEditMode && <FFIconButton iconName="general/line/pencil" iconSize="sm" buttonType="transparent" onClick={switchEditMode} />}
          {isEditMode && <FFIconButton iconName="general/line/check" iconSize="sm" buttonType="transparent" onClick={switchEditMode} />}
        </FFRow>
        <FFRow alignItems="center" gap="4px">
          <FFIconButton iconName="general/line/recycle-bin" iconSize="sm" buttonType="transparent" iconType="danger" onClick={onDelete} />
          {isExpanded ? (
            <FFNewIcon name="navigation/arrow-up" size="sm" onClick={switchExpanded} />
          ) : (
            <FFNewIcon name="navigation/arrow-down" size="sm" onClick={switchExpanded} />
          )}
        </FFRow>
      </FFRow>

      <VisibilityWrapper visible={isExpanded} beRenderedAlways>
        <FFCol className={getClass('groupsWrapper')}>
          <FFRow justifyContent="space-between" alignItems="center" marginBottom="18px">
            <div>
              <VisibilityWrapper visible={route.groups.length > 1}>
                <Controller
                  name={`routes.${idRoute}.operator`}
                  control={control}
                  render={(opt) => (
                    <Radio.Group
                      onChange={opt.field.onChange}
                      value={opt.field.value}
                      buttonStyle="solid"
                      className={getClass('routeOperator')}
                    >
                      <Radio.Button value="or">OR</Radio.Button>
                      <Radio.Button value="and">AND</Radio.Button>
                    </Radio.Group>
                  )}
                />
              </VisibilityWrapper>
            </div>
            <FFButton iconName="general/line/add-circle" className={getClass('addGroupButton')} onClick={onAddGroup}>
              Group
            </FFButton>
          </FFRow>
          {(route?.groups || []).map((group, idGroup) => (
            <FFCol key={idGroup} className={getClass('group', group.rules.length > 1 && 'moreThanOneRule')}>
              <div className={getClass('groupInnerContent')}>
                <FFRow justifyContent="space-between" alignItems="center" marginBottom="12px">
                  <div>
                    <VisibilityWrapper visible={group.rules.length > 1}>
                      <Controller
                        name={`routes.${idRoute}.groups.${idGroup}.operator`}
                        control={control}
                        render={(opt) => (
                          <Radio.Group
                            onChange={opt.field.onChange}
                            value={opt.field.value}
                            buttonStyle="solid"
                            className={getClass('groupOperator')}
                          >
                            <Radio.Button value="or">OR</Radio.Button>
                            <Radio.Button value="and">AND</Radio.Button>
                          </Radio.Group>
                        )}
                      />
                    </VisibilityWrapper>
                  </div>
                  <FFRow alignItems="center" gap="4px">
                    <FFButton
                      iconName="general/line/add-circle"
                      size="small"
                      className={getClass('addGroupButton')}
                      onClick={() => onAddRule(idGroup)}
                    >
                      Rule
                    </FFButton>
                    <FFIconButton
                      iconName="general/line/recycle-bin"
                      iconSize="sm"
                      buttonType="transparent"
                      iconType="danger"
                      onClick={() => onDeleteGroup(idGroup)}
                    />
                  </FFRow>
                </FFRow>
                {group.rules.map((rule, idRule) => (
                  <FFRow key={idRule} alignItems="center" gap="4px" marginBottom="12px">
                    <FFCol flex="1">
                      <FFRow flex="1" gap="8px" alignItems="center">
                        <Controller
                          name={`routes.${idRoute}.groups.${idGroup}.rules.${idRule}.attribute`}
                          control={control}
                          rules={{ required: 'Required' }}
                          render={(opt) => (
                            <FFField block>
                              <FFSelect
                                placeholder="Select field"
                                popupMatchSelectWidth={false}
                                options={attributeFields}
                                valueGetter={(opt) => opt.value}
                                labelGetter={(opt) => opt.label}
                                value={rule.attribute}
                                onChange={opt.field.onChange}
                                groupOptions={true}
                                sortGroup
                                showSearch
                                autoFocus
                                error={opt.fieldState.error?.message}
                                className={getClass('selectField')}
                              />
                            </FFField>
                          )}
                        />
                        <VisibilityWrapper visible={Boolean(rule.attribute)}>
                          <>
                            <RouteGroupRule
                              rule={rule}
                              idRoute={idRoute}
                              idGroup={idGroup}
                              idRule={idRule}
                              trafficSources={trafficSources}
                              domains={domains}
                              control={control}
                            >
                              <Controller
                                name={`routes.${idRoute}.groups.${idGroup}.rules.${idRule}.test`}
                                rules={{ required: 'Required' }}
                                control={control}
                                render={(opt) => (
                                  <FFSelect
                                    onChange={opt.field.onChange}
                                    value={opt.field.value}
                                    style={{ width: 130 }}
                                    placeholder="Operator"
                                    popupMatchSelectWidth={false}
                                    options={getTestOptions(rule.attribute)}
                                    valueGetter={(opt) => opt}
                                    labelGetter={(opt) => opt.toUpperCase()}
                                    sortGroup
                                    showSearch
                                    autoFocus
                                    error={opt.fieldState.error?.message}
                                    className={getClass('selectOperator')}
                                  />
                                )}
                              />
                            </RouteGroupRule>
                          </>
                        </VisibilityWrapper>
                      </FFRow>
                    </FFCol>
                    <FFCol flexGrow={0}>
                      <FFIconButton
                        buttonType="transparent"
                        iconName="general/line/recycle-bin"
                        iconSize="sm"
                        iconType="danger"
                        onClick={() => onDeleteRule(idGroup, idRule)}
                      />
                    </FFCol>
                  </FFRow>
                ))}
              </div>
            </FFCol>
          ))}
        </FFCol>
      </VisibilityWrapper>
    </div>
  );
};

export default ConditionRoute;
