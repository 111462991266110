export const getSidebarOffsetLevel = <T>(openedForms: T[], formName: T) => {
  const index = openedForms.indexOf(formName);
  if (index === -1) {
    return 0;
  }
  return openedForms.length - 1 - index;
}

export const getSidebarZIndex = <T>(openedForms: T[], formName: T) => {
  const index = openedForms.indexOf(formName);
  if (index === -1) {
    return 0;
  }
  return index + 1001;
}