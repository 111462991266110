import useHttp from '@/hooks/http';
import { FunnelCondition } from '@/models/funnelCondition';
import { useMutation } from '@tanstack/react-query';

export function useConditionCreateMutation() {
  const { post } = useHttp();
  return useMutation<FunnelCondition, Error, FunnelCondition>({
    mutationKey: ['condition', 'create'],
    mutationFn: async (condition: FunnelCondition) => {
      const { data } = await post<FunnelCondition, FunnelCondition>('v1/campaign/funnel/condition/save/', condition);
      return data;
    },
  });
}

export function useConditionUpdateMutation() {
  const { put } = useHttp();
  return useMutation<FunnelCondition, Error, FunnelCondition>({
    mutationKey: ['condition', 'update'],
    mutationFn: async (condition: FunnelCondition) => {
      const { data } = await put<FunnelCondition, FunnelCondition>('v1/campaign/funnel/condition/save/', condition);
      return data;
    },
  });
}

export function useConditionDuplicateMutation() {
  const { post } = useHttp();
  return useMutation<FunnelCondition, Error, FunnelCondition>({
    mutationKey: ['condition', 'duplicate'],
    mutationFn: async (condition: FunnelCondition) => {
      const { data } = await post<FunnelCondition, FunnelCondition>('v1/campaign/funnel/condition/duplicate/', condition);
      return data;
    },
  });
}

export function useConditionDeleteMutation() {
  const { del } = useHttp();

  return useMutation<null, Error, string>({
    mutationKey: ['condition', 'delete'],
    mutationFn: async (id: string) => {
      await del('v1/campaign/funnel/condition/delete/', {
        data: { entries: [id] },
      });
      return null;
    },
  });
}
