import { usePageGroupQuery, usePageGroupPagesCategoryInfoQuery, usePageGroupPagesQuery } from '@/api/queries/pageGroup';
import PageSlider from './components/PageSlider';
import SectionBox from '@/components/SectionBox';
import { PageGroup } from '@/models/pageGroup';
import useFormStore from '@/stores/forms';
import { FFButton, FFCheckbox, FFCol, FFField, FFInput, FFRow, FFSelect, FFSidePanel, FFTooltip, VisibilityWrapper } from '@/uikit';
import { FFSelectOption } from '@/uikit/types/select';
import { trimStringPropertiesInObject } from '@/utils/string';
import { message as AntMessage } from 'antd';
import { getSidebarOffsetLevel, getSidebarZIndex } from '@/utils/sidebar';
import { ucFirst } from '@/utils/string';
import RedirectLinks from './components/RedirectLinks';
import { forwardRef, Ref, useEffect, useMemo, useRef, useState, useImperativeHandle } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { usePageGroupCreateMutation, usePageGroupDuplicateMutation, usePageGroupUpdateMutation } from '@/api/mutations/pageGroup';
import useHttp from '@/hooks/http';
import { generateEntityId } from '@/utils/id';
import { InfoCircleOutlined } from '@ant-design/icons';
import { withIncrementedVersion } from '@/utils/model';
import DirectLinks from './components/DirectLink';
import ActionLinks from './components/ActionLinks';
import JavascriptTracking from './components/JavascriptTracking';
import AdditionalSettings from './components/AdditionalSettings';
import { FunnelNodePageParams } from '@/models/funnelNodePageParams';
import Skeleton from 'react-loading-skeleton';
import useMitt from '@/hooks/mitt';
import { CommonFormProps } from '@/types/forms/form';
import { FunnelNode } from '@/models/funnelNode';
import { FunnelNodeExtendedPageParams } from '@/types/forms/pageGroup';
import { definedObject } from '@/utils/define';

const ROUTING_TOOLTIP_CONTENT = (
  <>
    <p>When this is selected, pages become numbered and clicks coming from previous nodes will route based on the action number used.</p>

    <p>
      For example, if a user is on some lander A &gt; clicks action link 2 &gt; goes to this group, they will route to lander 2 in the
      ordered list.
    </p>

    <p>If they do not come from an action, they will always route to lander 1 in the list.</p>

    <p>
      Use this in situations like offer walls, where you have 10 products on a lander page and you want action X to go to offer X in the
      next group (allowing you to use a single offer group node, instead of making separate links to 10 offers).
    </p>
  </>
);

const DEFAULT_PAGEGROUP = (pageType: PageGroup['pageType']): PageGroup => ({
  idPageGroup: '',
  pageGroupName: '',
  pageType,
  routing: 'rotator',
  pages: [],
});

export type PageGroupFormTabId = 'general' | 'redirectLinks' | 'directLinks' | 'actionLinks' | 'jsTracking' | 'additionalSettings';

interface FormProps extends CommonFormProps<PageGroup, PageGroupFormTabId> {
  funnelNode?: FunnelNode;
  idFunnel?: string;
  updatePageOverrides?: (pageOverrides: Array<FunnelNodePageParams>) => void;
}

export interface PageGroupFormRefType {
  onSave: () => void;
}

export const PageGroupForm = forwardRef(
  (
    {
      currentTabId,
      funnelNode,
      defaultValues,
      idFunnel = '',
      saveMode = 'online',
      closeForm,
      setSubmitLoading,
      updatePageOverrides = () => {},
      onCreate = () => {},
      onDuplicate = () => {},
      onUpdate = () => {},
    }: FormProps,
    ref: Ref<PageGroupFormRefType>,
  ) => {
    const { get: httpGet } = useHttp();
    const emitter = useMitt();

    const {
      handleSubmit,
      watch,
      control,
      formState: { errors },
      getValues,
      reset,
      setValue,
    } = useForm<PageGroup>({
      defaultValues,
    });

    const selectedPages = watch('pages');
    const pageType = watch('pageType');
    const routing = watch('routing');

    const { control: pageOverridesControl, getValues: getValuesPageOverrides, trigger: triggerPageOverrides } = useForm<{ pageOverrides: FunnelNodeExtendedPageParams[] }>(
      {
        defaultValues: {
          pageOverrides: (funnelNode?.nodePageGroupParams?.pageOverrides || []).map((pageOverride) => ({
            ...pageOverride,
            appendExtraUrlParams: definedObject(pageOverride?.additionalTokens),
            overrideRedirectMode: !!pageOverride?.redirectOverride,
            extraUrlParams: pageOverride?.additionalTokens ? new URLSearchParams(pageOverride.additionalTokens).toString() : undefined,
          })),
        },
      },
    );

    const { data: pageCategoriesInfo = [], isLoading: isLoadingPageCategoriesInfo } = usePageGroupPagesCategoryInfoQuery(
      'active',
      pageType,
    );
    const { mutateAsync: createPageGroup } = usePageGroupCreateMutation();
    const { mutateAsync: updatePageGroup } = usePageGroupUpdateMutation();
    const { mutateAsync: duplicatePageGroup } = usePageGroupDuplicateMutation();
    const {
      data: pagesData = {},
      isFetching: isLoadingPagesData,
      refetch: refetchPages,
    } = usePageGroupPagesQuery(selectedPages.map((p) => p.idPage));

    const isDuplication = useFormStore((state) => state.pageGroup.isDuplication);
    const openVersioningForm = useFormStore((state) => state.openVersioningForm);
    const setVersioningType = useFormStore((state) => state.setVersioningType);

    const [latestVersionOfPageGroup, setLatestVersionOfPageGroup] = useState<PageGroup>();

    useEffect(() => {
      emitter.on('onLanderCreate', () => {
        refetchPages();
      });
      emitter.on('onLanderUpdate', () => {
        refetchPages();
      });
      emitter.on('onOfferCreate', () => {
        refetchPages();
      });
      emitter.on('onOfferUpdate', () => {
        refetchPages();
      });
      emitter.on('onVersioningConfirm', () => {
        onSave(true);
      });
    }, []);

    const categorizedPageOptions = useMemo(() => {
      const options: FFSelectOption[] = [];
      pageCategoriesInfo.forEach((pageCategoryInfo) => {
        if (pageCategoryInfo.pages) {
          pageCategoryInfo.pages.forEach((page) => {
            options.push({ label: page.pageName, value: page.idPage, category: pageCategoryInfo.categoryName });
          });
        }
      });
      return options.filter((opt) => !selectedPages.some((page) => page.idPage === opt.value));
    }, [pageCategoriesInfo, selectedPages]);

    const totalWeight = useMemo(() => selectedPages.reduce((acc, page) => acc + page.weight, 0), [selectedPages]);

    const onClose = () => {
      emitter.all.clear();
      closeForm();
    };

    const changeMap = (e: any) => {
      setValue('routing', e.target.checked ? 'actionMapped' : 'rotator');
    };

    const onSelectPage = (idPage: string) => {
      setValue('pages', [...getValues('pages'), { idPage, weight: 1 }]);
    };

    const onPageWeightChange = (idPage: string, weight: number) => {
      setValue(
        'pages',
        getValues('pages').map((page) => (page.idPage === idPage ? { ...page, weight } : page)),
      );
    };

    const onPageDelete = (idPage: string) => {
      setValue(
        'pages',
        getValues('pages').filter((page) => page.idPage !== idPage),
      );
    };

    const onReset = () => {
      reset();
    };

    const getPageOverrides = (): FunnelNodePageParams[] => {
      const pageOverrides = getValuesPageOverrides().pageOverrides;
      return pageOverrides
        .filter(
          (pageOverride) =>
            (pageOverride.accumulateUrlParams || pageOverride.extraUrlParams || pageOverride.redirectOverride) &&
            selectedPages.some((page) => page.idPage === pageOverride.idPage),
        )
        .map((pageOverride) => {
          const additionalTokens = pageOverride.extraUrlParams ? Object.fromEntries(new URLSearchParams(pageOverride.extraUrlParams)) : {};
          return {
            idPage: pageOverride.idPage,
            accumulateUrlParams: pageOverride.accumulateUrlParams,
            additionalTokens,
            redirectOverride: pageOverride.redirectOverride,
          };
        });
    };

    const onSave = (isConfirmingVersioning = false) =>
      handleSubmit(async (data) => {
        const pageOverrideIsValid = await triggerPageOverrides();
        if (!pageOverrideIsValid) {
          AntMessage.error('Please fix the errors in the Additional Settings tab');
          return;
        }
        if (!data.pages.length) {
          AntMessage.error('Please select at least one page');
          return;
        }
        setSubmitLoading(true);
        const duplicateFn = saveMode === 'offline' ? onDuplicate : duplicatePageGroup;
        const createFn = saveMode === 'offline' ? onCreate : createPageGroup;
        const updateFn = saveMode === 'offline' ? onUpdate : updatePageGroup;
        const onSaveSuccessFn = (pageGroup: PageGroup) => {
          emitter.emit('onPageGroupSave', pageGroup);
          onClose();
        };

        try {
          const newID = generateEntityId();
          const model = trimStringPropertiesInObject<PageGroup>(data, ['pageGroupName']);
          const updateModel = saveMode === 'offline' ? model : withIncrementedVersion(model);
          const duplicateModel = { ...model, meta: { version: 1 } };
          const createModel = { ...model, idPageGroup: newID, meta: { version: 1 } };

          if (isConfirmingVersioning) {
            try {
              let versionedUpdate = withIncrementedVersion(updateModel, latestVersionOfPageGroup?.meta?.version);
              await updateFn(versionedUpdate);
              emitter.emit('onPageGroupUpdate', versionedUpdate);
              onSaveSuccessFn(versionedUpdate);
            } catch (e) {
              AntMessage.error('Failed to save page group');
            } finally {
              setSubmitLoading(false);
            }
            return;
          }
          if (isDuplication) {
            await duplicateFn(duplicateModel);
            emitter.emit('onPageGroupCreate', duplicateModel);
          } else if (model.idPageGroup) {
            const pageGroup = await httpGet<PageGroup>('v1/page/group/find/byId', {
              params: { idPageGroup: model.idPageGroup },
            });
            setLatestVersionOfPageGroup(pageGroup.data);
            if (model.meta?.version !== pageGroup.data.meta?.version) {
              setVersioningType('pageGroup');
              openVersioningForm();
              return;
            } else {
              await updateFn(updateModel);
              emitter.emit('onPageGroupUpdate', updateModel);
            }
          } else {
            await createFn(createModel);
            emitter.emit('onPageGroupCreate', createModel);
          }

          AntMessage.success(`${model.pageGroupName} ${data.idPageGroup ? 'has been updated' : 'has been added'} successfully`);
          updatePageOverrides(getPageOverrides());
          onClose();
        } catch (e: any) {
          AntMessage.error(`${data.pageGroupName} ${data.idPageGroup ? `cannot be updated. ${e.response?.data?.message}` : `cannot be added. ${e.response?.data?.message}`}`);
        } finally {
          setSubmitLoading(false);
        }
      })();

    useImperativeHandle(ref, () => ({ onSave }));

    return (
      <>
        <VisibilityWrapper visible={currentTabId === 'general'}>
          <SectionBox title="General Settings">
            <FFCol gap="12px">
              <Controller
                name="pageGroupName"
                control={control}
                rules={{ required: 'Required' }}
                render={(opt) => (
                  <FFField label="Page Group Name" block>
                    <FFInput
                      value={opt.field.value}
                      onChange={opt.field.onChange}
                      error={errors.pageGroupName?.message}
                      placeholder="Enter a group name"
                    />
                  </FFField>
                )}
              />
              <FFField label={`Add ${ucFirst(defaultValues.pageType)}`}>
                <FFSelect
                  options={categorizedPageOptions}
                  valueGetter={(opt) => opt.value}
                  labelGetter={(opt) => opt.label}
                  loading={isLoadingPageCategoriesInfo}
                  showSearch
                  filterOption
                  sortGroup
                  groupOptions
                  placeholder="Select a page"
                  onSelect={onSelectPage}
                />
              </FFField>
              <Controller
                name="routing"
                control={control}
                render={(opt) => (
                  <FFCheckbox checked={opt.field.value === 'actionMapped'} onClick={changeMap}>
                    <FFRow alignItems="center" gap="4px">
                      <span>Map incoming action to lander number</span>
                      <FFTooltip title={ROUTING_TOOLTIP_CONTENT} placement="top">
                        <InfoCircleOutlined />
                      </FFTooltip>
                    </FFRow>
                  </FFCheckbox>
                )}
              />
              <FFButton type="tertiaryDanger" onClick={onReset}>
                Reset
              </FFButton>
              {selectedPages.map((pageGroupEntry, index) => (
                <PageSlider
                  key={pageGroupEntry.idPage}
                  index={index}
                  totalWeight={totalWeight}
                  pageType={defaultValues.pageType}
                  pageGroupEntry={pageGroupEntry}
                  page={pagesData?.[pageGroupEntry.idPage]}
                  showSkeleton={isLoadingPageCategoriesInfo || isLoadingPagesData}
                  routing={routing}
                  onChange={onPageWeightChange}
                  onDelete={onPageDelete}
                />
              ))}
            </FFCol>
          </SectionBox>
        </VisibilityWrapper>
        <VisibilityWrapper beRenderedAlways visible={currentTabId === 'redirectLinks'}>
          <RedirectLinks
            pageType={defaultValues.pageType}
            pages={Object.values(pagesData)}
            idFunnel={idFunnel}
            nodeId={funnelNode?.idNode!}
          />
        </VisibilityWrapper>
        <VisibilityWrapper beRenderedAlways visible={currentTabId === 'directLinks'}>
          <DirectLinks
            pageType={defaultValues.pageType}
            pages={Object.values(pagesData)}
            idFunnel={idFunnel}
            nodeId={funnelNode?.idNode!}
          />
        </VisibilityWrapper>
        <VisibilityWrapper beRenderedAlways visible={currentTabId === 'actionLinks'}>
          <ActionLinks idFunnel={idFunnel} nodeId={funnelNode?.idNode!} />
        </VisibilityWrapper>
        <VisibilityWrapper beRenderedAlways visible={currentTabId === 'jsTracking'}>
          <JavascriptTracking pages={Object.values(pagesData)} idFunnel={idFunnel} />
        </VisibilityWrapper>
        <VisibilityWrapper visible={currentTabId === 'additionalSettings'} beRenderedAlways>
          <AdditionalSettings pages={Object.values(pagesData)} selectedPages={selectedPages} control={pageOverridesControl} />
        </VisibilityWrapper>
      </>
    );
  },
);

const PageGroupSidebarForm = () => {
  const {
    pageGroup: { isDuplication, isGlobalOpen, data, pageGroupType },
    openedForms,
    closePageGroupForm,
  } = useFormStore();
  const { data: defaultFormValues = DEFAULT_PAGEGROUP(pageGroupType), isLoading, isFetching } = usePageGroupQuery(data?.id!);
  const [submitLoading, setSubmitLoading] = useState(false);

  const formRef = useRef<PageGroupFormRefType>({
    onSave: () => {},
  });

  const sidebarTitle = useMemo(() => {
    if (isDuplication) return `Duplicate ${ucFirst(pageGroupType)} Group`;
    else if (data?.id) return `Edit ${ucFirst(pageGroupType)} Group`;
    else return `Create ${ucFirst(pageGroupType)} Group`;
  }, [data?.id, isDuplication, pageGroupType]);

  const onClose = () => {
    closePageGroupForm('global');
  };

  return (
    <FFSidePanel
      isOpen={isGlobalOpen}
      minWidth={600}
      maxWidth={1100}
      onClose={onClose}
      sidebarName="PageGroupForm"
      offsetLevel={getSidebarOffsetLevel(openedForms, 'pageGroup')}
      zIndex={getSidebarZIndex(openedForms, 'pageGroup')}
      title={sidebarTitle}
      actions={
        <FFRow gap="8px">
          <FFButton onClick={() => formRef.current.onSave()} loading={submitLoading} disabled={submitLoading}>
            Save
          </FFButton>
          <FFButton type="tertiary" disabled={submitLoading} onClick={onClose}>
            Cancel
          </FFButton>
        </FFRow>
      }
    >
      {(!defaultFormValues || isLoading || isFetching) ? (
        <Skeleton width="100%" height="400px" />
      ) : (
        <PageGroupForm
          defaultValues={defaultFormValues}
          currentTabId="general"
          isDuplication={isDuplication}
          submitLoading={submitLoading}
          closeForm={onClose}
          setSubmitLoading={setSubmitLoading}
          ref={formRef}
        />
      )}
    </FFSidePanel>
  );
};

export default PageGroupSidebarForm;
