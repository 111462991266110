import { AppStore, AppStoreProps } from '@/types/stores/app';
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

const DEFAULT_PROPS: AppStoreProps = {
  isSidebarExpanded: true,
};

const useAppStore = create<AppStore>()(
  devtools(
    persist(
      (set) => ({
        ...DEFAULT_PROPS,
        toggleSidebar: () =>
          set((state) => {
            state.isSidebarExpanded = !state.isSidebarExpanded;
            return state;
          }),
      }),
      {
        name: 'app',
      },
    ),
  ),
);

export default useAppStore;
