import { FFButton, FFRow } from '@/uikit';
import { TabOption } from '@/uikit/types/tab';

const Tab = <T extends number | string>({
  value,
  onChange,
  options,
  disabled = false,
}: {
  value: T;
  disabled?: boolean;
  onChange: (tabId: T) => void;
  options: TabOption<T>[];
}) => (
  <FFRow alignItems="center" gap="8px">
    {options.map((opt, i) => (
      <FFButton key={i} disabled={disabled} size="middle" type="tertiary" active={value === opt.value} onClick={() => onChange(opt.value)}>
        {opt.label}
      </FFButton>
    ))}
  </FFRow>
);

export default Tab;
