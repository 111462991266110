import { UNCATEGORIZED } from '@/constants';
import { defaultDrilldownReportEvents } from '@/constants/reportings';
import { DrilldownReport } from '@/models/drilldownReport';
import { DrilldownReportParams } from '@/models/drilldownReportParams';
import { DrilldownReportRow } from '@/models/drilldownReportRow';
import { DrilldownReportRowAttribute } from '@/models/drilldownReportRowAttribute';
import { ReportCategory } from '@/models/reportCategory';
import { FluxColProps } from '@/types/table/column';
import { defined } from '@/utils/define';

export const getReportingCategoriesObject = (reporting: DrilldownReport): Record<string, ReportCategory> => {
  return defined(reporting.entities) && defined(reporting.entities.categories)
    ? reporting.entities.categories.reduce((acc: Record<string, ReportCategory>, category: ReportCategory) => {
        acc[category.id] = category;
        return acc;
      }, {})
    : {};
};

export const getCategoryAsDrilldownAttribute = (
  entity: DrilldownReportRowAttribute,
  categories: Record<string, Partial<ReportCategory>>,
) => {
  let category: Partial<DrilldownReportRowAttribute> = {
    id: UNCATEGORIZED,
    value: UNCATEGORIZED,
    status: entity.status || 'archived',
  };

  if (defined(categories?.[entity?.categoryID!])) {
    let reportCategory = categories[entity.categoryID!];
    category.id = reportCategory.id;
    category.value = reportCategory.name;
  }

  return category as DrilldownReportRowAttribute;
};

export const getRestrictToMetricsKeys = <T = DrilldownReportRow>(columnDefs: FluxColProps<T>[]) => {
  return [
    Array.from(
      new Set(
        columnDefs
          .map((columnDef) => columnDef?.restrictToMetrics || [])
          .flat()
          .filter(Boolean),
      ),
    ),
  ];
};

export const getCalculatedMetricsKeys = (columnDefs: FluxColProps[]) => {
  return [
    Array.from(
      new Set(
        columnDefs
          .map((columnDef) => columnDef?.calculatedMetrics || [])
          .flat()
          .filter(Boolean),
      ),
    ),
  ];
};

export const makeReportingRowData = (attributes: DrilldownReportRowAttribute[], date = ''): DrilldownReportRow & { date?: string } => ({
  attributes: attributes,
  conversions: 0,
  cost: '0',
  indirectConversions: 0,
  indirectRevenue: '0',
  landerClicks: 0,
  landerClicksUnique: 0,
  landerViews: 0,
  landerViewsUnique: 0,
  nodeViews: 0,
  nodeViewsUnique: 0,
  offerClicks: 0,
  offerClicksUnique: 0,
  offerViews: 0,
  offerViewsUnique: 0,
  revenue: '0',
  visitors: 0,
  visits: 0,
  googleAverageCpc: 0,
  googleAverageCpm: 0,
  googleClicks: 0,
  googleCostMicros: 0,
  googleCtr: 0,
  googleImpressions: 0,
  customEvents: defaultDrilldownReportEvents,
  date,
  calculatedMetrics: {
    lander_ctr: 0,
    offer_ctr: 0,
    unique_lander_ctr: 0,
    unique_offer_ctr: 0,
    conversion_per_lander_click: 0,
    conversion_per_lander_view: 0,
    conversion_per_offer_click: 0,
    conversion_per_offer_view: 0,
    conversion_per_unique_lander_click: 0,
    conversion_per_unique_lander_view: 0,
    conversion_per_unique_offer_click: 0,
    conversion_per_unique_offer_view: 0,
    conversion_per_unique_visitor: 0,
    conversion_per_visit: 0,
    cost_per_conversion: 0,
    cost_per_offer_click: 0,
    cost_per_offer_view: 0,
    cost_per_unique_lander_click: 0,
    cost_per_unique_lander_view: 0,
    cost_per_unique_offer_click: 0,
    cost_per_unique_offer_view: 0,
    cost_per_unique_visitor: 0,
    cost_per_visit: 0,
    cost_per_visitor: 0,
    uniqueness: 0,
    profit_and_loss: 0,
    roi: 0,
    revenue_lifetime: 0,
    revenue_per_conversion: 0,
    revenue_per_offer_click: 0,
    revenue_per_offer_view: 0,
    revenue_per_unique_lander_click: 0,
    revenue_per_unique_lander_view: 0,
    revenue_per_unique_offer_click: 0,
    revenue_per_unique_offer_view: 0,
    revenue_per_unique_visitor: 0,
    revenue_per_visit: 0,
    revenue_per_visitor: 0,
    revenue_per_event_1: 0,
    revenue_per_event_2: 0,
    revenue_per_event_3: 0,
    revenue_per_event_4: 0,
    revenue_per_event_5: 0,
    revenue_per_event_6: 0,
    revenue_per_event_7: 0,
    revenue_per_event_8: 0,
    revenue_per_event_9: 0,
    revenue_per_event_10: 0,
    revenue_per_lander_click: 0,
    revenue_per_lander_view: 0,
    cost_per_event_1: 0,
    cost_per_event_2: 0,
    cost_per_event_3: 0,
    cost_per_event_4: 0,
    cost_per_event_5: 0,
    cost_per_event_6: 0,
    cost_per_event_7: 0,
    cost_per_event_8: 0,
    cost_per_event_9: 0,
    cost_per_event_10: 0,
    cost_per_lander_click: 0,
    cost_per_lander_view: 0,
  },
});

export const transformDrilldownReport = (report: DrilldownReport): DrilldownReport => {
  return report;
};