const urlSpecialChars = ['@', '^', '$', '#', '%', '*', '`', '(', ')', '~'];
const MAX_SPECIAL_CHARS_COUNT = 10;

export const validateUrl = (value: string) => {
  try {
    const url = new URL(value);
    const specialCharsCount = urlSpecialChars.reduce((acc, item) => {
      acc += value.split(item).length - 1;
      return acc;
    }, 0);
    const isValidProtocol =
      /^(https?|ftp|callto|geo|mailto|maps|bip|bbmi|itms-services|fb-me|fb-messenger|intent|line|skype|sms|snapchat|tel|tg|threema|twitter|viber|webcal|web\+mastodon|wh|whatsapp):$/i.test(
        url.protocol
      );
    if (!isValidProtocol || !(specialCharsCount < MAX_SPECIAL_CHARS_COUNT)) {
      return 'Not a valid URL';
    }
  } catch (e) {
    return 'Not a valid URL';
  }
};

export const validateCloudflareHostname = (value: string) => {
  return !new RegExp(
    '^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$|^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]).)+([A-Za-z]|[A-Za-z][A-Za-z0-9-]*[A-Za-z0-9])$'
  ).test(value)
    ? 'Your domain matches one of our exclude lists - please make sure to use a real, valid domain, as Cloudflare will reject addition of things like example.com'
    : true;
};

export const validateDomainContainFunnelflux = (value: string) => {
  return value.includes('funnelflux') ? 'Your domain must contain "funnelflux"' : true;
}

export const validateUniqueness = (data: string | string[], resourceName: string) => {
  return !data.includes(resourceName) ? true : `A ${resourceName} with name "${resourceName}" already exists`;
}