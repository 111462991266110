import { RouterProvider } from 'react-router-dom';
import { protectedRoutes } from './protected';
import { publicRoutes } from './public';
import useAuthStore from '@/stores/auth';
import { useEffect, useMemo, useState } from 'react';
import useAuthMitt from '@/hooks/authMitt';

export const AppRoutes = () => {
  const emitter = useAuthMitt();
  const isLoggedInDefaultValue = useAuthStore(state => state.isLoggedIn);
  const [isLoggedIn, setIsLoggedIn] = useState(isLoggedInDefaultValue);

  useEffect(() => {
    emitter.on('onLogin', () => {
      setIsLoggedIn(true);
    });
    emitter.on('onLogout', () => {
      setIsLoggedIn(false);
    });
  }, []);

  const routes = useMemo(() => (isLoggedIn ? protectedRoutes : publicRoutes), [isLoggedIn]);
  return <RouterProvider router={routes} />;
};
