import { FFModal, FFNewIcon, FFRow } from '@/uikit';
import className from '@/utils/className';
import useFormStore from '@/stores/forms';
import './style.scss';

const { blockClassName, getClass } = className('c-createNewAssetModal');

type AssetType =
  | 'funnelGroup'
  | 'visualFunnel'
  | 'simpleFlow'
  | 'trafficSource'
  | 'lander'
  | 'offer'
  | 'offerSource'
  | 'pageGroup'
  | 'condition';

const CreateNewAssetModal = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
  const openFunnelGroupForm = useFormStore((state) => state.openFunnelGroupForm);
  const openFunnelForm = useFormStore((state) => state.openFunnelForm);
  const openSimpleFlowForm = useFormStore((state) => state.openSimpleFlowForm);
  const openTrafficSourceForm = useFormStore((state) => state.openTrafficSourceForm);
  const openLanderForm = useFormStore((state) => state.openLanderForm);
  const openOfferForm = useFormStore((state) => state.openOfferForm);
  const openOfferSourceForm = useFormStore((state) => state.openOfferSourceForm);
  const openPageGroupForm = useFormStore((state) => state.openPageGroupForm);
  const openConditionForm = useFormStore((state) => state.openConditionForm);

  const onAssetClick = (assetType: AssetType) => () => {
    onClose();
    setTimeout(() => {
      if (assetType === 'funnelGroup') {
        openFunnelGroupForm();
      } else if (assetType === 'visualFunnel') {
        openFunnelForm();
      } else if (assetType === 'simpleFlow') {
        openSimpleFlowForm();
      } else if (assetType === 'trafficSource') {
        openTrafficSourceForm();
      } else if (assetType === 'lander') {
        openLanderForm();
      } else if (assetType === 'offer') {
        openOfferForm();
      } else if (assetType === 'offerSource') {
        openOfferSourceForm();
      } else if (assetType === 'pageGroup') {
        openPageGroupForm('selection');
      } else if (assetType === 'condition') {
        openConditionForm('global');
      }
    }, 200);
  };

  return (
    <FFModal width={842} title="Create New Asset" open={isOpen} onCancel={onClose}>
      <FFRow className={blockClassName}>
        <button className={getClass('asset')} onClick={onAssetClick('funnelGroup')}>
          <FFNewIcon name="general/duotone/label-folder-tag" type="sidebartab" />
          Funnel Group
        </button>
        <button className={getClass('asset')} onClick={onAssetClick('visualFunnel')}>
          <FFNewIcon name="navigation/funnels" type="sidebartab" />
          Visual Funnel
        </button>
        <button className={getClass('asset')} onClick={onAssetClick('simpleFlow')}>
          <FFNewIcon name="general/duotone/simple-flow" type="sidebartab" />
          Simple Flow
        </button>
        <button className={getClass('asset')} onClick={onAssetClick('trafficSource')}>
          <FFNewIcon name="navigation/traffic-sources" type="sidebartab" />
          Traffic Source
        </button>
        <button className={getClass('asset')} onClick={onAssetClick('lander')}>
          <FFNewIcon name="navigation/landers" type="sidebartab" />
          Lander
        </button>
        <button className={getClass('asset')} onClick={onAssetClick('offer')}>
          <FFNewIcon name="navigation/offers" type="sidebartab" />
          Offer
        </button>
        <button className={getClass('asset')} onClick={onAssetClick('offerSource')}>
          <FFNewIcon name="navigation/offer-sources" type="sidebartab" />
          Offer Source
        </button>
        <button className={getClass('asset')} onClick={onAssetClick('pageGroup')}>
          <FFNewIcon name="navigation/page-groups" type="sidebartab" />
          Global Page Group
        </button>
        <button className={getClass('asset')} onClick={onAssetClick('condition')}>
          <FFNewIcon name="navigation/conditions" type="sidebartab" />
          Global Condition
        </button>
      </FFRow>
    </FFModal>
  );
};

export default CreateNewAssetModal;
