import useOffersStore from '@/features/pagesAndResources/stores/offers';
import useHttp from '@/hooks/http';
import { DrilldownReport } from '@/models/drilldownReport';
import { DrilldownReportParams } from '@/models/drilldownReportParams';
import { Page } from '@/models/page';
import { transformDrilldownReport } from '@/utils/reporting';
import { useMutation } from '@tanstack/react-query';

export function useOfferCreateMutation() {
  const { post } = useHttp();

  return useMutation<null, Error, Page>({
    mutationKey: ['offer', 'create'],
    mutationFn: async (offer: Page) => {
      await post('v1/page/save/', offer);
      return null;
    },
  });
}

export function useOfferUpdateMutation() {
  const { put } = useHttp();

  return useMutation<null, Error, Page>({
    mutationKey: ['offer', 'update'],
    mutationFn: async (offer: Page) => {
      await put('v1/page/save/', offer);
      return null;
    },
  });
}

export function useOfferDuplicateMutation() {
  const { post } = useHttp();

  return useMutation<null, Error, Page>({
    mutationKey: ['offer', 'duplicate'],
    mutationFn: async (offer: Page) => {
      await post('v1/page/duplicate/', offer);
      return null;
    },
  });
}

export function useOfferDeleteMutation() {
  const { del } = useHttp();

  return useMutation<null, Error, string>({
    mutationKey: ['offer', 'delete'],
    mutationFn: async (id: string) => {
      await del('v1/page/delete/', {
        data: { entries: [id] },
      });
      return null;
    },
  });
}

export function useOfferArchiveMutation() {
  const { put } = useHttp();

  return useMutation<null, Error, Page>({
    mutationKey: ['offer', 'archive'],
    mutationFn: async (page: Page) => {
      await put<Page, null>('v1/page/save/', page);
      return null;
    },
  });
}

export function useOfferUnArchiveMutation() {
  const { put } = useHttp();

  return useMutation<null, Error, Page>({
    mutationKey: ['offer', 'unarchive'],
    mutationFn: async (page: Page) => {
      await put<Page, null>('v1/page/save/', page);
      return null;
    },
  });
}

export function useOffersReportingMutation() {
  const { post } = useHttp();
  const setReportingParams = useOffersStore((state) => state.setReportingParams);
  const setReporting = useOffersStore((state) => state.setReporting);

  return useMutation<DrilldownReport, Error, DrilldownReportParams>({
    mutationKey: ['offers', 'reporting'],
    mutationFn: async (reportParams: DrilldownReportParams) => {
      const { data } = await post<DrilldownReportParams, DrilldownReport>('v1/reporting/drilldown', reportParams);
      const reporting = transformDrilldownReport(data);
      setReportingParams(reportParams);
      setReporting(reporting);
      return reporting;
    },
  });
}