import { createBrowserRouter, Navigate } from 'react-router-dom';
import { lazyImport } from '@/utils/lazyImport';
import ErrorFallback from '@/components/ErrorFallback';

const { LoginRoutes } = lazyImport(() => import('@/features/login'), 'LoginRoutes');

export const publicRoutes = createBrowserRouter([
  {
    path: '/',
    errorElement: <ErrorFallback />,
    element: <LoginRoutes />,
  },
  {
    path: '*',
    element: <Navigate to="/" />,
  }
]);
