import React, { useState, useEffect } from 'react';

interface ImageAsyncSrcProps {
  src: string;
  alt: string;
  className?: string;
}

const ImageAsyncSrc: React.FC<ImageAsyncSrcProps> = ({ src, alt, className }) => {
  const [imageSrc, setImageSrc] = useState<string | null>(null);

  useEffect(() => {
    const loadImage = async () => {
      try {
        const imageModule = await import(`@/imgs/${src}`);
        setImageSrc(imageModule.default);
      } catch (error) {
        console.error(`Error loading image: ${src}`, error);
        setImageSrc(null);
      }
    };

    loadImage();
  }, [src]);

  if (!imageSrc) {
    return null;
  }

  return <img src={imageSrc} alt={alt} className={className} />;
};

export default ImageAsyncSrc;
