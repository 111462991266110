import useHttp from '@/hooks/http';
import { Funnel } from '@/models/funnel';
import { FunnelGroup } from '@/models/funnelGroup';
import { useMutation } from '@tanstack/react-query';

export function useFunnelDuplicateMutation() {
  const { post } = useHttp();
  return useMutation<Funnel, Error, Funnel>({
    mutationKey: ['funnel', 'duplicate'],
    mutationFn: async (funnel: Funnel) => {
      const { data } = await post<Funnel, Funnel>('v1/campaign/funnel/duplicate/', funnel);
      return data;
    },
  });
}

export function useFunnelUpdateMutation() {
  const { put } = useHttp();
  return useMutation<Funnel, Error, Funnel>({
    mutationKey: ['funnel', 'update'],
    mutationFn: async (funnel: Funnel) => {
      const { data } = await put<Funnel, Funnel>('v1/campaign/funnel/save/', funnel);
      return data;
    },
  });
}

export function useFunnelCreateMutation() {
  const { post } = useHttp();
  return useMutation<Funnel, Error, Funnel>({
    mutationKey: ['funnel', 'create'],
    mutationFn: async (funnel: Funnel) => {
      const { data } = await post<Funnel, Funnel>('v1/campaign/funnel/save/', funnel);
      return data;
    },
  });
}

export function useFunnelGroupDuplicateMutation() {
  const { post } = useHttp();
  return useMutation<FunnelGroup, Error, FunnelGroup>({
    mutationKey: ['funnelGroup', 'duplicate'],
    mutationFn: async (funnelGroup: FunnelGroup) => {
      const { data } = await post<FunnelGroup, FunnelGroup>('v1/campaign/duplicate/', funnelGroup);
      return data;
    },
  });
}

export function useFunnelGroupUpdateMutation() {
  const { put } = useHttp();
  return useMutation<FunnelGroup, Error, FunnelGroup>({
    mutationKey: ['funnelGroup', 'update'],
    mutationFn: async (funnelGroup: FunnelGroup) => {
      const { data } = await put<FunnelGroup, FunnelGroup>('v1/campaign/save/', funnelGroup);
      return data;
    },
  });
}

export function useFunnelGroupCreateMutation() {
  const { post } = useHttp();
  return useMutation<FunnelGroup, Error, FunnelGroup>({
    mutationKey: ['funnelGroup', 'create'],
    mutationFn: async (funnelGroup: FunnelGroup) => {
      const { data } = await post<FunnelGroup, FunnelGroup>('v1/campaign/save/', funnelGroup);
      return data;
    },
  });
}

export function useFunnelGroupDeleteMutation() {
  const { del } = useHttp();

  return useMutation<null, Error, string>({
    mutationKey: ['funnelGroup', 'delete'],
    mutationFn: async (id: string) => {
      await del('v1/campaign/delete/', {
        data: { entries: [id] },
      });
      return null;
    },
  });
}

export function useFunnelDeleteMutation() {
  const { del } = useHttp();

  return useMutation<null, Error, string[]>({
    mutationKey: ['funnel', 'delete'],
    mutationFn: async (id: string[]) => {
      await del('v1/campaign/funnel/delete/', {
        data: { entries: [...id] },
      });
      return null;
    },
  });
}

export function useFunnelGroupArchiveMutation() {
  const { put } = useHttp();

  return useMutation<null, Error, FunnelGroup>({
    mutationKey: ['funnelGroup', 'archive'],
    mutationFn: async (funnelGroup: FunnelGroup) => {
      await put('v1/campaign/save/', {
        ...funnelGroup,
        status: 'archived',
      });
      return null;
    },
  });
}

export function useFunnelGroupUnArchiveMutation() {
  const { put } = useHttp();

  return useMutation<null, Error, FunnelGroup>({
    mutationKey: ['funnelGroup', 'unarchive'],
    mutationFn: async (funnelGroup: FunnelGroup) => {
      await put('v1/campaign/save/', {
        ...funnelGroup,
        status: 'active',
      });
      return null;
    },
  });
}

export function useFunnelMoveMutation() {
  const { put } = useHttp();

  return useMutation<null, Error, { ids: string[]; idFunnelGroup: string }>({
    mutationKey: ['funnel', 'delete'],
    mutationFn: async ({ ids, idFunnelGroup }) => {
      await put(
        'v1/campaign/funnel/move/',
        { entries: [...ids] },
        {
          params: {
            idCampaign: idFunnelGroup,
          },
        },
      );
      return null;
    },
  });
}

export function useFunnelArchiveMutation() {
  const { put } = useHttp();

  return useMutation<null, Error, string[]>({
    mutationKey: ['funnel', 'archive'],
    mutationFn: async (funnelIds: string[]) => {
      await put('v1/campaign/funnel/archive/', {
        entries: funnelIds,
      });
      return null;
    },
  });
}

export function useFunnelUnArchiveMutation() {
  const { put } = useHttp();

  return useMutation<null, Error, string[]>({
    mutationKey: ['funnel', 'unarchive'],
    mutationFn: async (funnelIds: string[]) => {
      await put('v1/campaign/funnel/unarchive/', {
        entries: funnelIds,
      });
      return null;
    },
  });
}
