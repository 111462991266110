import { useOfferSourceCreateMutation, useOfferSourceDuplicateMutation, useOfferSourceUpdateMutation } from '@/api/mutations/offersource';
import Skeleton from 'react-loading-skeleton';
import { useOfferSourceCategoryListQuery, useOfferSourceQuery } from '@/api/queries/offersource';
import CodeSnippet from '@/components/CodeSnippet';
import DataPassing from '@/components/DataPassing';
import SectionBox from '@/components/SectionBox';
import { TemplateSelector } from '@/components/TemplateSelector';
import { OFFER_SOURCE_TEMPLATES } from '@/constants/templates';
import { OfferSource } from '@/models/offerSource';
import useFormStore from '@/stores/forms';
import { message as AntMessage } from 'antd';
import useSystemSettingsStore from '@/stores/systemSettings';
import { OfferSourceTemplate } from '@/types/offerSource';
import { FFIconButton, FFCol, FFField, FFInput, FFRow, FFSelect, FFSidePanel, VisibilityWrapper, FFButton, FFNewIcon } from '@/uikit';
import { SidebarTab } from '@/uikit/types/sidebar';
import { generateEntityId } from '@/utils/id';
import { withIncrementedVersion } from '@/utils/model';
import { getSidebarOffsetLevel, getSidebarZIndex } from '@/utils/sidebar';
import { addOrIncrementCopySuffix, trimStringPropertiesInObject } from '@/utils/string';
import { Ref, forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import useHttp from '@/hooks/http';
import useMitt from '@/hooks/mitt';

type TabId = 'general' | 'dataPassing' | 'conversionTracking' | 'help';

const DEFAULT_POSTBACK_SUBID = 'REQUIRED';
const DEFAULT_POSTBACK_TXID = 'OPTIONAL_TXID';
const DEFAULT_POSTBACK_PAYOUT = 'OPTIONAL_REVENUE';

const DEFAULT_OFFERSOURCE: OfferSource = {
  queryParams: {},
  offerSourceName: '',
  idOfferSource: '',
  postbackPayout: '',
  postbackSubId: '',
  postbackTxId: '',
};

const tabs: SidebarTab[] = [
  {
    title: 'General Settings',
    tabId: 'general',
    icon: <FFNewIcon name="sidebar-tabs/general-settings" size="md" display="inline-block" type="sidebartab" />,
  },
  {
    title: 'Configure Data Passing',
    tabId: 'dataPassing',
    icon: <FFNewIcon name="sidebar-tabs/configure-data-passing" size="md" display="inline-block" type="sidebartab" />,
  },
  {
    title: 'Conversion Tracking',
    tabId: 'conversionTracking',
    icon: <FFNewIcon name="sidebar-tabs/conversion-tracking" size="md" display="inline-block" type="sidebartab" />,
  },
  {
    title: 'Help',
    tabId: 'help',
    icon: <FFNewIcon name="sidebar-tabs/help" size="md" display="inline-block" type="sidebartab" />,
  },
];

interface FormProps {
  closeForm: () => void;
  currentTabId: TabId;
  defaultFormValues: OfferSource;
  submitLoading: boolean;
  setSubmitLoading: (loading: boolean) => void;
}

interface RefType {
  onSave: () => void;
}

const Form = forwardRef(({ currentTabId, closeForm, setSubmitLoading, submitLoading, defaultFormValues }: FormProps, ref: Ref<RefType>) => {
  const { get: httpGet } = useHttp();
  const emitter = useMitt();
  const { data: categories = [], refetch: refetchCategories } = useOfferSourceCategoryListQuery(false);
  const { domains, userSettings } = useSystemSettingsStore();
  const offerSourceFormProps = useFormStore((state) => state.offerSource);
  const openCategoryForm = useFormStore((state) => state.openCategoryForm);
  const openVersioningForm = useFormStore((state) => state.openVersioningForm);
  const setVersioningType = useFormStore((state) => state.setVersioningType);

  const { mutateAsync: createOfferSource } = useOfferSourceCreateMutation();
  const { mutateAsync: updateOfferSource } = useOfferSourceUpdateMutation();
  const { mutateAsync: duplicateOfferSource } = useOfferSourceDuplicateMutation();

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitted },
    getValues,
    setValue,
    watch,
  } = useForm<OfferSource>({
    defaultValues: defaultFormValues,
  });
  const queryParams = watch('queryParams');

  const [domain, setDomain] = useState<string>(userSettings.defaultCustomDomain);
  const [latestVersionOfOfferSource, setLatestVersionOfOfferSource] = useState<OfferSource>();
  const [dataPassingHasError, setDataPassingHasError] = useState(false);

  useEffect(() => {
    emitter.on('onVersioningConfirm', () => onSave(false, true));
    emitter.on('onCategorySave', () => refetchCategories());
  }, []);

  const onSaveAndCreate = () => {
    onSave(true);
  };

  const onClose = () => {
    emitter.all.clear();
    closeForm();
  };

  const onSave = (isSaveAndCreate = false, isConfirmingVersioning = false) =>
    handleSubmit(async (data) => {
      if (!dataPassingHasError) {
        setSubmitLoading(true);
        const onSaveSuccessFn = (offerSource: OfferSource) => {
          emitter.emit('onOfferSourceSave', offerSource);
          onClose();
        };

        try {
          const newID = generateEntityId();
          const model = trimStringPropertiesInObject(data, ['offerSourceName']);
          const updateModel = withIncrementedVersion(model);
          const duplicateModel = { ...model, meta: { version: 1 } };
          const createModel: OfferSource = { ...model, idOfferSource: newID, meta: { version: 1 } };
          const category = categories.find((c) => c.idCategory === model.idCategory)!;

          if (isConfirmingVersioning) {
            try {
              let versionedUpdate = withIncrementedVersion(updateModel, latestVersionOfOfferSource?.meta?.version);
              await updateOfferSource(versionedUpdate);
              emitter.emit('onOfferSourceUpdate', versionedUpdate);
              onSaveSuccessFn(versionedUpdate);
            } catch (e) {
              AntMessage.error('Failed to save offer source');
            } finally {
              setSubmitLoading(false);
            }
            return;
          } else if (offerSourceFormProps.isDuplication) {
            await duplicateOfferSource(duplicateModel);
            emitter.emit('onOfferSourceCreate', { data: duplicateModel, category });
          } else if (model.idOfferSource) {
            const offersource = await httpGet<OfferSource>('v1/offersource/find/byId', {
              params: { idOfferSource: model.idOfferSource },
            });
            setLatestVersionOfOfferSource(offersource.data);
            if (model.meta?.version !== offersource.data.meta?.version) {
              setVersioningType('offerSource');
              openVersioningForm();
              return;
            } else {
              await updateOfferSource(updateModel);
              emitter.emit('onOfferSourceUpdate', updateModel);
            }
          } else {
            await createOfferSource(createModel);
            setValue('idOfferSource', '');
            emitter.emit('onOfferSourceCreate', { data: createModel, category });
          }

          AntMessage.success(`${model.offerSourceName} ${data.idOfferSource ? 'has been updated' : 'has been added'} successfully`);
          if (isSaveAndCreate) {
            setValue('offerSourceName', addOrIncrementCopySuffix(data.offerSourceName));
          } else {
            onClose();
          }
        } catch (e: any) {
          AntMessage.error(
            `${data.offerSourceName} ${
              data.idOfferSource ? `cannot be updated. ${e.response?.data?.message}` : `cannot be added. ${e.response?.data?.message}`
            }`,
          );
        } finally {
          setSubmitLoading(false);
        }
      }
    })();

  useImperativeHandle(ref, () => ({ onSave }));

  return (
    <>
      <VisibilityWrapper visible={currentTabId === 'general'} beRenderedAlways>
        <SectionBox title="General Settings">
          <FFCol gap="18px" height="max-content">
            <FFRow gap="8px">
              <Controller
                name="offerSourceName"
                control={control}
                rules={{ required: 'Offer Source Name is required' }}
                render={(opt) => (
                  <FFField label="Offer Source Name" block>
                    <FFInput
                      value={getValues('offerSourceName')}
                      onChange={opt.field.onChange}
                      error={errors.offerSourceName?.message}
                      placeholder="Offer Source Name"
                    />
                  </FFField>
                )}
              />
              <Controller
                name="idCategory"
                control={control}
                render={(opt) => (
                  <FFField label="Category">
                    <FFSelect
                      value={getValues('idCategory')}
                      onSelect={opt.field.onChange}
                      options={categories}
                      valueGetter={(opt) => opt.idCategory}
                      labelGetter={(opt) => opt.categoryName}
                      defaultValueFallback={{
                        label: 'Uncategorized',
                        value: 'Uncategorized',
                      }}
                      placeholder="Category"
                      style={{ width: 170 }}
                    />
                    <FFIconButton
                      buttonType="transparent"
                      iconName="general/line/add-circle"
                      onClick={() => openCategoryForm('offersources')}
                    />
                  </FFField>
                )}
              />
            </FFRow>
            <FFButton type="primary" onClick={onSaveAndCreate} loading={submitLoading}>
              Save & Create New
            </FFButton>
          </FFCol>
        </SectionBox>
      </VisibilityWrapper>
      <VisibilityWrapper visible={currentTabId === 'dataPassing'} beRenderedAlways>
        <FFCol gap="18px" height="max-content" width="100%">
          <SectionBox title="Configure Data Passing">
            <FFCol gap="8px">
              <p>Here you can configure additional data that gets appended to your base page URL.</p>
              <DataPassing
                queryParams={queryParams || {}}
                onChange={(queryParams) => setValue('queryParams', queryParams)}
                pageName="Data Passing Offers"
                showErrors={isSubmitted}
                setError={setDataPassingHasError}
              />
            </FFCol>
          </SectionBox>
        </FFCol>
      </VisibilityWrapper>
      <VisibilityWrapper visible={currentTabId === 'conversionTracking'}>
        <FFCol gap="18px" height="max-content" width="100%">
          <SectionBox title="Postback URL">
            <FFCol gap="8px">
              <p>
                Here, enter the tokens this offer source uses to pass back relevant data. The Hit ID field should have the token that
                corresponds to the field you pass Hit ID under in the configure data passing tab.
              </p>
              <FFRow gap="8px" marginBottom="18px">
                <Controller
                  name="postbackSubId"
                  control={control}
                  render={(opt) => (
                    <FFField label="Hit ID Field" block>
                      <FFInput
                        value={getValues('postbackSubId')}
                        onChange={opt.field.onChange}
                        error={errors.postbackSubId?.message}
                        placeholder="Hit ID Token"
                      />
                    </FFField>
                  )}
                />
                <Controller
                  name="postbackPayout"
                  control={control}
                  render={(opt) => (
                    <FFField label="Offer Payout" block>
                      <FFInput
                        value={getValues('postbackPayout')}
                        onChange={opt.field.onChange}
                        error={errors.postbackPayout?.message}
                        placeholder="Payout Token"
                      />
                    </FFField>
                  )}
                />
                <Controller
                  name="postbackTxId"
                  control={control}
                  render={(opt) => (
                    <FFField label="Transaction ID" block>
                      <FFInput
                        value={getValues('postbackTxId')}
                        onChange={opt.field.onChange}
                        error={errors.postbackTxId?.message}
                        placeholder="Transaction Token"
                      />
                    </FFField>
                  )}
                />
              </FFRow>
              <FFRow marginBottom="18px">
                <FFField
                  label="Select a domain"
                  block
                  tooltipContent={
                    <p>
                      Any domain can be used for postback URLs as these rely on a unique hit ID. However, client-side code like Javascript
                      will rely on cookies if you do not inject hit/vid directly, thus you should use the same domain for tracking links and
                      Javascript where possible.
                    </p>
                  }
                >
                  <FFSelect
                    options={domains}
                    valueGetter={(opt) => opt.domain}
                    labelGetter={(opt) => opt.domain}
                    value={domain}
                    onSelect={setDomain}
                    placeholder="Select a domain"
                  />
                </FFField>
              </FFRow>
              <FFCol>
                <FFField label="Here is the postback URL to use at this Offer Source:">
                  <CodeSnippet
                    data-testid="postBackUrl"
                    placeholder="Postback URL"
                    codeType="offerPostbackUrl"
                    domain={domain}
                    postbackPayout={watch('postbackPayout') || DEFAULT_POSTBACK_PAYOUT}
                    postbackTxId={watch('postbackTxId') || DEFAULT_POSTBACK_TXID}
                    postbackSubId={watch('postbackSubId') || DEFAULT_POSTBACK_SUBID}
                  />
                </FFField>
              </FFCol>
            </FFCol>
          </SectionBox>
          <SectionBox title="Universal Javascript Tag (conversion)">
            <FFCol gap="8px">
              <p>
                Here is the full Javascript code to place at this offer to track a conversion. It includes our universal JS tag and a
                conversion event. See our{' '}
                <a href="https://help.funnelflux.pro/article/106-javascript-tracking-of-conversions" target="_blank" rel="noreferrer">
                  help documentation
                </a>{' '}
                for more info.
              </p>
              <CodeSnippet
                codeType="offerSourceConversionFull"
                domain={domain}
                postbackPayout={watch('postbackPayout') || DEFAULT_POSTBACK_PAYOUT}
                postbackTxId={watch('postbackTxId') || DEFAULT_POSTBACK_TXID}
                postbackSubId={watch('postbackSubId') || DEFAULT_POSTBACK_SUBID}
              />
            </FFCol>
          </SectionBox>
          <SectionBox title="Optional: Conversion-only Event">
            <FFCol gap="8px">
              <p>If you have already loaded our universal JS tag, you can trigger a conversion with the following code.</p>
              <CodeSnippet
                codeType="offerSourceConversionSingle"
                domain={domain}
                postbackPayout={watch('postbackPayout') || DEFAULT_POSTBACK_PAYOUT}
                postbackTxId={watch('postbackTxId') || DEFAULT_POSTBACK_TXID}
                postbackSubId={watch('postbackSubId') || DEFAULT_POSTBACK_SUBID}
              />
            </FFCol>
          </SectionBox>
        </FFCol>
      </VisibilityWrapper>
      <VisibilityWrapper visible={currentTabId === 'help'}>
        <SectionBox title="Help">
          <FFCol gap="12px">
            <p>Offer Sources are the places where you get offers from.</p>

            <p>Typically, these will be affiliate networks, but could include direct advertisers and product owners.</p>

            <p>
              The key purpose of offer sources is to <strong>template</strong> the way you pass data in your offers from this source, as
              well as your default conversion tracking approach.
            </p>

            <p>
              By using Offer Sources you can create regular patterns for how you pass data to all your offers from the same source,
              guaranteeing standardised data and reducing tracking errors.
            </p>
            <p>
              For more help on offer sources, see our documentation{' '}
              <a href="https://help.funnelflux.pro/article/5-introduction-to-offer-sources" target="_blank" rel="noopener noreferrer">
                here
              </a>
              .
            </p>
            <p>
              For further information on using our Javascript tracking for offers, see our documentation{' '}
              <a href="https://help.funnelflux.pro/collection/6-javascript-tracking" target="_blank" rel="noopener noreferrer">
                here
              </a>
              .
            </p>
          </FFCol>
        </SectionBox>
      </VisibilityWrapper>
    </>
  );
});

const OfferSourceForm = () => {
  const isOpen = useFormStore((state) => state.offerSource.isOpen);
  const openedForms = useFormStore((state) => state.openedForms);
  const offerSourceFormProps = useFormStore((state) => state.offerSource);
  const closeForm = useFormStore((state) => state.closeOfferSourceForm);

  const { data: offersource = DEFAULT_OFFERSOURCE, isFetching } = useOfferSourceQuery(offerSourceFormProps?.data?.id!);

  const formRef = useRef<RefType>({
    onSave: () => {},
  });

  const [submitLoading, setSubmitLoading] = useState(false);
  const [currentTabId, setCurrentTabId] = useState<TabId>('general');
  const [showTemplates, setShowTemplates] = useState(false);
  const [templateSearch, setTemplateSearch] = useState('');
  const [selectedTemplateId, setSelectedTemplateId] = useState('');

  useEffect(() => {
    if (isOpen && !offerSourceFormProps.data?.id) {
      setShowTemplates(true);
    } else {
      setShowTemplates(false);
    }
  }, [isOpen, offerSourceFormProps.data?.id]);

  const onClose = () => {
    setTemplateSearch('');
    closeForm();
  };

  const onSelectTemplate = (template?: OfferSourceTemplate) => {
    if (template) {
      setSelectedTemplateId(template.id);
    }
    setShowTemplates(false);
  };

  const defaultFormValues: OfferSource = useMemo(() => {
    if (selectedTemplateId) {
      const template = OFFER_SOURCE_TEMPLATES.find((template) => template.id === selectedTemplateId);
      return {
        idOfferSource: '',
        offerSourceName: template?.name!,
        queryParams: template?.dataPassingFields,
        postbackPayout: template?.tracking.postbackPayout,
        postbackTxId: template?.tracking.postbackTxId,
        postbackSubId: template?.tracking.postbackSubId,
      };
    }
    return offersource;
  }, [offersource, selectedTemplateId]);

  return (
    <FFSidePanel
      isOpen={isOpen}
      minWidth={600}
      maxWidth={1100}
      tabs={showTemplates ? [] : tabs}
      onClose={onClose}
      sidebarName="OfferSourceForm"
      currentTabId={currentTabId}
      offsetLevel={getSidebarOffsetLevel(openedForms, 'offerSource')}
      zIndex={getSidebarZIndex(openedForms, 'offerSource')}
      title={
        offerSourceFormProps.isDuplication
          ? 'Duplicate Offer Source'
          : offerSourceFormProps.data?.id
          ? 'Edit Offer Source'
          : 'Create Offer Source'
      }
      setCurrentTabId={(tabId) => setCurrentTabId(tabId as TabId)}
      actions={
        <FFRow gap="8px">
          <FFButton onClick={() => formRef.current.onSave()} loading={submitLoading} disabled={submitLoading}>
            Save
          </FFButton>
          <FFButton type="tertiary" disabled={submitLoading} onClick={closeForm}>
            Cancel
          </FFButton>
        </FFRow>
      }
    >
      {showTemplates ? (
        <TemplateSelector
          onSelect={onSelectTemplate}
          onSearch={setTemplateSearch}
          searchValue={templateSearch}
          templates={OFFER_SOURCE_TEMPLATES}
          type="offersource"
        />
      ) : isFetching ? (
        <Skeleton width="100%" height="400px" />
      ) : (
        <Form
          defaultFormValues={defaultFormValues}
          currentTabId={currentTabId}
          closeForm={closeForm}
          setSubmitLoading={setSubmitLoading}
          submitLoading={submitLoading}
          ref={formRef}
        />
      )}
    </FFSidePanel>
  );
};

export default OfferSourceForm;
