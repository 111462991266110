export type PartnerCategory =
  | 'Communities'
  | 'Landing Pages'
  | 'Hosting Providers'
  | 'Tools & Services'
  | 'Affiliate Networks'
  | 'Traffic Sources'
  | 'Email Platforms';

export interface Partner {
  key: string;
  partner_name: string;
  description: string;
  description_expanded: string;
  promo_description?: string;
  img_src: string;
  cta_link: string;
  cta_text: string;
  category: PartnerCategory;
}

export const partnerCategories: PartnerCategory[] = [
  'Communities',
  'Landing Pages',
  'Hosting Providers',
  'Tools & Services',
  'Traffic Sources',
  'Affiliate Networks',
  'Email Platforms',
];

export const partners: Partner[] = [
  {
    key: 'partner-4',
    partner_name: 'TheOptimizer',
    description:
      'TheOptimizer is a cloud-based automation and campaign management platform that enables marketers to easily manage and automate their routine optimization tasks across native, pop, redirect, push and adult display traffic networks.',
    description_expanded: `TheOptimizer is a cloud-based automation and campaign management platform that enables marketers to easily manage and automate their routine optimization tasks across native, pop, redirect, push and adult display traffic networks.
  
  It integrates with over 29 ad networks combining accurate spend and revenue on all campaign levels, while automating repetitive tasks from simple campaign, publisher or ad pausing and activation, to complex sensitive tasks like budget and bid changes.
  
  **Here's a what TheOptimizer can do for you:**
  - Accurately combine ad network and tracker data to show accurate spent, revenue and profit on a Campaign / Publisher / Spot / Ad group and Ad level.
  - Automatically pause underperforming Campaigns / Publishers / Ad Groups or Ads when predefined rule conditions are met (clicks, cost, conversions, revenue, etc)
  - Automatically start profitable Campaigns / Publishers / Ad Groups or Ads automatically. Extremely useful for delayed conversions.
  - Automatically increase, decrease or set bids based of a fixed value, or percentage of the current bid, EPC, etc.
  - Automatically increase, decrease or set campaign daily budgets to a fixed value or percentage. Super useful for scaling profitable campaigns.
  - Create multiple campaigns and ads in one flow without spending too much time on the traffic network interface. Ideal for split-testing.
  `,
    cta_link: 'https://theoptimizer.io/',
    cta_text: 'Get Started Now',
    img_src: 'marketplace/optimizer.svg',
    category: 'Tools & Services',
    promo_description: 'The industry leading automatic optimization tool',
  },
  {
    key: 'partner-5',
    partner_name: 'Vimmy',
    description:
      'Vimmy is an ad network that provides multiple ad formats, including calendar push notifications, and focuses on tier 1 and 2 country traffic.',
    description_expanded: `Vimmy is an ad network that provides multiple ad formats, including calendar push notifications, and focuses on tier 1 and 2 country traffic.
  
  Vimmy's new calendar push format allows you to send iOS push notifications via the calendar app -- make sure to test it before it loses its novelty!
  
  **Additionally the ad network offers:**
  - Traditional push ads
  - In-page push ads
  - Popunder traffic
  - They have their own database of 300M+ subscribers
  - Over a billion daily impressions
  - CPC payment model with a minimum bid of $0.001
  - Minimum deposit of $50.
  
  Use the promo code **FUNNELFLUX100** and get a first deposit bonus of $25 when you deposit $100 or more.`,
    cta_link: 'https://partners.vimmy.com/index.php?action=show&value=register',
    cta_text: 'Join Vimmy Here',
    img_src: 'marketplace/vimmy.png',
    category: 'Traffic Sources',
    promo_description: 'First deposit bonus of $25 when you deposit $100',
  },
  {
    key: 'partner-6',
    partner_name: 'Gamesvid',
    description:
      'Gamesvid is among the best game-focused networks available - and we mean real games, not "gaming" i.e. casino/gambling stuff.',
    description_expanded: `Gamesvid is among the best game-focused networks available - and we mean real games, not "gaming" i.e. casino/gambling stuff.
  
  While there is no specific promo here, we highly recommend this network for anyone who wants to dabble in advertising of PC games.
  
  They are as legit as they come and provide great support, content/creative resources from the advertisers, and they know gaming inside and out.
  
  Highly recommended, let them know you came from FunnelFlux when you join and they'll be sure to look after you`,
    cta_link: 'https://gamesvid.com/',
    cta_text: 'Join Gamesvid Here',
    img_src: 'marketplace/gamesvid.png',
    category: 'Affiliate Networks',
    promo_description: 'No specific promotion, just a great network!',
  },
  {
    key: 'partner-7',
    partner_name: 'Affiliate World Forum',
    description:
      'AW Forum (nee STM) is the premium affiliate and performance marketing community. Learn from experienced affiliates, connect with like-minded marketers and expand your circle.',
    description_expanded: `If you're looking for information on how to get started in affiliate marketing, case studies and guides, want to keep your finger on the pulse or just want to connect with other affiliates -- then AW Forum is the place for you.
  
  It comes with a premium price tag because of the quality of the content and community, but its the best there is. Nowhere else has as many experienced marketers who are actually practicing what they preach.`,
    cta_link: 'https://affiliateworldforum.com/join/',
    cta_text: 'Join Affiliate World Forum Here',
    img_src: 'marketplace/awforum.svg',
    category: 'Communities',
    promo_description: 'The #1 Affiliate Marketing Forum',
  },
  {
    key: 'partner-8',
    partner_name: 'Afflift',
    description: 'Afflift is a premium affiliate marketing community and one of your best sources of up-to-date guidance.',
    description_expanded: `Afflift is one of the most active affiliate marketing communities out there. It's packed with case studies, tutorials, discounts from partners and of course a thriving affiliate community.
  
  Afflift is very newbie friendly and demands a lower price tag than STM Forum, so is a good bet if you're quite new to the industry and are not yet sure if a forum is a worthwhile investment for you (usually, it is!).
  
  Keen to join? Use the coupon code "FUNNELFLUX" to get 20% OFF any plan price - that's $16/mo, $80 for 6 months or $280 for a lifetime license!`,
    cta_link: 'https://afflift.com',
    cta_text: 'Join Afflift Here',
    img_src: 'marketplace/afflift.png',
    category: 'Communities',
    promo_description: '20% OFF any membership price',
  },
  {
    key: 'partner-9',
    partner_name: 'AffiliateFix',
    description:
      'AffiliateFix is one of the oldest affiliate marketing communities around and is loaded with case studies, guides and community. And, its FREE!',
    description_expanded: `AffiliateFix is one of the oldest affiliate marketing communities out there and will always come up in conversation when someone is recommending forums.
  
  Unlike STM and Afflift... AffiliateFix is 100% free!
  
  It's packed full of discounts from partners, case studies, discussions and as usual, an active community.
  
  Check it out if you're new to AM and want helpful guides and a community to assist with questions.
  `,
    cta_link: 'https://affiliatefix.com/',
    cta_text: 'Join AffiliateFix Here',
    img_src: 'marketplace/affiliatefix.png',
    category: 'Communities',
    promo_description: 'No specific promotion, just a great forum!',
  },
  {
    key: 'partner-10',
    partner_name: 'Adplexity',
    description:
      'Adplexity is the top competitive intelligence tool for affiliates. Use it to keep track of your competitors campaigns on multiple traffic sources, ranging from native, display to pops and push traffic',
    description_expanded: `If you have ever looked for a competitive intelligence tool, chances are you have heard of Adplexity.
  
  As a FunnelFlux member you can get up to 30% OFF their products.
  
  You can make use of our promo by visiting the link below.
  
  **Why use Adplexity?**
  - It's hands down the best "spy tool" out there
  - They have coverage for many traffic types in their various products
  - You can directly import landers into our other partner, Landerlab
  - They have a rock-solid track record, they aren't going to suddenly disappear like many competitive intel tools of the past
  `,
    cta_link:
      'https://promo.adplexity.com/promo/common/funnelfluxwelcome?utm_source=affiliate&utm_medium=refferal&utm_campaign=funnelfluxwelcome&utm_term=generic',
    cta_text: 'Visit Adplexity Now',
    img_src: 'marketplace/adplexity.png',
    category: 'Tools & Services',
    promo_description: 'Get up to 30% OFF',
  },
  {
    key: 'partner-11',
    partner_name: 'Landerlab',
    description:
      'Landerlab is the best visual landing page builder made for affiliate marketers. They offer fast hosting, an easy to use interface, and lots of templates to get you off to a running start.',
    description_expanded: `Landerlab is the cloud-based visual landing page editor that we recommend.
  
  No coding, no server skills needed! With Landerlab, you can create, edit, and publish beautiful and responsive landing pages that convert more in a matter of clicks. You can import these directly from Adplexity (another partner) as well.
  
  Our FunnelFlux Pro tracker doens't handle the landing page side of things for you, so you'll need a way to host your pages.
  
  If you want the simplest, cleanest visual editor that gives you lighting-fast delivery, a flat rate cost, and is geared toward the performance marketer, Landerlab has our highest recommendation.`,
    cta_link: 'https://landerlab.io/',
    cta_text: 'See Landerlab in Action',
    img_src: 'marketplace/landerlab.png',
    category: 'Landing Pages',
    promo_description: 'Get started with Landerlab',
  },
  {
    key: 'partner-12',
    partner_name: 'Adtrafico',
    description: 'Looking for a solid affiliate network with regular payments? Then Adtrafico.com is a perfect match for you!',
    description_expanded: `Looking for a solid affiliate network with regular payments? Then Adtrafico.com is a perfect match for you!
  
  **The advantages you get when working with Adtrafico:**
  - 2000+ of the freshest offers for all geos worldwide in sweepstakes, dating and mobile billing verticals
  - personal supportive affiliate manager who is always there to assist with any questions you have
  - weekly payments with no holds, no delays and no holidays
  - possibility to create your own smartlink
  
  If you аre a media buyer with stable volumes of good quality traffic, Adtrafico is the network you’ve been looking for.
  
  Register now using this link, say the code word *FunnelFlux* to your manager and get +10% on your first payout!`,
    cta_link: 'https://www.adtrafico.com/?utm=funnelflux_promo&source_id=31668',
    cta_text: 'Join Adtrafico Now',
    img_src: 'marketplace/adtrafico.svg',
    category: 'Affiliate Networks',
    promo_description: 'Get a 10% bonus on your first payment',
  },
  {
    key: 'partner-13',
    partner_name: 'Zeydoo',
    description:
      'Zeydoo is a trustworthy network with hand-picked offers and years of experience. They have a lot of exclusive offers and cover virtually every geo, so are a great primary network to focus on.',
    description_expanded: `Zeydoo (by PropellerAds) has quickly become one of the most talked about networks in the AM space.
  
  They have a lot of exclusive offers, specifically from PropellerAds advertisers, and cover most geos and verticals.
  
  They have a good reputation in the AM industry, good technology and a solid team helming the ship.
  
  On signing up as an affiliate, use the promo code *FNLFL* to get $15 added to your account.
  `,
    cta_link: 'https://zeydoo.com/',
    cta_text: 'Join Zeydoo Now',
    img_src: 'marketplace/zeydoo.png',
    category: 'Affiliate Networks',
    promo_description: 'Get $15 added to your account on signup',
  },
  {
    key: 'partner-14',
    partner_name: 'C3PA',
    description: 'C3PA (by CyTripia) is an affiliate network based in Estonia. They have a number of exclusive offers.',
    description_expanded: `C3PA is based in Estonia. Like most networks, they have a large array of offers including some exclusive to the network.
  
  Their top verticals are social discovery and finance in Tier I/II countries.
  
  As a FunnelFlux user, you can use the promo code **FUNNELFLUX** at the last step of registration to get streamlined approval.
  `,
    cta_link: 'https://c3pa.net/',
    cta_text: 'Join C3PA Now',
    img_src: 'marketplace/c3pa.png',
    category: 'Affiliate Networks',
    promo_description: 'Streamlined approval for FunnelFlux users',
  },
  {
    key: 'partner-15',
    partner_name: 'TrafficStars',
    description: 'TrafficStars is a premium adult ad network established in 2014. They have highly sought-after adult publishers.',
    description_expanded: `TrafficStars was founded in 2014 and has steadily grown over the years, along with their reputation as one of the premium ad networks in the ad space.
  
  When it comes to publishers, they are quality over quantity and some of the biggest adult sites work with them exclusively.
  
  They have office in Cyprus and Spain and have a stellar reputation.
  
  As a FunnelFlux user, you can get an **extra $50 on your first deposit of at least $100**.
  
  Just sign up, then reach out to their support and mention the promo code **FUNNELFLUX**.
  `,
    cta_link: 'https://auth.trafficstars.com/register?p1=partner&p2=funnelflux&utm_source=partner&utm_medium=funnelflux&utm_campaign=bonus',
    cta_text: 'Join TrafficStars Now',
    img_src: 'marketplace/trafficstars.png',
    category: 'Traffic Sources',
    promo_description: 'Get an extra $50 on your first deposit of at least $100',
  },
  {
    key: 'partner-16',
    partner_name: 'Mondiad',
    description: 'Mondiad is a global ad network specialized in push and native ads, built by the experienced founders of Popcash.',
    description_expanded: `Mondiad is a global ad network specialized in push and native ads, designed to serve your business needs, improving your performance and ROI!
  
  What’s in it for you as a Mondiad advertiser:
  - Self-serve platform
  - Advanced targeting options
  - Powerful API
  - Traffic fraud detection
  -️ Fast approvals & 24/7 support
  - Multiple payment options
  - Referral system
  - Conversion trackers
  - Smart optimization rules and tools
  - Custom bids for zoneID, subId, country
  
  Sign up now then use the promo code **FLX15** when topping up. You'll get a 15% bonus on your first deposit of a minimum of $100 (max bonus value of 100 USD).
  `,
    cta_link: 'https://members.mondiad.com/registration?role=advertiser',
    cta_text: 'Join Mondiad Now',
    img_src: 'marketplace/mondiad.svg',
    category: 'Traffic Sources',
    promo_description: 'Get a 15% bonus on your first Mondiad deposit of a minimum of $100',
  },
  {
    key: 'partner-17',
    partner_name: 'SourceKnowledge',
    description: 'SourceKnowledge is a self-serve ad network that delivers highly converting traffic for performance-based campaigns',
    description_expanded: `SourceKnowledge is a self-serve ad network that delivers highly converting traffic for performance-based campaigns.
  
  Plugging into SourceKnowledge’s platform gives affiliates access to premium domain-targeted traffic from our network of direct publishers,
  consisting of apps & plugins, in-text ads (deal & coupon sites and content review sites), and social influencers.
  
  **Key features of SourceKnowledge:**
  - Device targeting
  - Daily budget caps
  - Geotargeting
  - Allow lists / block lists by SubID
  - Track your performance using postback URLs
  - Live chat feature for fast support
  
  **Bonus:** $100 free ad spend!
  
  Sign Up by clicking the "Get bonus" button below, then contact your account manager and mention the code **FUNNELFLUX**.
  `,
    cta_link: 'https://sourceknowledge.com/',
    cta_text: 'Get Bonus Now',
    img_src: 'marketplace/sourceknowledge.png',
    category: 'Traffic Sources',
    promo_description: 'Get $100 in free ad spend',
  },
  {
    key: 'partner-18',
    partner_name: 'SelfAdvertiser',
    description:
      'SelfAdvertiser is a self-service platform crafted for advertisers that packs qualified desktop and mobile traffic sources, essential targeting and optimization tools into a simple, reliable advertising platform.',
    description_expanded: `SelfAdvertiser is a self-service platform crafted for advertisers that packs qualified desktop and mobile traffic sources, essential targeting and optimization tools into a simple, reliable advertising platform.
  
  You can choose from three available traffic types: Domain Redirect (Zero-click), Pop and Push.
  
  Use our sign up link below to get an extra 40% bonus on your first deposit.`,
    cta_link: 'https://selfadvertiser.com/lp/apff/?/ref=APFF',
    cta_text: 'Sign Up Here',
    img_src: 'marketplace/selfadvertiser.png',
    category: 'Traffic Sources',
    promo_description: 'Get an extra 40% bonus on your first deposit.',
  },
  {
    key: 'partner-19',
    partner_name: 'Adstart Media',
    description:
      'Adstart Media is a fast-growing performance marketing network and a direct advertiser operating on the market since 2017.',
    description_expanded: `Adstart Media is a fast-growing performance marketing network and a direct advertiser operating on the market since 2017.
  
  Through the years, they have built an extensive network of 1000+ active affiliates, diversified in verticals, GEOs, and traffic sources.
  
  **Working with Adstart Media brings:**
  - In-house developed Sweepstakes and Mobile Subscription offers across 10 GEOs
  - Extensive collection of direct CPL, CPI, CPA offers to monetize any type of traffic;
  - On-demand landing pages
  - A referral program for affiliates and advertisers
  - Fast, clear, and insightful assistance from your dedicated manager
  
  **Your exclusive promo:** Adstart Media provides an increased +20% to the default payout on 5 offers of your choice for all newly registered affiliates for two weeks, from the date of your registration.
  
  Weekly payments on high traffic volumes and ready-to-use prelenders are guaranteed.
  
  Sign up via the link below, apply the promo code “FunnelFlux” in the registration form in the section “How did you hear about Adstart Media”.`,
    cta_link: 'http://adstartmedia.affise.com/signup ',
    cta_text: 'Sign Up Here',
    img_src: 'marketplace/adstart.png',
    category: 'Affiliate Networks',
    promo_description: '+20% payout on 5 offers of your choice, for two weeks',
  },
  {
    key: 'partner-20',
    partner_name: 'Adoperator',
    description:
      'Adoperator is a popular traffic source that provides push, in-page push and popunder traffic with an easy to use interface and plenty of handy features.',
    description_expanded: `Adoperator is a popular traffic source that provides push, in-page push and popunder traffic with an easy to use interface and plenty of handy features. They are offering a discount for all FunnelFlux Pro users.
  
  Use the promo code below during payment to get extra money for your ad campaigns.
  
  **FUNNELFLUX20** - for a deposit of $200, you will receive an additional $20 to your account balance.
  
  **What else do they provide?**
  - Traffic from India, Indonesia, Philippines, Thailand at 0.002 per click (and more than 200 other countries)
  - A user-friendly interface and fast approvals
  - All popular verticals allowed
  - No overspend of daily and total campaign budgets
  
  _P.S. the promo code is available for a limited time, do not miss your chance to earn with Adoperator!_`,
    cta_link: 'https://go.adoperator.com/auth/registration',
    cta_text: 'Sign Up Here',
    img_src: 'marketplace/adoperator.svg',
    category: 'Traffic Sources',
    promo_description: 'Get 10% extra on a $200 deposit',
  },
  {
    key: 'partner-21',
    partner_name: 'EZmob',
    description: 'EZmob is a self-serve advertising platform with over 3B impressions per day across display, pop, push and native ads.',
    description_expanded: `EZmob gives you access to billions of ad impressions generated by direct publishers and premium SSPs.
  
  With over 3B impressions per day across different channels such as display, pop, push notifications, and native ads, EZmob provides you with direct access to high-performing inventory from over 80,000 publishers on the EZmob ad network, with added supply from another leading premium SSPs.
  
  As a FunnelFlux user, you can get an **extra 10% on your first deposit of at least $100**.
  
  Just sign up as an advertiser, then when topping up use the code **FLUX10**.
  `,
    cta_link: 'https://ezmob.com/flux',
    cta_text: 'Join EZmob Now',
    img_src: 'marketplace/ezmob.png',
    category: 'Traffic Sources',
    promo_description: 'Get an extra 10% on your first deposit over $100',
  },
  {
    key: 'partner-22',
    partner_name: 'Datify',
    description: 'Datify is an affiliate network that offers traffic monetization through Smartlink and direct offers.',
    description_expanded: `Datify is an affiliate network that offers traffic monetization through Smartlink and direct offers.
  
    They have 2,000+ offers, reach 3M conversions per month and cover 100+ countries around the world.
    
    As a FunnelFlux user, you can get a **10% bonus on your first payout of at least $200**.
    
    Just sign up as an affiliate using our referral link below.
    `,
    cta_link: 'https://datify.link/en?utm_source=funnelflux&utm_medium=promo&utm_campaign=bonus',
    cta_text: 'Join Datify Now',
    img_src: 'marketplace/datify.png',
    category: 'Affiliate Networks',
    promo_description: 'Get an extra 10% on your first payout over $200',
  },
  {
    key: 'partner-23',
    partner_name: 'Traforama',
    description: `Traforama is an open marketplace for direct advertisers and ad networks that provides high-quality traffic for popunder, interstitial, banner, in-video, and in-page push ad formats.`,
    description_expanded: `Traforama is an open marketplace for direct advertisers and ad networks that provides high-quality traffic for popunder, interstitial, banner, in-video, and in-page push ad formats on a CPM basis.
      
    They are an open marketplace for direct advertisers and ad networks who can buy premium traffic & make direct deals with publishers.
  
    **Advantages of Traforama:**
    - Direct access to more than 1000+ premium websites (adult and mainstream)
    - Fully optimized platform for the self-service
    - Wide range of campaign creation settings and targeting options
    - Transparent Marketplace with high-quality websites and available spots
    - 8 payment methods with a minimum threshold of 5$
    - Comfortable postback and API
    - Smart CPM feature and Automatic CPM optimization to meet your CPA target
    - Detailed analysis of your stats and traffic reports
    - S2S postback and Pixel conversion tracking methods
    
    Register on the Traforama platform, fill in your balance and **receive a 10% bonus on your first deposit.**
    
    To receive the bonus, just write to us via Live Chat with the promo code **"FunnelFlux"**.`,
    cta_link: 'https://app.traforama.com/#/registration?utm_source=trackers&utm_medium=funnelflux&utm_term=marketplace',
    cta_text: 'Sign up to Traforama Now',
    img_src: 'marketplace/traforama.svg',
    category: 'Traffic Sources',
    promo_description: 'Get an extra 10% on your first deposit',
  },
  {
    key: 'partner-24',
    partner_name: 'Vultr',
    description: `Vultr is a cloud infrastructure provider offering affordable virtual private servers, bare metal servers, and storage solutions through a global network of data centers, enabling quick deployment and scaling of applications worldwide.`,
    description_expanded: `Vultr is a cloud infrastructure provider offering affordable virtual private servers, bare metal servers, and storage solutions through a global network of data centers, enabling quick deployment and scaling of applications worldwide. 
  
  Key features of Vultr include:
  - A wide range of products
  - Global presence
  - Flexible pricing by the hour
  - No long-term contracts
  - User-friendly control panel
  
  Vultr's combination of affordable pricing, global reach, and robust features makes it a compelling choice for those seeking reliable cloud infrastructure services.
  
  **Register with our link below to get a $100 free credit for initial testing (lasts 14 days).**`,
    cta_link: 'https://www.vultr.com/?ref=9193199-8H',
    cta_text: 'Sign up to Vultr Now',
    img_src: 'marketplace/vultr.svg',
    category: 'Hosting Providers',
    promo_description: 'Get $100 free for initial testing (lasts 14 days)',
  },
  {
    key: 'partner-25',
    partner_name: 'DigitalOcean',
    description: `DigitalOcean is a cloud infrastructure provider that offers simple and affordable cloud computing solutions for developers and businesses.`,
    description_expanded: `DigitalOcean is a cloud infrastructure provider that offers simple and affordable cloud computing solutions for developers and businesses. 
      
      Known for its user-friendly interface and straightforward pricing, DigitalOcean has become a popular choice for hosting web applications, websites, and development environments.
  
  **Key features of DigitalOcean include:**
  - Droplets: Scalable virtual machines for general web hosting
  - An easy to use UI
  - A marketplace of apps you can deploy in a few clicks
  - Spaces: Object storage for large amounts of unstructured data
  - Robust API and CLI tools for automation and integration
  
  Sign up using our link below to get $200 credit to use in your first 60 days.`,
    cta_link: 'https://m.do.co/c/bb7f0280d86c',
    cta_text: 'Sign up to DigitalOcean Now',
    img_src: 'marketplace/digitalocean.svg',
    category: 'Hosting Providers',
    promo_description: '$200 credit for your first 60 days',
  },
  {
    key: 'partner-26',
    partner_name: 'PureLander',
    description: `PureLander is a depository of simple affiliate-style landers. It's useful for basic templates that you can modify afterwards.`,
    description_expanded: `PureLander is a depository of simple affiliate-style landers. It's useful for basic templates that you can modify afterwards.
      
    There's not much more to say -- if you are an affiliate and plan to imitate/copy landers in the wild, or run simple pages that are used time and time again, it's a great (and cheap) trove of basic landing pages.
  
    Don't expect beautiful pages though -- however you can readily clean these up (and with AI) to suit your needs.
  
    Perfect for those who don't have much knowledge with coding and want basic HTML templates to use`,
    cta_link: 'https://purelanderas.com/a/click.php?refid=k1NUKquHOf&s1=marketplace',
    cta_text: 'Sign up to PureLander Now',
    img_src: 'marketplace/purelander.png',
    category: 'Landing Pages',
    promo_description: 'Affiliate landing page templates',
  },
  {
    key: 'partner-27',
    partner_name: 'Convertri',
    description: `Convertri is a high-performance funnel hosting platform. It's simple to use and it's pages load lightning-fast compared to most competitors.`,
    description_expanded: `Convertri is a high-performance funnel hosting platform. It's simple to use and it's pages load lightning-fast compared to most competitors.
      
    If you've used funnel builders before you might be familiar with clunky UIs and things loading terribly slowly. Not so with Convertri.
  
    Of all the visual page builders we have encountered, it's one of the best (though you always pay quite a bit of $$ for visual builders).
  
    If you're looking for something with clean, professional pages and suitable for Ecommerce, emailing, etc., Convertri is our #1 recommendation.`,
    cta_link: 'https://app.convertri.com/a/88bfd7ad-3394-11ef-a34b-06324c722a0b',
    cta_text: 'Sign up to Convertri Now',
    img_src: 'marketplace/convertri.svg',
    category: 'Landing Pages',
    promo_description: 'Our recommended clean funnel builder',
  },
  {
    key: 'partner-28',
    partner_name: 'Aweber',
    description: `AWeber is an email marketing and automation platform founded in 1998, making it one of the pioneers in the industry`,
    description_expanded: `AWeber is an email marketing and automation platform founded in 1998, making it one of the pioneers in the industry.
  
  It offers a range of features including email template builders, autoresponders, landing pages, and list management tools.
  
  AWeber is known for its user-friendly interface and reliable deliverability rates, making it popular among small businesses and entrepreneurs.
  
  The platform provides integrations with many third-party applications and e-commerce platforms, enhancing its functionality.
  
  AWeber offers both a free plan for up to 500 subscribers and paid plans with more advanced features, catering to businesses at different stages of growth.`,
    cta_link: 'https://www.aweber.com/easy-email.htm?id=557303',
    cta_text: 'Sign up to Aweber Now',
    img_src: 'marketplace/aweber.svg',
    category: 'Email Platforms',
    promo_description: 'Email marketing platform, allows affiliate marketing',
  },
  {
    key: 'partner-29',
    partner_name: 'Sendlane',
    description: `Sendlane is a data-driven email marketing and automation platform founded in 2013, designed specifically for e-commerce businesses.`,
    description_expanded: `Sendlane is a data-driven email marketing and automation platform founded in 2013, designed specifically for e-commerce businesses.
  
  It offers advanced segmentation capabilities, allowing users to create highly targeted campaigns based on customer behavior and preferences.
  
  Sendlane's features include email marketing, SMS marketing, web tracking, and deep data integrations with popular e-commerce platforms.
  
  The platform is known for its powerful automation tools, including multi-channel workflows and abandoned cart recovery sequences.
  
  While Sendlane may be pricier than some competitors, it offers a comprehensive solution for e-commerce businesses looking to maximize their marketing ROI through personalized customer experiences.`,
    cta_link: 'https://www.sendlane.com/',
    cta_text: 'Sign up to Sendlane Now',
    img_src: 'marketplace/sendlane.svg',
    category: 'Email Platforms',
    promo_description: 'Email marketing platform, allows affiliate marketing',
  },
  {
    key: 'partner-30',
    partner_name: 'Brevo',
    description: `Brevo is a comprehensive all-in-one digital marketing platform that offers tools for email marketing, SMS campaigns, chat, CRM, and marketing automation.`,
    description_expanded: `Brevo is a comprehensive all-in-one digital marketing platform that offers tools for email marketing, SMS campaigns, chat, CRM, and marketing automation.
  
  Originally founded as Sendinblue in 2012, the company rebranded to Brevo in 2023 to reflect its expanded suite of services beyond email marketing. 
  
  Brevo caters to businesses of all sizes, from small startups to large enterprises, providing user-friendly solutions to create, send, and analyze marketing campaigns. 
  
  The platform's strengths lie in its intuitive interface, affordable pricing plans, and powerful features like segmentation, A/B testing, and real-time analytics. 
  
  With a focus on helping businesses grow their online presence and engage with customers effectively, Brevo has become a popular choice for marketers seeking an integrated marketing solution.`,
    cta_link: 'https://www.brevo.com/',
    cta_text: 'Sign up to Brevo Now',
    img_src: 'marketplace/brevo.svg',
    category: 'Email Platforms',
    promo_description: 'Email marketing platform, allows affiliate marketing',
  },
  {
    key: 'partner-31',
    partner_name: 'Anstrex',
    description: 'Anstrex is a leading competitive intelligence provider that has native, push, pops and instream ad data.',
    description_expanded: `Anstrex is a leading competitive intelligence provider that has native, push, pops and instream ad data.
  
  Their plans cover a large number of networks and they have a lot of data to help you with competitive intelligence:
  
  * 38+ push networks
  * 32+ pop networks
  * 27+ native networks
  * Data from 92 countries
  
  Smart marketers use comeptitive intel to kickstart testing and often the price of a subscription pays for itself in reduced ad spend wastage.
  
  Check them out today!
  `,
    cta_link: 'https://www.anstrex.com/?utm_campaign=affiliate&fp_ref=funnelflux',
    cta_text: 'Visit Anstrex Now',
    img_src: 'marketplace/anstrex.svg',
    category: 'Tools & Services',
    promo_description: 'Just a great tool',
  },
  {
    key: 'partner-32',
    partner_name: 'Multilogin',
    description: 'Multilogin is a capable "anti-detect" browser used for multi-accounting, scraping, and competitive intelligence',
    description_expanded: `Multilogin is the first anti-detect browser on the market. It focuses on quality over having too many
  features that do not seem to serve a major purpose. This means users get the most reliable and effective tools to get the job done.
  
  Thousands of affiliates and online marketers, digital agencies, crypto farmers, developers, and big SaaS businesses use it daily to manage multiple accounts, automate tasks, scrape the web, and stay safe online without ever getting detected.
  
  It is a powerful tool for many industries, including affiliate marketing, e-commerce, digital marketing, crypto, and especially data scraping and automation.
  
  Multilogin focuses on providing a complete solution for online anonymity, account management and
  automation & scaling. It uses advanced technology to help users stay undetected and avoid bans. This is important for keeping their online activities safe and successful.
  
  With our partnership, you can get massive 50% discount on the first three months.
  
  To use this discount, please sign up for free, then contact the support team and ask to apply the discount code FUNNELFLUX to your subscription.
  `,
    cta_link: 'https://multilogin.com?utm_source=funnelflux&utm_medium=partner#a_aid=funnelflux&a_bid=11110007',
    cta_text: 'Visit Multilogin Now',
    img_src: 'marketplace/multilogin.svg',
    category: 'Tools & Services',
    promo_description: '50% off for 3 months',
  },
];
