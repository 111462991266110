/**
 * FunnelFlux Stats API
 * API endpoints to manage stats and run statistic reports
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AttributeData } from '../attributeData';


export interface HitReportParams {
    timeStart: string;
    timeEnd: string;
    filters?: Array<AttributeData>;
    includeHits?: boolean;
    includeClicks?: boolean;
    includeConversions?: boolean;
    sort?: HitReportParams.SortEnum;
    pagingStart?: number;
    pagingLength: number;
    /**
     * If empty, returns default metrics. Can restrict the response to any combination of the following metrics (note a limit of 15 total metrics is imposed):   * EventType   * EventTime   * HitTime   * FunnelID   * TrafficSourceID   * External   * NodeID   * NodeType   * NodeName   * HitID   * VisitorID   * VisitorIP   * ClickID   * ConversionID   * ConversionTransaction   * Revenue   * Cost   * TrackingDomain   * CampaignID   * OfferSourceID   * PageID   * PageCategoryID   * PageGroupID   * LocationContinent   * LocationCountry   * LocationRegion   * LocationCity   * ConnectionISP   * ConnectionMobileCarrier   * ConnectionType   * ConnectionReferrerPath   * ConnectionReferrerDomain   * DeviceType   * DeviceBrand   * DeviceModel   * DeviceModelName   * DeviceOS   * DeviceOSVersion   * DeviceBrowser   * DeviceBrowserVersion   * DeviceMainLanguage   * DeviceUserAgent   * SubCampaign   * TrackingField1   * TrackingField2   * TrackingField3   * TrackingField4   * TrackingField5   * TrackingField6   * TrackingField7   * TrackingField8   * TrackingField9   * TrackingField10   * TrackingField11   * TrackingField12   * TrackingField13   * TrackingField14   * TrackingField15   * TrackingField16   * TrackingField17   * TrackingField18   * TrackingField19   * TrackingField20   * CustomEvent1   * CustomEvent2   * CustomEvent3   * CustomEvent4   * CustomEvent5   * CustomEvent6   * CustomEvent7   * CustomEvent8   * CustomEvent9   * CustomEvent10
     */
    restrictToMetrics?: Array<string>;
}
export namespace HitReportParams {
    export type SortEnum = 'asc' | 'desc';
    export const SortEnum = {
        Asc: 'asc' as SortEnum,
        Desc: 'desc' as SortEnum
    };
}
