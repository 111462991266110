/**
 * FunnelFlux Pro Ledger API
 * API endpoints to manage customers' assets
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ThirdPartyKeys } from './ThirdPartyKeys';

export interface CustomEventAlias {
  alias: string; shortAlias: string
}

export interface UserSettings {
  /**
   * URL of default homepage, keep empty for 404 response
   */
  defaultHomepageURL: string;
  /**
   * Default redirect type for offers. See more about types in Page.redirectType description
   */
  defaultOfferRedirect: UserSettings.DefaultOfferRedirectEnum;
  /**
   * Default redirect type for landers. See more about types in Page.redirectType description
   */
  defaultLanderRedirect: UserSettings.DefaultLanderRedirectEnum;
  /**
   * IP Anonymizer name
   */
  ipAnonymizer: UserSettings.IpAnonymizerEnum;
  /**
   * Default custom domain name from 'customDomains' collection
   */
  defaultCustomDomain: string;
  /**
   * Keys from third parties
   */
  thirdPartyKeys?: ThirdPartyKeys;
  savedViews?: Array<string>;
  customEventAliases?: { [key: number]: CustomEventAlias }
}
export namespace UserSettings {
  export type DefaultOfferRedirectEnum =
    | '301'
    | '307'
    | 'umr'
    | 'fluxify'
    | 'meta';
  export const DefaultOfferRedirectEnum = {
    _301: '301' as DefaultOfferRedirectEnum,
    _307: '307' as DefaultOfferRedirectEnum,
    Umr: 'umr' as DefaultOfferRedirectEnum,
    Meta: 'meta' as DefaultOfferRedirectEnum,
    Fluxify: 'fluxify' as DefaultOfferRedirectEnum
  };
  export type DefaultLanderRedirectEnum =
    | '301'
    | '307'
    | 'umr'
    | 'fluxify'
    | 'meta';
  export const DefaultLanderRedirectEnum = {
    _301: '301' as DefaultLanderRedirectEnum,
    _307: '307' as DefaultLanderRedirectEnum,
    Umr: 'umr' as DefaultLanderRedirectEnum,
    Meta: 'meta' as DefaultLanderRedirectEnum,
    Fluxify: 'fluxify' as DefaultLanderRedirectEnum
  };
  export type IpAnonymizerEnum = 'disabled' | 'All IPs' | 'EU IPs';
  export const IpAnonymizerEnum = {
    Disabled: 'disabled' as IpAnonymizerEnum,
    AllIPs: 'All IPs' as IpAnonymizerEnum,
    EUIPs: 'EU IPs' as IpAnonymizerEnum
  };
}
