import useHttp from '@/hooks/http';
import { OfferSource } from '@/models/offerSource';
import { useMutation } from '@tanstack/react-query';

export function useOfferSourceCreateMutation() {
  const { post } = useHttp();

  return useMutation<null, Error, OfferSource>({
    mutationKey: ['offersource', 'create'],
    mutationFn: async (offersource: OfferSource) => {
      await post('v1/offersource/save/', offersource);
      return null;
    },
  });
}

export function useOfferSourceUpdateMutation() {
  const { put } = useHttp();

  return useMutation<null, Error, OfferSource>({
    mutationKey: ['offersource', 'update'],
    mutationFn: async (offersource: OfferSource) => {
      await put('v1/offersource/save/', offersource);
      return null;
    },
  });
}

export function useOfferSourceDuplicateMutation() {
  const { post } = useHttp();

  return useMutation<null, Error, OfferSource>({
    mutationKey: ['offersource', 'duplicate'],
    mutationFn: async (offersource: OfferSource) => {
      await post('v1/offersource/duplicate/', offersource);
      return null;
    },
  });
}


export function useOfferSourceDeleteMutation() {
  const { del } = useHttp();

  return useMutation<null, Error, string>({
    mutationKey: ['offersource', 'delete'],
    mutationFn: async (id: string) => {
      await del('v1/offersource/delete/', {
        data: { entries: [id] },
      });
      return null;
    },
  });
}

export function useOfferSourcesArchiveMutation() {
  const { put } = useHttp();

  return useMutation<null, Error, OfferSource>({
    mutationKey: ['offersource', 'archive'],
    mutationFn: async (offersource: OfferSource) => {
      await put<OfferSource, null>('v1/offersource/save/', offersource);
      return null;
    },
  });
}

export function useOfferSourcesUnArchiveMutation() {
  const { put } = useHttp();

  return useMutation<null, Error, OfferSource>({
    mutationKey: ['offersource', 'unarchive'],
    mutationFn: async (trafficSource: OfferSource) => {
      await put<OfferSource, null>('v1/offersource/save/', trafficSource);
      return null;
    },
  });
}