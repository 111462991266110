import { RefObject } from 'react';
import { FFIcon, FFTooltip, VisibilityWrapper } from '@/uikit';
import {
  getJsDefaultTrackingParams,
  getGenericViewCodeFull,
  getGenericViewCodeSingle,
  getOfferSourceConversionCodeFull,
  getOfferSourceConversionCodeSingle,
  getOfferConversionCodeFull,
  getOfferConversionCodeSingle,
  getGenericConversionCodeFull,
  getGenericConversionCodeSingle,
  getCustomEventCodeSingle,
  getOfferPostbackUrl,
  getFunnelDirectLink,
  getFunnelRedirectLink,
} from '@/utils/linkJs';
import { FunnelNode } from '@/models/funnelNode';
import { TrafficSource } from '@/models/trafficSource';
import clsx from 'clsx';
import CopyButton from '../CopyButton';
import className from '@/utils/className';
import './style.scss';

const { blockClassName, getClass } = className('c-codeSnippet');

type CodeType =
  | 'offerSourceConversionFull'
  | 'offerSourceConversionSingle'
  | 'offerConversionFull'
  | 'offerConversionSingle'
  | 'genericViewFull'
  | 'genericViewSingle'
  | 'customEventCodeSingle'
  | 'genericConversionFull'
  | 'genericConversionSingle'
  | 'defaultParams'
  | 'funnelRedirectLink'
  | 'funnelDirectLink'
  | 'offerPostbackUrl';

interface CommonProps {
  domain: string;
  idPage: string;
  idFunnel: string;
  idTrafficSource: string;
  cost: string;
  pid: string;
  vid: string;
  idNode: string;
  pageBaseUrl: string;
  postbackPayout: string;
  postbackTxId: string;
  postbackSubId: string;
  funnelNodes: FunnelNode[];
  trafficSource: TrafficSource;
  appendTsParamsToDirectLink: boolean;
  directLinkWithSeparator: boolean;
}

const getCode = (codeType: CodeType, commonProps: CommonProps) => {
  const {
    domain,
    idPage,
    idFunnel,
    postbackPayout,
    postbackSubId,
    postbackTxId,
    idTrafficSource,
    pageBaseUrl,
    idNode,
    cost,
    trafficSource,
    funnelNodes,
    appendTsParamsToDirectLink,
    directLinkWithSeparator,
  } = commonProps;

  switch (codeType) {
    case 'offerSourceConversionFull':
      return getOfferSourceConversionCodeFull(domain, postbackPayout, postbackTxId);

    case 'offerSourceConversionSingle':
      return getOfferSourceConversionCodeSingle(postbackPayout, postbackTxId);

    case 'offerConversionFull':
      return getOfferConversionCodeFull(domain, postbackPayout, postbackTxId);

    case 'offerConversionSingle':
      return getOfferConversionCodeSingle(postbackPayout, postbackTxId);

    case 'genericViewFull':
      return getGenericViewCodeFull(domain!);

    case 'genericViewSingle':
      return getGenericViewCodeSingle();

    case 'genericConversionFull':
      return getGenericConversionCodeFull(domain);

    case 'genericConversionSingle':
      return getGenericConversionCodeSingle();

    case 'defaultParams':
      return getJsDefaultTrackingParams(idPage, idFunnel);

    case 'offerPostbackUrl':
      return getOfferPostbackUrl(domain, postbackPayout, postbackSubId, postbackTxId, idPage, false);

    case 'customEventCodeSingle':
      return getCustomEventCodeSingle();

    case 'funnelDirectLink':
      return getFunnelDirectLink(
        domain,
        idFunnel,
        idTrafficSource,
        idPage,
        pageBaseUrl,
        idNode,
        cost,
        trafficSource,
        appendTsParamsToDirectLink,
        directLinkWithSeparator,
      );

    case 'funnelRedirectLink':
      return getFunnelRedirectLink(domain, funnelNodes, idFunnel, idTrafficSource, idNode, cost, trafficSource);
  }
};

const CodeSnippet = ({
  code,
  codeType,
  className = '',
  maxContent = false,
  forwardedRef,
  multiline,
  disabled,
  placeholder,
  error,
  formatter = (code) => code,
  onCopy,
  ...props
}: {
  code?: string;
  className?: string;
  forwardedRef?: RefObject<HTMLDivElement>;
  maxContent?: boolean;
  multiline?: boolean;
  disabled?: boolean;
  placeholder?: string;
  error?: string;
  codeType?: CodeType;
  formatter?: (code: string) => string;
  onCopy?: () => void;
  'data-testid'?: string;
} & Partial<CommonProps>) => {
  const codeContent = codeType
    ? formatter(
        getCode(codeType, {
          domain: props.domain!,
          idPage: props.idPage!,
          idFunnel: props.idFunnel!,
          pid: props.pid!,
          vid: props.vid!,
          idNode: props.idNode!,
          idTrafficSource: props.idTrafficSource!,
          cost: props.cost!,
          pageBaseUrl: props.pageBaseUrl!,
          funnelNodes: props.funnelNodes || [],
          trafficSource: props.trafficSource!,
          appendTsParamsToDirectLink: props.appendTsParamsToDirectLink!,
          directLinkWithSeparator: props.directLinkWithSeparator!,
          postbackPayout: props.postbackPayout!,
          postbackTxId: props.postbackTxId!,
          postbackSubId: props.postbackSubId!,
        }),
      )
    : formatter(code || '') || placeholder;

  return (
    <div
      data-testid={props['data-testid']}
      className={clsx([
        blockClassName,
        className,
        maxContent ? `${blockClassName}--maxContent` : '',
        multiline ? `${blockClassName}--multiline` : '',
        disabled ? `${blockClassName}--disabled` : '',
      ])}
      ref={forwardedRef}
    >
      <pre className={getClass('code')}>{codeContent}</pre>
      <div className={getClass('suffix')}>
        <VisibilityWrapper visible={!!error}>
          <FFTooltip title={error} placement="top">
            <div>
              <FFIcon name="information" className={getClass('errorIcon')} size="small" />
            </div>
          </FFTooltip>
        </VisibilityWrapper>
        <VisibilityWrapper visible={!error}>
          <CopyButton value={code!} />
        </VisibilityWrapper>
      </div>
    </div>
  );
};

export default CodeSnippet;
