import useHttp from '@/hooks/http';
import { Category } from '@/models/category';
import { useMutation } from '@tanstack/react-query';

export function useDeleteCategoryMutation() {
  const { del } = useHttp();

  return useMutation<null, Error, string[]>({
    mutationKey: ['category', 'delete'],
    mutationFn: async (ids: string[] = []) => {
      await del('v1/category/delete/', {
        data: { entries: [...ids] },
      });
      return null;
    },
  });
}

export function useArchiveCategoryMutation() {
  const { put } = useHttp();

  return useMutation<null, Error, Category>({
    mutationKey: ['category', 'archive'],
    mutationFn: async (category: Category) => {
      await put<Category>('v1/category/save/', {
        ...category,
        status: 'archived',
      });
      return null;
    },
  });
}

export function useUnArchiveCategoryMutation() {
  const { put } = useHttp();

  return useMutation<null, Error, Category>({
    mutationKey: ['category', 'archive'],
    mutationFn: async (category: Category) => {
      await put<Category>('v1/category/save/', {
        ...category,
        status: 'active',
      });
      return null;
    },
  });
}

export function useCreateCategoryMutation() {
  const { post } = useHttp();

  return useMutation<null, Error, Category>({
    mutationKey: ['category', 'create'],
    mutationFn: async (category: Category) => {
      await post('v1/category/save/', category);
      return null;
    },
  });
}

export function useUpdateCategoryMutation() {
  const { put } = useHttp();

  return useMutation<null, Error, Category>({
    mutationKey: ['category', 'update'],
    mutationFn: async (category: Category) => {
      await put('v1/category/save/', category);
      return null;
    },
  });
}
