import { TrafficSource } from '@/models/trafficSource';
import { FFCol, FFField, FFInput, FFSelect, FFSwitch } from '@/uikit';
import { FFSelectOption } from '@/uikit/types/select';
import { Control, Controller, useWatch } from 'react-hook-form';

const EVENT_NAMES: Array<FFSelectOption> = [
  {
    value: 'PURCHASE',
    label: 'Purchase'
  },
  {
    value: 'SAVE',
    label: 'Save'
  },
  {
    value: 'START_CHECKOUT',
    label: 'Start Checkout'
  },
  {
    value: 'ADD_CART',
    label: 'Add to Cart'
  },
  {
    value: 'VIEW_CONTENT',
    label: 'View Content'
  },
  {
    value: 'ADD_BILLING',
    label: 'Add Billing Details'
  },
  {
    value: 'SIGN_UP',
    label: 'Sign Up'
  },
  {
    value: 'SEARCH',
    label: 'Search'
  },
  {
    value: 'PAGE_VIEW',
    label: 'Page View'
  },
  {
    value: 'SUBSCRIBE',
    label: 'Subscribe'
  },
  {
    value: 'START_TRIAL',
    label: 'Start Trial'
  },
  {
    value: 'APP_INSTALL',
    label: 'App Install'
  },
  {
    value: '{txid}',
    label: 'Custom – Use Transaction ID Value'
  }
];

const SnapchatCustomScenario = ({ triggeringEvent, control }: { triggeringEvent: string; control: Control<TrafficSource> }) => {
  const sendOverridingEventURL = useWatch({
    control,
    name: `conversionTrackingSettings.${triggeringEvent}.customEventData.sendOverridingEventURL`,
  });

  return (
    <FFCol gap="12px">
      <Controller
        name={`conversionTrackingSettings.${triggeringEvent}.customEventData.accessToken`}
        control={control}
        rules={{ required: 'Required' }}
        render={opt => (
          <FFField label="Access Token:" block direction="row">
            <FFInput
              value={opt.field.value}
              onChange={opt.field.onChange}
              error={opt.fieldState.error?.message}
              placeholder="Access Token"
              style={{ width: 280 }}
            />
          </FFField>
        )}
      />
      <Controller
        name={`conversionTrackingSettings.${triggeringEvent}.customEventData.pixelID`}
        control={control}
        rules={{ required: 'Required' }}
        render={opt => (
          <FFField label="Pixel ID:" block direction="row">
            <FFInput
              value={opt.field.value}
              onChange={opt.field.onChange}
              error={opt.fieldState.error?.message}
              placeholder="Pixel ID"
              style={{ width: 280 }}
            />
          </FFField>
        )}
      />
      <Controller
        name={`conversionTrackingSettings.${triggeringEvent}.customEventData.eventType`}
        control={control}
        rules={{ required: 'Required' }}
        render={opt => (
          <FFField label="Event type:" block direction="row">
            <FFSelect
              options={EVENT_NAMES}
              valueGetter={opt => opt.value}
              labelGetter={opt => opt.label}
              value={opt.field.value}
              onChange={opt.field.onChange}
              error={opt.fieldState.error?.message}
              placeholder="Event name"
              style={{ width: 280 }}
            />
          </FFField>
        )}
      />
      <Controller
        name={`conversionTrackingSettings.${triggeringEvent}.customEventData.sendOverridingEventURL`}
        control={control}
        render={opt => (
          <FFSwitch checked={opt.field.value} onClick={opt.field.onChange}>
            Send overriding event URL for each conversion
          </FFSwitch>
        )}
      />
      {sendOverridingEventURL && (
        <Controller
          name={`conversionTrackingSettings.${triggeringEvent}.customEventData.eventURLToUse`}
          control={control}
          rules={{ required: 'Required' }}
          render={opt => (
            <FFField label="Event url to use:" block direction="row">
              <FFInput
                value={opt.field.value}
                onChange={opt.field.onChange}
                error={opt.fieldState.error?.message}
                placeholder="Event URL"
                style={{ width: 280 }}
              />
            </FFField>
          )}
        />
      )}
    </FFCol>
  );
};

export default SnapchatCustomScenario;
