import useHttp from '@/hooks/http';
import { Page } from '@/models/page';
import { PageCategoryInfo } from '@/models/pageCategoryInfo';
import { PageGroup } from '@/models/pageGroup';
import { PageGroupInfo } from '@/models/pageGroupInfo';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';

export function usePageGroupPagesCategoryInfoQuery(status: PageGroup.StatusEnum = 'active', pageGroupType: PageGroup.PageTypeEnum) {
  const { get } = useHttp();
  return useQuery({
    queryKey: ['pageGroup', 'pagesCategoryInfo', pageGroupType],
    queryFn: async () => {
      const { data } = await get<PageCategoryInfo[]>('v1/page/category/list/', {
        params: {
          status,
          includeChilds: true,
          pageType: pageGroupType,
        },
      });
      return data;
    },
  });
}

export function usePageGroupPagesQuery(ids: string[] = []) {
  const { get } = useHttp();
  const [pages, setPages] = useState<{ [key: string]: Page }>({});

  return useQuery({
    queryKey: ['pageGroup', 'pages', ids],
    queryFn: async () => {
      const { data } = await get<Page[]>(`v1/page/find/byIds/?idPages=${ids.join(',')}`);
      const dataObject = data.reduce((acc: { [key: string]: Page }, page) => {
        acc[page.idPage] = page;
        return acc;
      }, pages);
      setPages(dataObject);
      return dataObject;
    },
    enabled: ids.length > 0,
    initialData: pages,
  });
}

export function usePageGroupQuery(idPageGroup: string) {
  const { get } = useHttp();
  return useQuery({
    queryKey: ['pageGroup', idPageGroup],
    queryFn: async () => {
      const { data } = await get<PageGroup>('v1/page/group/find/byId', { params: { idPageGroup } });
      return data;
    },
    enabled: !!idPageGroup,
  });
}

export function usePageGroupListQuery<T extends PageGroup.PageTypeEnum>(
  pageType: T,
  status: PageGroup.StatusEnum = 'active',
  includeChilds = false,
  idFunnel?: string,
) {
  const { get } = useHttp();
  return useQuery({
    queryKey: ['pageGroup', 'list', pageType],
    queryFn: async () => {
      const { data } = await get<PageGroupInfo<T>[]>('v1/page/group/list', { params: { pageType, status, includeChilds, idFunnel } });
      return data;
    },
    enabled: !!pageType,
  });
}
