
import useAuthStore from '@/stores/auth';
import useAuthMitt from './authMitt';
import { gistUtils } from '@/widgets/gist';
import useUserStore from '@/stores/user';

export const useLogout = () => {
  const emitter = useAuthMitt();
  const logout = useAuthStore((state) => state.logout);
  const resetUser = useUserStore((state) => state.resetUser);

  const handleLogout = () => {
    emitter.emit('onLogout');
    gistUtils.close();
    gistUtils.shutdown();
    resetUser();
    logout();
  };

  return {
    logout: handleLogout,
  };
};
