import React, { PropsWithChildren, useEffect } from 'react';
import { VisibilityWrapper } from '@/uikit';
import ReactPortal from '@/components/Portal';
import className from '@/utils/className';
import { SidebarTab } from '@/uikit/types/sidebar';
import clsx from 'clsx';
import './style.scss';

const { blockClassName, getClass } = className('c-ffSidePanel');

interface Props<T> {
  title: string | React.ReactNode;
  actions?: React.ReactNode;
  tabs?: SidebarTab<T>[];
  sidebarName: string;
  zIndex?: number;
  isOpen: boolean;
  onClose: () => void;
  headerClassName?: string;
  contentClassName?: string;
  contentStyle?: React.CSSProperties;
  currentTabId?: T;
  width?: number | string;
  minWidth?: number;
  maxWidth?: number;
  padding?: number | string;
  offsetLevel?: number;
  closableByOverlay?: boolean;
  setCurrentTabId?: (tabId: T) => void;
}

const SidePanel = <T = string>({
  zIndex = 100,
  sidebarName,
  isOpen,
  onClose,
  title,
  headerClassName,
  contentClassName,
  contentStyle,
  actions,
  children,
  tabs = [],
  padding,
  minWidth,
  maxWidth,
  width,
  currentTabId,
  closableByOverlay = false,
  offsetLevel,
  setCurrentTabId = () => {},
}: PropsWithChildren<Props<T>>) => {
  const hasTabs = tabs.length > 0;
  const [currentTabIdState, setCurrentTabIdState] = React.useState(currentTabId);

  const onTabClick = (tab: SidebarTab<T>) => {
    setCurrentTabIdState(tab.tabId);
    setCurrentTabId(tab.tabId);
  };

  const onOverlayClick = () => {
    if (closableByOverlay) {
      onClose();
    }
  };

  useEffect(() => {
    if (isOpen) {
      setCurrentTabIdState(currentTabId);
    }
  }, [isOpen, currentTabId]);

  return (
    <ReactPortal zIndex={zIndex} dataPortalKey={sidebarName}>
      <>
        <div className={getClass('backdrop', [isOpen && 'open'])} style={{ zIndex }} onClick={onOverlayClick} />
        <div
          style={{ zIndex, minWidth, maxWidth, width }}
          className={clsx(
            blockClassName,
            `${blockClassName}--${isOpen ? 'open' : 'close'}`,
            `${blockClassName}--offsetLevel${offsetLevel}`,
          )}
        >
          <div className={clsx(getClass('header'), headerClassName)}>
            <h3 className={getClass('title')}>{title}</h3>
            <div className={getClass('actions')}>{actions}</div>
          </div>
          <div style={{ padding }} className={getClass('body')}>
            {isOpen && <div style={contentStyle} className={clsx(getClass('children'), contentClassName)}>{children}</div>}
            <VisibilityWrapper visible={hasTabs}>
              <div className={getClass('tabs')}>
                {tabs.map((tab, index) => (
                  <div
                    key={index}
                    className={getClass('tab', [
                      !!tab.hidden && 'hidden',
                      !!tab.highlightable && 'highlightable',
                      !!tab.disabled && 'disabled',
                      tab.tabId === currentTabIdState ? 'active' : 'inactive',
                    ])}
                    onClick={() => onTabClick(tab)}
                  >
                    <span className={getClass('tabTitle')}>{tab.title}</span>
                    <span className={getClass('tabIcon')}>{tab.icon}</span>
                  </div>
                ))}
              </div>
            </VisibilityWrapper>
          </div>
        </div>
      </>
    </ReactPortal>
  );
};

export default SidePanel;
