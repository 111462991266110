const GeneralSettings = () => (
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
    <g id="desktop-personal-hotspot--desktop-personal-hotspot-connection-signal-device-display-computer">
        <path id="Subtract" fill="currentColor" fillRule="evenodd" d="M3.78304 2H1c-0.276142 0 -0.5 0.22386 -0.5 0.5v8c0 0.2761 0.223858 0.5 0.5 0.5h12c0.2761 0 0.5 -0.2239 0.5 -0.5V7.67268c-0.5789 0.36569 -1.2648 0.57733 -2.0001 0.57733H9.78564c-1.67212 0 -3.08863 -1.09442 -3.5723 -2.60602 -0.49988 0.0404 -1.01339 -0.13326 -1.39183 -0.51945l0.71424 -0.69991 -0.71424 0.6999C4.16059 4.45008 3.75 3.52132 3.75 2.5c0 -0.16953 0.01125 -0.33643 0.03304 -0.5Z" clipRule="evenodd" strokeWidth="1"></path>
        <path id="Vector" fill="#ffffff" d="M9 13.5 8 11H6l-1 2.5h4Z" strokeWidth="1"></path>
        <path id="Vector_2" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M3.47227 2 1 2c-0.276142 0 -0.5 0.22386 -0.5 0.5v8c0 0.2761 0.223858 0.5 0.5 0.5h12c0.2761 0 0.5 -0.2239 0.5 -0.5V7.88353" strokeWidth="1"></path>
        <path id="Vector_3" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="m6 11 -1 2.5" strokeWidth="1"></path>
        <path id="Vector_4" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="m8 11 1 2.5" strokeWidth="1"></path>
        <path id="Vector_5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M4 13.5h6" strokeWidth="1"></path>
        <path id="Vector 3158" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M6.07143 3.89971C5.71795 3.53898 5.5 3.04494 5.5 2.5c0 -1.10457 0.89543 -2 2 -2h1.71429c1.10461 0 2.00001 0.89543 2.00001 2 0 0.79783 -0.4672 1.48654 -1.1429 1.80753" strokeWidth="1"></path>
        <path id="Vector 3159" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M12.9285 3.1003c0.3535 0.36072 0.5714 0.85476 0.5714 1.39971 0 1.10457 -0.8954 2 -2 2H9.78564c-1.10456 0 -2 -0.89543 -2 -2 0 -0.79783 0.46716 -1.48655 1.14286 -1.80754" strokeWidth="1"></path>
    </g>
</svg>
);

export default GeneralSettings;
