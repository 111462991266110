const Edit = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
    <g id="pencil-square--change-document-edit-modify-paper-pencil-write-writing">
      <path
        id="Vector 3996"
        fill="currentColor"
        d="M11 2H2c-0.55228 0 -1 0.44771 -1 1v9c0 0.5523 0.44771 1 1 1h9c0.5523 0 1 -0.4477 1 -1V3c0 -0.55228 -0.4477 -1 -1 -1Z"
        strokeWidth="1"
      ></path>
      <path
        id="Vector"
        fill="#ffffff"
        d="M7.4972 8.96246 4.49854 9.5l0.49977 -3.02614 5.22699 -5.17941c0.0929 -0.0933 0.2035 -0.16736 0.3253 -0.21789C10.6724 1.02602 10.803 1 10.935 1c0.1319 0 0.2626 0.02602 0.3844 0.07656 0.1218 0.05053 0.2324 0.12459 0.3253 0.21789l1.0595 1.05517c0.0937 0.09254 0.1681 0.20263 0.2188 0.32394 0.0507 0.1213 0.0769 0.25141 0.0769 0.38282 0 0.13141 -0.0262 0.26152 -0.0769 0.38282 -0.0507 0.12131 -0.1251 0.2314 -0.2188 0.32394l-5.207 5.19932Z"
        strokeWidth="1"
      ></path>
      <path
        id="Vector 3995"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M5.5 2H2c-0.55228 0 -1 0.44772 -1 1v9c0 0.5523 0.44772 1 1 1h9c0.5523 0 1 -0.4477 1 -1V8.5"
        strokeWidth="1"
      ></path>
      <path
        id="Vector_2"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M7.4972 8.96246 4.49854 9.5l0.49977 -3.02614 5.22699 -5.17941c0.0929 -0.0933 0.2035 -0.16736 0.3253 -0.21789C10.6724 1.02602 10.803 1 10.935 1c0.1319 0 0.2626 0.02602 0.3844 0.07656 0.1218 0.05053 0.2324 0.12459 0.3253 0.21789l1.0595 1.05517c0.0937 0.09254 0.1681 0.20263 0.2188 0.32394 0.0507 0.1213 0.0769 0.25141 0.0769 0.38282 0 0.13141 -0.0262 0.26152 -0.0769 0.38282 -0.0507 0.12131 -0.1251 0.2314 -0.2188 0.32394l-5.207 5.19932Z"
        strokeWidth="1"
      ></path>
    </g>
  </svg>
);

export default Edit;
