import { AuthenticateResponse, VerifyAuthenticationResponse } from '@/models/authenticate';
import { AuthStore, AuthStoreProps } from '@/types/stores/auth';
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

const DEFAULT_PROPS: AuthStoreProps = {
  tokens: {
    access_token: '',
    refresh_token: '',
    id_token: '',
    expires_at: 0
  },
  user: {
    user_id: '',
    permissions: [],
    email: '',
    email_verified: false,
    name: '',
    nickname: '',
    picture_url: '',
    updated_at: 0
  },
  isLoggedIn: false
};

const useAuthStore = create<AuthStore>()(
  devtools(
    persist(
      (set) => ({
        ...DEFAULT_PROPS,
        initializeAppAuth: (loginResponse: AuthenticateResponse | VerifyAuthenticationResponse) =>
          set(state => {
            state.tokens = {
              ...state.tokens,
              ...loginResponse.tokens,
            };
            state.user = {
              ...state.user,
              ...loginResponse.user,
            };
            state.isLoggedIn = true;
            return state;
          }),
        logout: () =>
          set(() => {
            return DEFAULT_PROPS;
          })
      }),
      { name: 'auth' }
    )
  )
);

export default useAuthStore;
