/**
 * FunnelFlux Reporting API
 * API endpoints to manage stats and run statistic reports
 *
 * OpenAPI spec version: 1.0.0
 * Contact: support@funnelflux.pro
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface HitReportRow {
  // manually added
  id: string;
  
  eventType?: HitReportRow.EventTypeEnum;
  eventTime?: string;
  hitTime?: string;
  funnelID?: string;
  trafficSourceID?: string;
  external?: string;
  nodeID?: string;
  hitID?: string;
  visitorID?: string;
  visitorIP?: string;
  clickID?: string;
  conversionID?: string;
  conversionTransaction?: string;
  revenue?: string;
  cost?: string;
  trackingDomain?: string;
  campaignID?: string;
  offerSourceID?: string;
  pageID?: string;
  pageCategoryID?: string;
  pageGroupID?: string;
  continent?: string;
  country?: string;
  region?: string;
  city?: string;
  connectionMobileCarrier?: string;
  connectionType?: string;
  connectionReferrerPath?: string;
  connectionReferrerDomain?: string;
  deviceType?: string;
  deviceBrand?: string;
  deviceModel?: string;
  deviceModelName?: string;
  deviceOS?: string;
  deviceOSVersion?: string;
  deviceBrowser?: string;
  deviceBrowserVersion?: string;
  deviceMainLanguage?: string;
  deviceUserAgent?: string;
  subCampaign?: string;
  trackingField1?: string;
  trackingField2?: string;
  trackingField3?: string;
  trackingField4?: string;
  trackingField5?: string;
  trackingField6?: string;
  trackingField7?: string;
  trackingField8?: string;
  trackingField9?: string;
  trackingField10?: string;
  trackingField11?: string;
  trackingField12?: string;
  trackingField13?: string;
  trackingField14?: string;
  trackingField15?: string;
  trackingField16?: string;
  trackingField17?: string;
  trackingField18?: string;
  trackingField19?: string;
  trackingField20?: string;
  nodeType?: HitReportRow.NodeTypeEnum;
  nodeName?: string;
  connectionISP?: string;
}
export namespace HitReportRow {
  export type EventTypeEnum = 'hit' | 'click' | 'conversion';
  export const EventTypeEnum = {
    Hit: 'hit' as EventTypeEnum,
    Click: 'click' as EventTypeEnum,
    Conversion: 'conversion' as EventTypeEnum,
  };
  export type NodeTypeEnum = 'rotator' | 'lander' | 'offer' | 'landerGroup' | 'offerGroup' | 'externalURL' | 'condition' | 'visitorTag';
  export const NodeTypeEnum = {
    Rotator: 'rotator' as NodeTypeEnum,
    Lander: 'lander' as NodeTypeEnum,
    Offer: 'offer' as NodeTypeEnum,
    LanderGroup: 'landerGroup' as NodeTypeEnum,
    OfferGroup: 'offerGroup' as NodeTypeEnum,
    ExternalURL: 'externalURL' as NodeTypeEnum,
    Condition: 'condition' as NodeTypeEnum,
    VisitorTag: 'visitorTag' as NodeTypeEnum,
  };
}
