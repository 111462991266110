import { FFNewIcon } from '@/uikit';
import { NewIconName, NewIconSize, NewIconType } from '@/uikit/types/icon';
import className from '@/utils/className';
import { Button } from 'antd';
import { ButtonProps } from 'antd/lib';
import clsx from 'clsx';
import './style.scss';

const { blockClassName } = className('c-ffIconButton');

type ButtonType = 'green' | 'red' | 'transparent';

const IconButton = ({
  iconName,
  iconSize = 'md',
  onClick,
  iconType = 'tertiary',
  buttonType = 'green',
  className,
  disabled,
}: { iconName: NewIconName; iconType?: NewIconType; iconSize?: NewIconSize; buttonType: ButtonType } & ButtonProps) => {
  return (
    <Button
      className={clsx(blockClassName, `${blockClassName}--${buttonType}`, className)}
      disabled={disabled}
      style={{ width: '35px', height: '35px' }}
      type="primary"
      onClick={onClick}
    >
      <FFNewIcon name={iconName} type={iconType} size={iconSize} display="inline-flex" />
    </Button>
  );
};

export default IconButton;
